import React from 'react';
import { useManagePackage, useBroadbandSwapProduct } from '@sky-tv-group/shared';
import { PackageCanBuy } from './packages.can.buy';
import { myAccountSWR } from '../../swr';
import { useEffect } from 'react';
import { kkService } from '../../services';

const Router = () => {
  const { skyRouter, broadbandByod, broadbandSelfInstallation } = useManagePackage(myAccountSWR);
  const { toggleRouterWithInstallSwap, swapRouterServices, routerAdded } = useBroadbandSwapProduct(kkService);

  const specialToggleLabel = routerAdded
    ? 'Use of Sky WiFi 6 Router is included in your plan.'
    : 'Use my own equipment';

  useEffect(() => {
    // router was added but byod still in cart, swap plans to self-install
    if (routerAdded && broadbandByod && broadbandByod.quantityInCart > 0) {
      swapRouterServices(false);
    }
    // router was removed but self install still in cart, swap plans to byod
    if (!routerAdded && broadbandSelfInstallation && broadbandSelfInstallation.quantityInCart > 0) {
      swapRouterServices(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routerAdded]);

  if (!skyRouter) {
    return null;
  }

  return (
    <div
      className="border border-solid border-gray-dark p-2 md:px-0 h-full mx-auto mb-4"
      data-testid={'router-container'}>
      <PackageCanBuy
        data={skyRouter}
        toggle={toggleRouterWithInstallSwap}
        showZeroAsFree={false}
        toggleLabel={specialToggleLabel}
        hidePrice={true}
        hidePriceOnDesktop={true}
      />
      {!routerAdded && (
        <div className="sky-h7-reg text-left text-red p-4 md:px-16 md:pb-16">
          <b>Are you sure?</b> Our Sky WiFi Routers have the latest WiFi 6 technology, with all the support you need,
          and we include use of a Sky Broadband plan. Please only select this if you're feeling confident to tackle the
          installation solo and your fibre router supports PPPoE VLAN 10.
        </div>
      )}
    </div>
  );
};

export { Router };
