import React from 'react';
import cx from 'classnames';

interface TabHeaderProps {
  isSelected: boolean;
  title: string;
  subTitle?: string;
  bestPlanTitle?: string;
}

const TabHeader = ({ isSelected, title, subTitle, bestPlanTitle }: TabHeaderProps) => {
  let topTabStyle = (isSelected: boolean) =>
    cx('w-full p-2 sky-sub md:sky-h7-bold absolute ', {
      'bg-bborange sky-text-midnight rounded-tr-md rounded-tl-md -top-0-5': isSelected && bestPlanTitle,
      'text-bborange sky-sub top-0-5': !isSelected && bestPlanTitle,
      'sky-bg-midnight rounded-tr-md rounded-tl-md -top-0-5': isSelected && !bestPlanTitle,
      hidden: !isSelected && !bestPlanTitle,
    });
  return (
    <div className="relative h-full" id="tab-header">
      <div className={topTabStyle(isSelected)} style={{ fontWeight: 'bold' }}>
        {bestPlanTitle}
      </div>
      {/* Desktop View */}
      <div className="hidden md:block pb-8 pt-10">
        <div className="p-4 sky-h5-bold md:sky-h4-bold">{title}</div>
        {subTitle && <div className="sky-sub md:sky-h7-reg">{subTitle}</div>}
      </div>
      {/* Mobile View */}
      <div className="md:hidden pt-8 pb-4">
        <div className="pb-3 pt-4 sky-h5-bold">{title}</div>
        {subTitle && (
          <div className="sky-sub" style={{ fontSize: '12px' }}>
            {subTitle}
          </div>
        )}
      </div>
    </div>
  );
};

export { TabHeader };
