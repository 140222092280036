import React, { useState } from 'react';

interface AccordionProps {
  title: string;
  summary: string;
  url: string;
}

function Accordion(props: AccordionProps) {
  const [active, setActiveState] = useState('h-0');
  const [buttonIcon, setButtonIconState] = useState('+');

  function toggleAccordian() {
    setActiveState(active === 'h-0' ? 'h-auto' : 'h-0');
    setButtonIconState(buttonIcon === '+' ? '-' : '+');
  }

  return (
    <div className="mb-4">
      <h5
        onClick={toggleAccordian}
        className="relative sky-bg-gray-light sky-text-midnight text-left h-20 px-8 py-4 md:p-4 flex justify-start items-center cursor-pointer">
        <div className="w-9/10 sky-h6-bold md:sky-h7-bold">{props.title}</div>
        <button className="h-20 w-24 absolute right-0 top-0 sky-h3 md:sky-h4">{buttonIcon}</button>
      </h5>
      <div id="showMe" className={active + ' overflow-hidden'}>
        <h4 className="md:flex pb-0 relative text-gray-darker p-4 sky-h6 md:sky-h7">{props.summary}</h4>
        <h4 className="md:flex mb-2 relative sky-text-blue p-4 sky-h6 md:sky-h7">
          {props.url !== '' && (
            <a target="blank" href={props.url}>
              More Details
            </a>
          )}
        </h4>
      </div>
    </div>
  );
}

export { Accordion };
