import React from 'react';
import cx from 'classnames';
import { formatMoney } from '@sky-tv-group/shared';

interface NonStarterPriceSectionProps {
  price: string;
  isMobileView: boolean;
}
const NonStarterPriceSection = ({ price, isMobileView = true }: NonStarterPriceSectionProps) => {
  const sectionClass = cx('mx-auto w-full text-center py-4', {
    'sky-bg-blue sky-text-white': isMobileView,
    'bg-white text-blue-dark ': !isMobileView,
  });
  return (
    <div className={sectionClass}>
      <span className="font-bold">{formatMoney(Number(price))}</span>/mth if you remove Sky Starter
    </div>
  );
};

export { NonStarterPriceSection };
