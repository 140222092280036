import React from 'react';
import { Router } from 'react-router-dom';
import { SWRConfig } from 'swr';
import { swrConfig } from '@sky-tv-group/shared';
import { Loader, ToastContainer } from '@sky-tv-group/components';

import { App } from './App';
import { Auth0Provider } from './auth0/auth0';
import config from './auth0/auth0.config';
import { useAuth0 } from './auth0/store';
import history from './history';
import { ScrollToTop } from './ScrollToTop';
import { SplitFactory } from '@splitsoftware/splitio-react';
import { SPLIT_AUTHORIZATION_KEY, SPLIT_KEY } from './config';
import { SalesForceChat } from '@sky-tv-group/components';

const onRedirectCallback = (appState: any) => {
  // Prevent tracking the 'page view' event on the callBack as we track it already on App
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname, {
    noScroll: true,
    preventPageTrack: true,
  });
};

function ProtectedApp() {
  const { loading } = useAuth0();

  if (loading) return <Loader bgOpacity={false} />;

  return <App />;
}

export function Root() {
  const sdkConfig: SplitIO.IBrowserSettings = {
    core: {
      authorizationKey: SPLIT_AUTHORIZATION_KEY,
      key: SPLIT_KEY,
    },
  };

  return (
    <SplitFactory config={sdkConfig} updateOnSdkUpdate={true}>
      <SWRConfig value={swrConfig}>
        <Router history={history}>
          <ScrollToTop />
          <Auth0Provider
            config={{
              domain: config.domain,
              client_id: config.clientId,
              redirect_uri: window.location.origin,
              audience: config.audience,
              advancedOptions: {
                defaultScope: config.scope,
              },
            }}
            onRedirectCallback={onRedirectCallback}>
            <ProtectedApp />
          </Auth0Provider>
        </Router>
        <ToastContainer />
        <SalesForceChat target="broadband" />
      </SWRConfig>
    </SplitFactory>
  );
}
