import React from 'react';
import { Button } from '@sky-tv-group/components';
import {
  SPLITIO_KEY,
  useSplitIO,
  telPhoneNumber,
  useProductInCart,
  useOrderStore,
  useCouponStore,
  useAnalytics,
  useCustomerStore,
  PACKAGE_TYPE,
  unformattedTelPhoneNumber,
} from '@sky-tv-group/shared';
import { useAuth0 } from '../auth0/store';
import history from '../history';
import { useStore } from '../store';
import { AppRoutes } from '../constants';

const ContinueButtonArea = () => {
  const [allowCheckout] = useSplitIO(SPLITIO_KEY.SKYWEB_BROADBAND_AND_TV_ACQUISTION);
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { isUpdatingPlan, selectedIsSkyStarterCustomer, isBundle } = useStore();
  const { starterInCart } = useProductInCart();
  const { isActiveGASession, setActiveGASession } = useCustomerStore(s => ({
    isActiveGASession: s.isActiveGASession,
    setActiveGASession: s.setActiveGASession,
  }));
  const redirectURL = `${window.location.origin}${AppRoutes.Checking}`;
  const inTVPage = window.location.pathname === AppRoutes.TV;
  const inOrderSummaryPage = window.location.pathname === AppRoutes.OrderSummary;
  const order = useOrderStore(s => s.order);
  const coupons = useCouponStore(s => s.coupons);

  const { checkoutStarted } = useAnalytics();

  const coupon = coupons[0];

  const handleCheckout = () => {
    if (isAuthenticated) {
      history.push(AppRoutes.Checking);
    } else {
      if (!selectedIsSkyStarterCustomer && allowCheckout) {
        if (inOrderSummaryPage) {
          // anaytics
          if (!isActiveGASession && order) {
            setActiveGASession(true);
          }
          order &&
            checkoutStarted(
              order,
              false,
              coupon,
              '',
              false,
              isBundle ? PACKAGE_TYPE.DTH_BROADBAND : starterInCart ? PACKAGE_TYPE.DTH : PACKAGE_TYPE.BROADBAND
            );
          history.push(AppRoutes.Checkout);
        } else if (isBundle && !inTVPage) {
          history.push(AppRoutes.TV);
        } else {
          history.push(AppRoutes.OrderSummary);
        }
      } else {
        loginWithRedirect(redirectURL);
      }
    }
  };

  const handleBack = () => {
    if (inOrderSummaryPage) {
      if (starterInCart) {
        history.push(AppRoutes.TV);
      } else {
        history.push(AppRoutes.Broadband);
      }
    } else if (inTVPage) {
      history.push(AppRoutes.Broadband);
    } else {
      history.push(AppRoutes.Home);
    }
  };

  return (
    <div className="bg-blue-light flex flex-col justify-center items-center">
      <div className="flex justify-center items-center py-5">
        <Button
          data-testid="back-button"
          colorMode="inverse"
          variant="secondary"
          onClick={handleBack}
          disabled={isUpdatingPlan}>
          Back
        </Button>
        <Button data-testid="checkout-button" colorMode="special" onClick={handleCheckout} disabled={isUpdatingPlan}>
          {/* Logged out Sky customer should continue to login. */}
          {isAuthenticated || (allowCheckout && !selectedIsSkyStarterCustomer) ? 'Continue' : 'Continue to login'}
        </Button>
      </div>
      {!isAuthenticated && (
        <div className="flex-1 sky-text-white font-bold md:sky-h7-reg w-3/4 md:w-full py-2 md:py-0 text-center my-0 md:my-4">
          Prefer to speak to one of our friendly Sky Crew? Call{' '}
          <a className="underline md:sky-h7-bold" href={`tel:${unformattedTelPhoneNumber}`}>
            {`${unformattedTelPhoneNumber}`}
          </a>
        </div>
      )}
    </div>
  );
};

export { ContinueButtonArea };
