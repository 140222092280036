import React, { ReactNode, useEffect } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { myAccountV2SWR } from '../swr';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import { BlockLoader } from '@sky-tv-group/components';
import { Helmet } from 'react-helmet';
import { AppRoutes } from '../constants';

const renderOption2 = {
  renderNode: {
    [INLINES.HYPERLINK]: (node: any, children: ReactNode) => (
      <a href={node?.data?.uri} className="sky-copyright sm:sky-copyright md:sky-copyright">
        {children}
      </a>
    ),
  },
};

const renderOptions = {
  preserveWhiteSpace: true,
  renderText: (text: string) =>
    text
      .split('\n')
      .reduce(
        (children: any, textSegment: any, index: number) => [...children, index > 0 && <br key={index} />, textSegment],
        []
      ),
  renderMark: {
    [MARKS.BOLD]: (text: ReactNode) => <b>{text}</b>,
    [MARKS.ITALIC]: (text: ReactNode) => <i>{text}</i>,
  },
  renderNode: {
    [BLOCKS.HEADING_2]: (node: any, children: ReactNode) => (
      <h1 className="sky-h1-black sm:sky-h1-black md:sky-h1-black">{children}</h1>
    ),
    [INLINES.HYPERLINK]: (node: any, children: ReactNode) => (
      <a href={node?.data?.uri} className="font-semibold sky-text-daylight">
        {children}
      </a>
    ),
    [BLOCKS.HEADING_3]: (node: any, children: ReactNode) => (
      <h3 className="sky-h6-bold sm:sky-h6-bold md:sky-h6-bold">{children}</h3>
    ),
    [BLOCKS.PARAGRAPH]: (node: any, children: ReactNode) => <>{children}</>,
    [BLOCKS.UL_LIST]: (node: any, children: ReactNode) => <ul className="list-disc text-xl text-navy">{children}</ul>,
  },
};

export const Offers = () => {
  const slug = AppRoutes?.Offers?.replace('/', '');
  const { data: offerContent, isValidating } = myAccountV2SWR.useGetComposePageGQL(slug);
  useDocumentTitle(offerContent?.data?.composePage?.[0]?.title as string);
  const [
    offerContentBanner,
    BBEntertainmentComponent,
    callPhoneComponent,
    BBGetStartedSection,
    BBOffersVideoSection,
    contactUsSection,
  ] = offerContent?.data?.composePage?.[0]?.content?.content ?? [];
  return (
    <>
      {isValidating ? (
        <div className="h-128">
          <BlockLoader />
        </div>
      ) : (
        <>
          {offerContentBanner?.bannerContent && (
            <>
              <div
                className="hidden md:flex relative text-white w-100 h-100 items-start flex-col justify-center bg-center bg-cover px-6"
                style={{
                  backgroundImage: `url('${offerContentBanner?.bannerContent?.heroImage?.url}')`,
                  height: '520px',
                }}>
                <div className="container mx-auto">
                  {documentToReactComponents(offerContentBanner?.bannerContent?.heading, renderOptions)}
                  <div className="font-bold text-xl text-white">
                    {documentToReactComponents(offerContentBanner?.bannerContent?.detailParagraph, renderOptions)}
                  </div>
                  {offerContentBanner?.bannerContent?.copyrightParagraph && (
                    <div className="absolute bottom-0 right-2">
                      {documentToReactComponents(offerContentBanner?.bannerContent?.copyrightParagraph, renderOption2)}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex text-white flex-col sky-bg-midnight md:hidden">
                <div
                  className="flex relative w-100 h-100 items-start flex-col justify-end bg-center bg-cover px-6"
                  style={{
                    backgroundImage: `url('${offerContentBanner?.bannerContent?.heroImageMobile?.url}')`,
                    height: '450px',
                  }}
                />
                <div className="px-4 relative bottom-4" style={{ backdropFilter: 'blur(8px)' }}>
                  {documentToReactComponents(offerContentBanner?.bannerContent?.heading, renderOptions)}
                </div>
                <div className="p-2">
                  <div className="pb-12 relative bottom-4 font-bold text-xl">
                    {documentToReactComponents(offerContentBanner?.bannerContent?.detailParagraph, renderOptions)}
                  </div>
                  {offerContentBanner?.bannerContent?.copyrightParagraph && (
                    <div className="flex justify-end p-4">
                      {documentToReactComponents(offerContentBanner?.bannerContent?.copyrightParagraph, renderOption2)}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          {BBEntertainmentComponent?.componentCardSectionTitle && (
            <div className="px-4 py-18 sky-header-gradient text-center text-white">
              <h3
                className="mx-auto px-12 text-3xl font-semibold"
                dangerouslySetInnerHTML={{ __html: BBEntertainmentComponent?.componentCardSectionTitle }}
              />
            </div>
          )}
          {BBEntertainmentComponent?.componentCards &&
            BBEntertainmentComponent?.componentCards?.map(({ cardDetailedDescription, links }) => (
              <div className="px-4 py-18 ">
                <div className="text-center px-6 md:px-20 text-3xl mx-auto flex flex-col items-center justify-start gap-4">
                  {documentToReactComponents(cardDetailedDescription, renderOptions)}
                  <div className="flex justify-center items-center gap-6">
                    {links?.map(({ linkText, linkUrl }) => (
                      <a href={linkUrl!} className="sky-button sky-button--secondary-light btn btn-lg min-w-40">
                        {linkText}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          {callPhoneComponent?.componentCards &&
            callPhoneComponent?.componentCards?.map(({ heading, links, cardImageUrl }) => (
              <div
                className="bg-center h-60 md:h-40 bg-cover"
                style={{ backgroundImage: `url('${cardImageUrl?.url}')` }}>
                <div className="container mx-auto px-5 py-4 flex flex-col justify-center gap-4 h-full">
                  <h1 className="text-white text-center text-3xl font-semibold">{heading}</h1>
                  <div className="flex justify-center items-center gap-6">
                    {links?.map(({ linkText, linkUrl }) => (
                      <a href={linkUrl!} className="sky-button sky-button--primary-light btn btn-sm">
                        {linkText}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          {BBGetStartedSection?.componentCards && BBGetStartedSection?.componentCardSectionTitle && (
            <div className="px-4 py-4 container mx-auto flex flex-col gap-12 items-center justify-start">
              <h2 className="w-full p-4 sky-bg-gray-light text-center sky-h2 md:sky-h2-black sky-text-midnight">
                {BBGetStartedSection?.componentCardSectionTitle}
              </h2>
              {BBGetStartedSection?.componentCards && (
                <div className="flex flex-col gap-6 md:flex-row justify-center items-center md:items-start md:gap-4">
                  {BBGetStartedSection?.componentCards?.map(
                    ({ cardTitle, cardImageUrl, links, cardDetailedDescription }) => (
                      <div className="flex w-11/12 md:w-1/2 flex-col gap-2 items-start justify-center">
                        <img src={cardImageUrl?.url!} />
                        <div className="sky-h5-bold md:sky-h4-bold">{cardTitle}</div>
                        <div className="text-md">
                          {documentToReactComponents(cardDetailedDescription, renderOptions)}
                        </div>
                        {links?.map(({ linkText, linkUrl }) => (
                          <div className="flex w-full justify-center">
                            <a href={linkUrl!} className="sky-button sky-button--primary-light btn btn-sm">
                              {linkText}
                            </a>
                          </div>
                        ))}
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
          )}
          {BBOffersVideoSection?.bannerContent && (
            <div className="px-4 py-4 container mx-auto flex flex-col gap-12 items-center justify-start">
              <h2 className="w-full p-4 sky-bg-gray-light text-center text-xl md:text-2xl font-semibold sky-text-midnight">
                {documentToReactComponents(BBOffersVideoSection?.bannerContent?.heading, renderOption2)}
              </h2>
              {BBOffersVideoSection?.bannerContent?.brightCoveVideoId && (
                <div
                  className="w-full hidden lg:flex"
                  id="video-carousel"
                  data-experience="64d189d76f5fc7eecab9be7c"
                  style={{ overflow: 'auto' }}>
                  <Helmet>
                    <script
                      src={`https://players.brightcove.net/${BBOffersVideoSection?.bannerContent?.brightCoveVideoId}/experience_64d189d76f5fc7eecab9be7c/live.js`}></script>
                  </Helmet>
                </div>
              )}
              {BBOffersVideoSection?.bannerContent?.brightCoveVideoIdMobile && (
                <div
                  className="w-full lg:hidden"
                  id="video-carousel"
                  data-experience="64d189d76f5fc7eecab9be7c"
                  style={{ overflow: 'auto' }}>
                  <Helmet>
                    <script
                      src={`https://players.brightcove.net/${BBOffersVideoSection?.bannerContent?.brightCoveVideoIdMobile}/experience_64d189d76f5fc7eecab9be7c/live.js`}></script>
                  </Helmet>
                </div>
              )}
            </div>
          )}
          {contactUsSection?.componentCards &&
            contactUsSection?.componentCards?.map(({ heading, cardImageUrl }) => (
              <div
                className="bg-center h-60 md:h-40 bg-cover"
                style={{ backgroundImage: `url('${cardImageUrl?.url}')` }}>
                <div className="container mx-auto px-5 py-4 flex flex-col justify-center gap-4 h-full">
                  <h1
                    className="text-white text-center text-3xl md:text-2xl font-semibold"
                    dangerouslySetInnerHTML={{ __html: heading! }}
                  />
                </div>
              </div>
            ))}
        </>
      )}
    </>
  );
};
