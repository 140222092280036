import React, { useEffect } from 'react';
import { useForm } from 'react-jeff';
import { FormElement, PropertyDetails, YourDetails, Progression } from '@sky-tv-group/components';
import {
  SPLITIO_KEY,
  useGiveCreditCheckPermission,
  useInstallationDetailsOwnershipField,
  usePropertyDetailsAddressField,
  usePropertyDetailsAddressSameAsInstallationAddressField,
  usePropertyDetailsBillingAddressField,
  usePropertyDetailServiceContactEmailAddressField,
  usePropertyDetailsPaperlessBillingField,
  useSplitIO,
  useYourDetailsDateOfBirthField,
  useYourDetailsEmailField,
  useYourDetailsFamilyNameField,
  useYourDetailsFirstNameField,
  useYourDetailsPhoneAreaCodeField,
  useYourDetailsPhoneTelephoneNumberField,
} from '@sky-tv-group/shared';

import { next } from './transition';
import { CreditCheckCheckbox } from '../../components/checkout';
import { addressService, kkService } from '../../services';
import { storeApi } from '../../store';
import { AppRoutes } from '../../constants';
import history from '../../history';

const AccountDetails = () => {
  const [enableCreditCheck] = useSplitIO(SPLITIO_KEY.SKYWEB_ENABLE_CREDIT_CHECK);

  // Account details
  const detailsFirstNameField = useYourDetailsFirstNameField();
  const detailsFamilyNameField = useYourDetailsFamilyNameField();
  const detailsEmailField = useYourDetailsEmailField();
  const detailsPhoneAreaCodeField = useYourDetailsPhoneAreaCodeField();
  const detailsPhoneTelephoneNumberField = useYourDetailsPhoneTelephoneNumberField();
  const detailsDateOfBirthField = useYourDetailsDateOfBirthField();
  // Property details
  const propertyDetailsAddressField = usePropertyDetailsAddressField();
  const propertyDetailsPaperlessBillingField = usePropertyDetailsPaperlessBillingField();
  const propertyDetailsAddressSameAsInstallationAddressField = usePropertyDetailsAddressSameAsInstallationAddressField();
  const propertyDetailsBillingAddressField = usePropertyDetailsBillingAddressField();
  const propertyDetailsOwnershipField = useInstallationDetailsOwnershipField();
  const serviceEmailField = usePropertyDetailServiceContactEmailAddressField();
  // Checkbox fields - might need to
  const creditCheckCheckbox = useGiveCreditCheckPermission();

  const fields = [
    detailsFirstNameField,
    detailsFamilyNameField,
    detailsEmailField,
    detailsPhoneAreaCodeField,
    detailsPhoneTelephoneNumberField,
    detailsDateOfBirthField,
    propertyDetailsAddressField,
    propertyDetailsPaperlessBillingField,
    propertyDetailsAddressSameAsInstallationAddressField,
    propertyDetailsBillingAddressField,
    propertyDetailsOwnershipField,
  ];

  if (enableCreditCheck) {
    fields.push(creditCheckCheckbox);
  }
  const form = useForm({
    fields,
    onSubmit: () => {},
  });

  // Set installation address from check address results
  useEffect(() => {
    const { selectedAddressId, selectedInstallationAddress, selectedTuiAddress } = storeApi.getState();

    if (selectedAddressId && selectedInstallationAddress && selectedTuiAddress) {
      const address = {
        id: selectedAddressId,
        label: selectedInstallationAddress,
        tui: selectedTuiAddress,
      };
      propertyDetailsAddressField.setValue(address);
      propertyDetailsAddressField.setDisabled(true);
    }

    // need to pick up initial value from sessionstorage once feature is ready
    creditCheckCheckbox.setValue(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goBack = () => history.push(AppRoutes.OrderSummary);
  const goNext = async () => {
    await form.submit();
    if (form.valid) next();
  };

  useEffect(() => {
    if (detailsEmailField.value) {
      serviceEmailField.setValue(detailsEmailField.value);
    }
    // eslint-disable-next-line
  }, [detailsEmailField.value]);

  return (
    <div className="container m-auto md:pb-10 text-navy">
      <FormElement {...form.props}>
        <YourDetails
          titleText="About You"
          formValidation={form}
          firstNameField={detailsFirstNameField}
          familyNameField={detailsFamilyNameField}
          emailField={detailsEmailField}
          phoneAreaCodeField={detailsPhoneAreaCodeField}
          phoneTelephoneNumberField={detailsPhoneTelephoneNumberField}
          dateOfBirthField={detailsDateOfBirthField}
          addressService={addressService}
        />
        <PropertyDetails
          titleText="About Your Property"
          formValidation={form}
          addressField={propertyDetailsAddressField}
          paperlessBillingField={propertyDetailsPaperlessBillingField}
          addressSameAsInstallationAddressField={propertyDetailsAddressSameAsInstallationAddressField}
          billingAddressField={propertyDetailsBillingAddressField}
          serviceEmailField={serviceEmailField}
          ownershipField={propertyDetailsOwnershipField}
          addressService={addressService}
          konakartService={kkService}
          disableAddressField={propertyDetailsAddressField.disabled}
        />
        <CreditCheckCheckbox creditCheckCheckbox={creditCheckCheckbox} />
        <Progression
          next={goNext}
          back={goBack}
          backDataTestId="back-from-account-details"
          nextDataTestId="next-from-account-details"
        />
      </FormElement>
    </div>
  );
};

export { AccountDetails };
