import React from 'react';
import { T_Product, useVoice, productSkuIds } from '@sky-tv-group/shared';
import { kkService, prequalService } from '../../services';
import { myAccountSWR } from '../../swr';
import { PackageCanBuy } from './packages.can.buy';
import { useStore } from '../../store';

export let Voice = () => {
  const { guestPrequalData, authenticatedPrequalData } = useStore(s => ({
    guestPrequalData: s.guestPrequalData,
    authenticatedPrequalData: s.authenticatedPrequalData,
  }));
  const prequal = authenticatedPrequalData ?? guestPrequalData;
  const isVoiceAddable = prequal?.isVoiceAddable();
  const {
    voiceProducts,
    voiceAddonsProducts,
    voiceCrossCountryProducts,
    isHomePhoneOn,
    toggle,
    toggleCrossCountry,
  } = useVoice(kkService, prequalService, myAccountSWR, prequal, false /* dont check for free */);
  const specialToggleLabel = "I'd like a home phone";

  if (!isVoiceAddable) {
    return null;
  }

  return (
    <div
      className="border border-solid border-gray-dark p-2 md:px-0 h-full mx-auto mb-4"
      data-testid={'voice-container'}>
      {voiceProducts
        ?.filter(
          (p: T_Product) =>
            ![
              productSkuIds.voiceSummerSet.primary as string,
              productSkuIds.voiceSummerSetOpt.primary as string,
            ].includes(p?.sku)
        )
        ?.map((p: T_Product) => (
          <PackageCanBuy key={p.id} data={p} toggle={toggle} showZeroAsFree={true} toggleLabel={specialToggleLabel} />
        ))}
      {isHomePhoneOn && (
        <>
          {voiceAddonsProducts.length > 0 && (
            <p className="sky-h6-bold md:sky-h6-bold p-6 border-t">Plans below include mobile & landline calling</p>
          )}
          {voiceAddonsProducts.map((p: T_Product) => (
            <div className="border-t" key={p.id}>
              <PackageCanBuy data={p} toggle={toggle} showZeroAsFree={true} isAddonProduct={true} />
            </div>
          ))}
          {voiceCrossCountryProducts.map((p: T_Product) => (
            <div className="border-t" key={p.id}>
              <PackageCanBuy data={p} toggle={toggleCrossCountry} showZeroAsFree={true} isAddonProduct={true} />
            </div>
          ))}
        </>
      )}
    </div>
  );
};
