import React from 'react';
import cx from 'classnames';
import { Aspect } from '../aspect';
import { Image } from '../Image';
import { formatMoneyWithoutSign, T_Product } from '@sky-tv-group/shared';

export interface IBaseProductItemProps {
  product: T_Product;
  imageBackgroundClassName?: string;
  imageClassName?: string;
}
const BaseProductItem: React.FC<IBaseProductItemProps> = ({
  product,
  imageClassName,
  imageBackgroundClassName,
  children,
}) => {
  const imgBackgroundClassName = cx({
    [imageBackgroundClassName ?? '']: imageBackgroundClassName,
    'bg-black': !imageBackgroundClassName,
  });
  const imgClassName = cx('w-full h-full max-h-40', {
    [imageClassName ?? '']: imageClassName,
    'opacity-image-with-text ': !imageClassName,
  });

  return (
    <div className="h-full flex flex-col">
      <div className={imgBackgroundClassName} style={{ flexBasis: '30%' }}>
        <Aspect ratio={9 / 16}>
          <Image
            className={imgClassName}
            src={product.custom1}
            alt={product.description}
            placeHolderDefaultSize={100}
          />
        </Aspect>
      </div>
      <div
        className="flex flex-col items-center md:text-center text-navy mt-4 pl-4 pr-4 md:border-r-2 hide-end-border-right"
        style={{ flexBasis: '60%' }}>
        <h4 className="text-center md:sky-h4-reg font-bold hidden md:block">{product.name}</h4>
        <h4 className="md:sky-h4-reg pb-3 font-bold hidden md:block">
          <span>$</span>
          {formatMoneyWithoutSign(product?.priceIncTax)}
          <span className="text-xl">/mth</span>
        </h4>
        <h4 className="md:text-center sky-h4 font-bold md:hidden pb-3 w-full">
          {product.name} &ndash; <span>$</span>
          {formatMoneyWithoutSign(product?.priceIncTax)}
          <span className="text-xl">/mth</span>
        </h4>
        <p className="w-full sky-h5 md:sky-h7">{product.description}</p>
      </div>
      <div className="flex justify-center md:justify-around text-center pt-4 flex-shrink-0 flex-wrap">{children}</div>
    </div>
  );
};

export { BaseProductItem };
