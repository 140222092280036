import { Button } from '../../button';
import React from 'react';

interface ProgressionProps {
  back: () => void;
  next: () => void;
  nextText?: string;
  backDataTestId?: string;
  nextDataTestId?: string;
  disableSubmit?: boolean;
}

const Progression: React.FunctionComponent<ProgressionProps> = ({
  back,
  next,
  nextText = 'Next',
  backDataTestId = 'back-button',
  nextDataTestId = 'next-button',
  disableSubmit = false,
}) => {
  return (
    <div>
      <div className="flex justify-center p-6">
        <Button variant="secondary" onClick={back} type="button" data-testid={backDataTestId}>
          Back
        </Button>
        <Button
          onClick={next}
          type="submit"
          data-testid={nextDataTestId}
          colorMode={disableSubmit ? 'disabled' : 'light'}
          disabled={disableSubmit}>
          {nextText}
        </Button>
      </div>
    </div>
  );
};

export { Progression };
