import React from 'react';

export interface IPricingProps {
  showZeroAsFree: boolean;
  priceIncTax: number;
  period?: string;
  priceClassName?: string;
  periodClassName?: string;
  appendTextPriceClassName?: string;
  appendSlash?: boolean;
  showIncluded?: boolean;
  showPending?: boolean;
}

const Pricing: React.FC<IPricingProps> = ({
  showZeroAsFree = false,
  priceIncTax,
  period = 'mth',
  priceClassName = 'sky-h6-reg md:sky-h4',
  periodClassName = 'sky-label md:sky-h6',
  appendTextPriceClassName = undefined,
  appendSlash = true,
  showIncluded = false,
  showPending,
}) => {
  // Append text otherwise leave as is
  const fullPriceName = appendTextPriceClassName ? appendTextPriceClassName + ' ' + priceClassName : priceClassName;
  const appendedSlash = appendSlash ? '/' : ' ';

  if (showZeroAsFree && priceIncTax === 0) {
    return <span className={fullPriceName}>Free</span>;
  } else if (showIncluded) {
    return <span className={fullPriceName}>Included</span>;
  } else if (showPending) {
    return <span className={fullPriceName}>Pending</span>;
  } else {
    return (
      <span className={priceClassName}>
        ${priceIncTax.toFixed(2)}
        <span className={periodClassName}>
          {appendedSlash}
          {period}
        </span>
      </span>
    );
  }
};

export { Pricing };
