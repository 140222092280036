import React from 'react';
import {
  BoxContainer,
  PackageCard,
  FirstMonthOffer,
  FirstMonthBannerConfig,
  SpecialistChannelsContainer,
  OneMonthOnUs,
} from '@sky-tv-group/components';
import {
  useCartContainer,
  CouponTypeValue,
  useFreeProductStore,
  useSplitIO,
  SPLITIO_KEY,
  useJSONConfig,
  useProductWithCouponRemoval,
  T_Product,
} from '@sky-tv-group/shared';
import { InfoPlacard } from '../components/info-placard/InfoPlacard';
import { ContinueButtonArea } from '../components/continue';
import { MultiRoomContainer } from '../components/MultiroomContainer';
import { configService, kkService } from '../services';

const TV = () => {
  const { acquisitionBoxes, toggleBoxes, toggleProduct, packageUpgrades, specials, hindi } = useCartContainer(
    kkService,
    CouponTypeValue.Acquisition
  );

  const { handleRemovalOfT_ProductWithToast } = useProductWithCouponRemoval(kkService);

  const toggleWithToast = async (product: T_Product) => {
    await handleRemovalOfT_ProductWithToast(product, toggleProduct, true);
  };

  const [arrowFlag] = useSplitIO(SPLITIO_KEY.SKYWEB_NEW_SKY_BOX);
  const [shouldShowOneMonthBanner] = useSplitIO(SPLITIO_KEY.SKYWEB_ONE_MONTH_ON_US);
  const [showBBOneMonthOnUs] = useSplitIO(SPLITIO_KEY.SKYWEB_BB_ONE_MONTH_ON_US_ON);
  const [showBBCreditOffer] = useSplitIO(SPLITIO_KEY.SKYWEB_BB_CREDIT_OFFER);
  const config = useJSONConfig(configService);
  const firstMonthBannerConfig: FirstMonthBannerConfig = config?.broadband?.acquisition?.firstMonthBannerConfig;

  // Sort by rating.
  let sortedAddMoreChannels = packageUpgrades.sort(
    ({ rating: previousRating }, { rating: currentRating }) => currentRating - previousRating
  );

  const freeProduct = useFreeProductStore(s => s.freeProduct);

  return (
    <>
      <div className="md:container m-auto mb-10">
        <div>
          {!showBBOneMonthOnUs && shouldShowOneMonthBanner && !showBBCreditOffer && (
            <FirstMonthOffer infoConfig={firstMonthBannerConfig} className={'py-10 px-4 md:px-0 md:py-13'} />
          )}
          {showBBOneMonthOnUs && (
            <OneMonthOnUs
              copy="Get your first month on us when you join with Sky Starter on a 12 month contract."
              tAndCLink="https://www.sky.co.nz/offer-terms"
              learnMoreLink="https://www.sky.co.nz/offers"
              boldText="Get your first month on us"
            />
          )}
          <h3 className="sky-h3 md:sky-h3 text-navy text-center py-10 md:py-13">
            Upgrade Your Sky TV Experience with One of These Packages
          </h3>
        </div>
        <InfoPlacard
          info={
            'Sky Starter base package is included in your monthly plan. Additional packages will be added to your monthly charge.'
          }
          className={'mb-8 mx-4 md:mx-0'}
        />
        <div>
          <div className="flex pb-10 flex-col lg:flex-row items-stetch justify-between lg:gap-1 xl:gap-6">
            {(sortedAddMoreChannels ?? []).map((product, index) => (
              <PackageCard
                kkService={kkService}
                imageBackgroundClassName="bg-white"
                imageClassName="opacity-1 object-cover"
                key={product.id}
                product={product}
                toggleProduct={toggleProduct}
                removable={product.id !== freeProduct?.productId}
              />
            ))}
          </div>
        </div>

        <SpecialistChannelsContainer
          products={[...specials, ...hindi]}
          toggleProduct={toggleWithToast}
          freeProduct={freeProduct}
          className={'py-10 m-auto'}
        />

        <BoxContainer products={acquisitionBoxes} toggleBoxes={toggleBoxes} />
        {arrowFlag && <MultiRoomContainer />}
      </div>
      <ContinueButtonArea />
    </>
  );
};

export { TV };
