import React from 'react';
import { InstallationType, useInstallationForm, useProductInCart, useBroadbandStore } from '@sky-tv-group/shared';
import { FormElement, SiteAccessInformation, Progression } from '@sky-tv-group/components';

import { next, back } from './transition';
import { configService, prequalService, serviceProviderService } from '../../services';
import { useCheckoutManager } from '../../hooks/useCheckoutManager';
import { SkyBroadbandForm } from '../../components/checkout';

const BroadbandInstallation = () => {
  const { selfInstallInCart } = useProductInCart();
  const { customerDetails } = useCheckoutManager();
  const installationType = selfInstallInCart ? InstallationType.SelfInstall : InstallationType.BYOD;
  const { isChangingProvider } = useBroadbandStore();

  const {
    form,
    fields,
    prequalData,
    providers,
    installDates,
    activationDates,
    isShowSiteAccessInfo,
    siteAccessInformationFields,
  } = useInstallationForm(configService, prequalService, serviceProviderService, installationType, customerDetails);

  const goNext = async () => {
    await form.submit();
    if (form.valid) next();
  };

  const isFibreSwap = prequalData?.isFibreSwapJourney();

  return (
    <div className="container m-auto md:pb-10 text-navy">
      <FormElement {...form.props}>
        <SkyBroadbandForm
          form={form}
          fields={fields}
          isFibreSwap={isFibreSwap ?? false}
          isNotIntactOnt={!prequalData?.isIntactONTJourney()}
          isNotChorus={!prequalData?.isChorus()}
          isNewFibre={prequalData?.isNewFibreJourney() ?? false}
          isSelfInstall={selfInstallInCart}
          consentRequired={prequalData?.consentRequired() ?? false}
          isLfcDoesntTellUsIfConsentRequired={prequalData?.isLfcDoesntTellUsIfConsentRequired() ?? false}
          providers={providers}
          installDates={installDates}
          activationDates={activationDates}
        />
        {isShowSiteAccessInfo && <SiteAccessInformation form={form} fields={siteAccessInformationFields} />}
        <Progression next={goNext} back={back} disableSubmit={isFibreSwap && !isChangingProvider} />
      </FormElement>
    </div>
  );
};

export { BroadbandInstallation };
