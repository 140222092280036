import React, { useState } from 'react';
import { T_Product } from '@sky-tv-group/shared';
import { LineProductItem } from './box-line-items/LineProductItem';
import { Button } from './button';

export interface IMultiroomProductItemProps {
  product: T_Product;
  addMultiRoom: (product: T_Product, diff: number) => () => Promise<void>;
  totalMultiRooms: number;
}
const MultiroomProductItem: React.FC<IMultiroomProductItemProps> = ({ product, totalMultiRooms, addMultiRoom }) => {
  const isInCart = product.quantityInCart > 0;
  const [isAddDisabled, setAddIsDisabled] = useState(false);
  const [isRemoveDisabled, setRemoveIsDisabled] = useState(false);

  const addProduct = async () => {
    setAddIsDisabled(true);
    await addMultiRoom(product, 1)();
    setAddIsDisabled(false);
  };

  const removeProduct = async () => {
    setRemoveIsDisabled(true);
    await addMultiRoom(product, -1)();
    setRemoveIsDisabled(false);
  };

  return (
    <LineProductItem product={product}>
      <Button
        dataTestId={'minus-' + product.sku}
        variant="secondary"
        className="sky-button--round"
        onClick={removeProduct}
        disabled={!isInCart || isRemoveDisabled}>
        <span className="block text-4xl mb-1">—</span>
      </Button>
      <span
        className={`mx-1 md:mx-6 min-w-28 px-8 py-4 font-bold leading-8 rounded-round text-white
        ${isInCart ? 'bg-green' : 'bg-blue-light'}`}>
        {product.quantityInCart}
      </span>
      <Button
        dataTestId={'plus-' + product.sku}
        variant="secondary"
        className="sky-button--round"
        onClick={addProduct}
        disabled={totalMultiRooms >= 5 || isAddDisabled}>
        <span className="block text-4xl mb-1">+</span>
      </Button>
    </LineProductItem>
  );
};

export { MultiroomProductItem };
