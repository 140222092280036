import { ConfirmationScreen, Loader, ErrorScreen } from '@sky-tv-group/components';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { HEADER_HEIGHT } from '@sky-tv-group/components';
import {
  useAccountDetailsStore,
  useBroadbandDeliveryStore,
  useCustomerStore,
  useBroadbandStore,
  errorScreenMsg,
  skyCrewContactlinks,
  errorScreenLinkTrext,
  PACKAGE_TYPE
} from '@sky-tv-group/shared';
import { clearSessionStorageState, useStore } from '../../store';
import { kkService, orderService } from '../../services';
import { myAccountV2SWR } from '../../swr';
import { clearState } from '../checkout/transition';

const Confirmation = () => {
  const { accountNumber, email, firstName, isBundle } = useStore();
  const { accountDetails, clearAccountDetails } = useAccountDetailsStore(s => ({
    accountDetails: s.accountDetails,
    clearAccountDetails: s.clearAccountDetails,
  }));
  const orderMsg = myAccountV2SWR.useGetOrderConfirmation();
  const { clearBroadbandDeliveryStore } = useBroadbandDeliveryStore(s => ({
    clearBroadbandDeliveryStore: s.clearBroadbandDeliveryStore,
  }));
  const { clearBroadbandStore } = useBroadbandStore(s => ({
    clearBroadbandStore: s.clearBroadbandStore,
  }));
  const { clearStore, clearPersistedButtonStates } = useStore(s => ({
    clearPersistedButtonStates: s.clearPersistedButtonStates,
    clearStore: s.clearStore,
  }));
  const { setExistingCustomerId, getTempCustomerId } = useCustomerStore(s => ({
    setExistingCustomerId: s.setExistingCustomerId,
    getTempCustomerId: s.getTempCustomerId,
  }));
  const {
    state: { workOrderNumber },
  }: any = useLocation();
  const [name, setName] = useState('');
  const [emailError, setEmailError] = useState('');

  useEffect(() => {
    /**
     * CLEAR ALL STORES
     */
    const clear = async () => {
      const customerId = await getTempCustomerId(kkService);
      clearPersistedButtonStates();
      clearSessionStorageState();
      await kkService.updateOrder(customerId, []);
      setExistingCustomerId('');
      clearStore();
      clearAccountDetails();
      clearBroadbandDeliveryStore();
      clearBroadbandStore();
      clearState();
    };
    clear();

    /* NZ Compare tracking pixel :: Begin */
    if (workOrderNumber) {
      try {
        const { NZCompareScript, NZCompareTrackScript } = (() => {
          const NZCompareScript = document.createElement('script');
          NZCompareScript.type = 'text/javascript';
          NZCompareScript.async = true;
          NZCompareScript.innerHTML = `
            // Required fields
            var afid = '${workOrderNumber}';	// unique order or sale ID
            var afgoal = 'signup';	// goal name. Can be 'lead', 'sale', 'signup' or other
            var afsecure = 'be6a7db9c1f56a6d1956a1287d3338e6';	// security hash provided by NZ Compare manager
            var afoffer_id = 426;	// Offer ID provided by NZ Compare manager
            var afstatus = 2;	// Default Conversion Status: 1 - "Approved", 2 - "Pending", 3 - "Declined", 5 - "Hold" 
          `;
          const NZCompareTrackScript = document.createElement('script');
          NZCompareTrackScript.type = 'text/javascript';
          NZCompareTrackScript.async = true;
          NZCompareTrackScript.src = '//track.roeye.co.nz/track.js';
          return { NZCompareScript, NZCompareTrackScript };
        })();
        document.body.appendChild(NZCompareScript);
        document.body.appendChild(NZCompareTrackScript);
        return () => {
          document.body.removeChild(NZCompareScript);
          document.body.removeChild(NZCompareTrackScript);
        };
      } catch (error) {
        console.log('Error in NZ Compare tracking');
      }
    }
    /* NZ Compare tracking pixel :: End */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let orderScreen;
  if (orderMsg.isValidating) {
    orderScreen = <Loader bgOpacity={false} />;
  }
  if (orderMsg.error) {
    orderScreen = (
      <ErrorScreen errorMsg={errorScreenMsg} linkText={errorScreenLinkTrext} linkURL={skyCrewContactlinks} />
    );
  }
  if (orderMsg?.data) {
    orderScreen = (
      <ConfirmationScreen
        firstName={firstName}
        accountNumber={accountNumber}
        email={email}
        data={orderMsg?.data?.data}
        orderService={orderService}
        packageType={isBundle ? PACKAGE_TYPE.DTH_BROADBAND : PACKAGE_TYPE.BROADBAND}
        setEmailError={setEmailError}
      />
    );
  }

  return (emailError?
    <>
      <div className=" margin-120">
        <ErrorScreen errorMsg={errorScreenMsg} linkText={errorScreenLinkTrext} linkURL={skyCrewContactlinks} />
      </div>
    </>:
    <>
      <div className="margin-120 ">{orderScreen}</div>
    </>
  );
};

export { Confirmation };
