import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import cx from 'classnames';
import { isMobile } from 'react-device-detect';
import { useField, useForm } from 'react-jeff';
import {
  validateInstallationAddress,
  NullableAddressLookup,
  telPhoneNumber,
  useSplitIO,
  SPLITIO_KEY,
  formatAddress,
  useBroadbandStore,
  useBroadbandDeliveryStore,
  useAccountDetailsStore,
  BroadbandReady,
  segment,
  unformattedTelPhoneNumber,
} from '@sky-tv-group/shared';
import { ValidatedAddressSuggest, BlockLoader } from '@sky-tv-group/components';
import { addressService, prequalService } from '../../services';
import { useStore, set } from '../../store';
import { useCheckYourAddress } from '../../hooks/useCheckYourAddress';
import { FibreDescription } from './FibreDescription';
import { useAuth0 } from '../../auth0/store';
import { clearState } from '../../screens/checkout/transition';

const CheckYourAddress = () => {
  const [allowChangingPlans] = useSplitIO(SPLITIO_KEY.SKYWEB_BROADBAND_CHANGING_PLANS);
  const { isAuthenticated, login } = useAuth0(s => ({
    isAuthenticated: s.isAuthenticated,
    login: s.login,
  }));
  const { clearBroadbandStore } = useBroadbandStore();
  const { clearBroadbandDeliveryStore } = useBroadbandDeliveryStore();
  const { clearAccountDetails } = useAccountDetailsStore();
  const [loading, setLoading] = useState<boolean>(false);
  const [allowCheckout] = useSplitIO(SPLITIO_KEY.SKYWEB_BROADBAND_AND_TV_ACQUISTION);

  const {
    isLoadingPrequal,
    setGuestPrequalData,
    setSelectedAddress,
    setUseGuestPrequal,
    setSelectedTuiAddress,
    setSelectedAddressId,
    clearStore,
    authTuiAddress,
    selectedTuiAddress,
    selectedIsSkyStarterCustomer,
  } = useStore(s => ({
    isLoadingPrequal: s.isLoadingPrequal,
    setGuestPrequalData: s.setGuestPrequal,
    setSelectedAddress: s.setSelectedInstallationAddress,
    clearStore: s.clearStore,
    setUseGuestPrequal: s.setUseGuestPrequal,
    setSelectedTuiAddress: s.setSelectedTuiAddress,
    setSelectedAddressId: s.setSelectedAddressId,
    authTuiAddress: s.authTuiAddress,
    selectedTuiAddress: s.selectedTuiAddress,
    selectedIsSkyStarterCustomer: s.selectedIsSkyStarterCustomer,
  }));

  const {
    isFibreFuture,
    isFibreNeverOrUnknown,
    isFibreOk,
    getTitle,
    getWifiGuy,
    executeHomeButtonAction,
    getButtonLabel,
    authTuiDiffersFromSelected,
    hasActiveOrPendingBroadbandPackage,
  } = useCheckYourAddress();

  const showCTAButton = hasActiveOrPendingBroadbandPackage ? allowChangingPlans : true;

  const installationAddress = useField<NullableAddressLookup>({
    defaultValue: { id: '', label: '' },
    required: true,
    validations: [validateInstallationAddress],
  });

  const submit = function() {};
  const form = useForm({
    fields: Object.values(installationAddress),
    onSubmit: submit,
  });

  useEffect(() => {
    async function prequalify(addressId: string) {
      try {
        setSelectedAddressId(addressId);

        const addressDetails = await addressService.getAddressDetail(addressId);
        const tui = get(addressDetails, 'references.tui', undefined);

        if (!tui) {
          throw new Error('Error');
        }

        setSelectedTuiAddress(tui);
        setLoading(true);

        const prequal = await prequalService.getPrequalDetails(tui);
        if (prequal) {
          const selectedAddressLines = formatAddress(
            addressDetails.formatted_delivery_address.line1,
            addressDetails.formatted_delivery_address.line2,
            addressDetails.formatted_delivery_address.line3,
            addressDetails.formatted_delivery_address.line4
          );

          setGuestPrequalData(prequal);
          setSelectedAddress(installationAddress?.value?.label, selectedAddressLines);
          // use guest prequal data when checking fibre availability
          setUseGuestPrequal(true);

          // analytics
          const broadbandReadyStatus =
            prequal.getBroadbandReadyStatus().broadbandReadyStatus === BroadbandReady.FibreOK
              ? 'Fibre Available'
              : prequal.getBroadbandReadyStatus().broadbandReadyStatus === BroadbandReady.FibreFuture
              ? 'Fibre Not Yet Available'
              : 'Fibre Unavailable';

          segment.prequalAddressChecked(isAuthenticated, broadbandReadyStatus);
        }

        setLoading(false);
      } catch (err) {
        console.log('Error');
      }
    }

    if (installationAddress?.value && installationAddress.value.id !== '') {
      prequalify(installationAddress.value.id);
    }
    // eslint-disable-next-line
  }, [installationAddress.value]);

  // Clears local storage information if a new address is selected in
  // the address bar
  const clearLocalDataStores = () => {
    clearStore();
    clearAccountDetails();
    clearBroadbandStore();
    clearBroadbandDeliveryStore();
    clearState();
  };

  const resetInstallationAddressField = () => {
    setUseGuestPrequal(true);
    clearLocalDataStores();
    installationAddress.reset();
    setSelectedTuiAddress('');
    set({ redirectFromCheckingPage: false });
  };

  // const startOver = () => {
  //   setUseGuestPrequal(false);
  //   setSelectedTuiAddress(authTuiAddress);
  //   clearLocalDataStores();
  //   set({ redirectFromCheckingPage: false });
  // };

  const isShowStartOver = () => {
    return (isAuthenticated && authTuiDiffersFromSelected()) || (isAuthenticated && selectedTuiAddress === '');
  };
  const maxWidth = { maxWidth: 532 };
  const zIndex = { zIndex: 15 };
  return (
    <div
      className="py-4 max-w-none static w-full flex flex-col items-center"
      style={{ background: 'radial-gradient(100% 100% at 0% 0%, #00A1D6 0%, #000A9C 74.48%)' }}>
      {!isLoadingPrequal && (
        <div className="m-auto relative container">
          <div className="flex gap-2">
            <div className="flex-1 lg:gap-3 md:justify-between sky-text-white flex flex-col gap-1 lg:items-center lg:flex-row w-full px-8 md:px-0">
              <div className="sky-h2-bold" style={{ maxWidth: 592 }}>
                {isShowStartOver() ? getTitle().replace('1. ', '') : getTitle()}
              </div>
              {isFibreNeverOrUnknown() && (
                <div className="flex lg:hidden sky-text-white sky-h6-reg py-2">
                  {
                    <FibreDescription
                      clearInstallationAddressField={resetInstallationAddressField}
                      showNeverMsg={true}
                    />
                  }
                </div>
              )}
              {(isFibreFuture() || isFibreNeverOrUnknown()) && (
                <>
                  <div className=" lg:hidden flex-1 relative flex w-full justify-start lg:justify-end items-center sky-h4-bold">
                    Not your address?
                  </div>
                  <div className="hidden flex-1 relative lg:flex w-full justify-start lg:justify-end items-center sky-h4-reg">
                    Not your address?
                  </div>
                </>
              )}
              <FibreDescription clearInstallationAddressField={resetInstallationAddressField} />
              {!isFibreOk() && (
                <div className="flex-1 sky-text-black relative flex md:justify-start items-center w-full">
                  <div className="w-full" style={{ ...maxWidth, ...zIndex }}>
                    <ValidatedAddressSuggest
                      addressField={installationAddress}
                      formValidation={form}
                      id="address"
                      addressType="Address"
                      addressService={addressService}
                      placeholder="Enter your address"
                      className="w-full"
                      disabled={loading}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="hidden lg:flex sky-text-white sky-h6-reg pt-1 px-8 md:px-0">
            <FibreDescription clearInstallationAddressField={resetInstallationAddressField} showNeverMsg={true} />
          </div>

          {isShowStartOver() && isFibreOk() && (
            <div className="sky-text-white pl-8 md:pl-0 pb-3 lg:w-2/3">
              If you want to get Sky Broadband for a different address please call us on{' '}
              <span className="sky-h7-bold whitespace-no-wrap underline">{`${unformattedTelPhoneNumber}`}</span>
            </div>
          )}

          {/* Only show the button for Sky Box customers. */}
          {/* {(isFibreFuture() || (isAuthenticated && showCTAButton) || (isFibreOk() && !isAuthenticated)) &&
            (allowCheckout || selectedIsSkyStarterCustomer) && (
              <div className={executeButtonContainerStyles}>
                <button
                  onClick={executeHomeButtonAction}
                  className="self-center sky-button sky-button--broaband-dark-font mx-auto cursor-pointer">
                  {getButtonLabel()}
                </button>
              </div>
            )} */}

          {loading && <BlockLoader style={{ height: '4rem' }} />}

          {/* {!isAuthenticated && (
            <div className="sky-text-white text-center">
              <div className="sky-h7-bold"></div>
              <div>
                Already a customer?{' '}
                <button className="sky-text-sunny font-bold" onClick={login}>
                  Login
                </button>{' '}
                to see the best deals for your place.
              </div>
            </div>
          )} */}

          {!isShowStartOver() && isAuthenticated && !isFibreFuture() && !isFibreNeverOrUnknown() && (
            <div className="sky-text-white">
              <div className="px-8 md:px-0 sky-h7-reg mt-2">
                If you want to get Sky Broadband for a different address please call us on{' '}
                <span className="sky-h7-bold whitespace-no-wrap underline">{`${unformattedTelPhoneNumber}`}</span>
              </div>
            </div>
          )}
        </div>
      )}
      {/* {!isLoadingPrequal && isShowStartOver() && (
        <div className="sky-text-sunny text-center py-3">
          <a className="underline cursor-pointer" href="/#" onClick={startOver}>
            Start Over
          </a>
        </div>
      )} */}
      {isLoadingPrequal && <BlockLoader />}
    </div>
  );
};

export { CheckYourAddress };
