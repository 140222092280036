import { AccountDetails } from './AccountDetails';
import { BroadbandInstallation } from './BroadbandInstallation';
import { Review } from './Review';
import { TVInstallation } from './TVInstallation';
import { VoiceDetails } from './VoiceDetails';
import { CheckoutStates, ICheckoutRoute } from '@sky-tv-group/shared';

export const routes: ICheckoutRoute[] = [
  {
    title: 'Your Details',
    path: '/checkout/account-details',
    component: AccountDetails,
    exact: true,
    type: CheckoutStates.DETAILS,
  },
  {
    title: 'TV',
    path: '/checkout/tv',
    component: TVInstallation,
    exact: true,
    type: CheckoutStates.TV,
  },
  {
    title: 'Broadband',
    path: '/checkout/broadband',
    component: BroadbandInstallation,
    exact: true,
    type: CheckoutStates.BROADBAND,
  },
  {
    title: 'Home Phone',
    path: '/checkout/home-phone',
    component: VoiceDetails,
    exact: true,
    type: CheckoutStates.VOICE,
  },
  {
    title: 'Review',
    path: '/checkout/review',
    component: Review,
    exact: true,
    type: CheckoutStates.REVIEW,
  },
];
