import { Acquisition } from './screens/acquisition';
import { Home } from './screens/home';
import { NetworkStatus } from './screens/network-status';
import { CheckYourSubscription } from './screens/check';
import { CheckoutManager } from './screens/checkout';
import { TV } from './screens/tv';
import { OrderSummary } from './screens/order-summary';
import { AppRoutes } from './constants';
import { Confirmation } from './screens/confirmation';
import { NotFoundContainer } from '@sky-tv-group/components';
import { Setup } from './screens/setUp';
import { Offers } from './screens/offers';

export let routes = [
  {
    path: AppRoutes.Home,
    title: 'Home',
    component: Home,
    exact: true,
  },
  {
    path: AppRoutes.NetworkStatus,
    title: 'NetworkStatus',
    component: NetworkStatus,
    exact: true,
  },
  {
    path: AppRoutes.Offers,
    title: 'Offers',
    component: Offers,
    exact: true,
  },
  {
    path: AppRoutes.Setup,
    title: 'Setup',
    component: Setup,
    exact: true,
  },
  {
    path: AppRoutes.Broadband,
    title: 'Acquisition',
    component: Acquisition,
    exact: true,
  },
  {
    path: AppRoutes.Checking,
    title: 'Checking',
    component: CheckYourSubscription,
    exact: true,
  },
  {
    path: AppRoutes.Checkout,
    title: 'Checkout',
    component: CheckoutManager,
    exact: false,
  },
  {
    path: AppRoutes.TV,
    title: 'TV',
    component: TV,
    exact: true,
  },
  {
    path: AppRoutes.OrderSummary,
    title: 'Order Summary',
    component: OrderSummary,
    exact: true,
  },
  {
    path: AppRoutes.Confirmation,
    title: 'Success',
    component: Confirmation,
    exact: true,
  },
  {
    path: AppRoutes.NotFound,
    title: 'Not Found',
    component: NotFoundContainer,
    exact: false,
  },
];
