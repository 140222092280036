import React from 'react';
import { myAccountV2SWR } from '../swr';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import { BlockLoader } from '@sky-tv-group/components';
import { AppRoutes } from '../constants';

export const Setup = () => {
  const slug = AppRoutes?.Setup?.replace('/', '');
  const { data: setUpContent, isValidating } = myAccountV2SWR.useGetComposePageGQL(slug);
  useDocumentTitle(setUpContent?.data?.composePage?.[0]?.title as string);
  const datass: any = setUpContent?.data.composePage[0];
  const bannerContent = () => {
    return (
      <div className="absolute pl-7 md:pl-8" style={{ top: '3rem' }}>
        <div className=" title-text sky-h1-bold sm:sky-h1-bold md:sky-h1-black sky-text-white xs:sky-h1 whitespace-pre-line">
          {datass?.content.content[0].bannerContent?.heading.content[0].content[0].value}
          <br />
        </div>
        <div className="description-text text-md lg:text-5xl sky-h4-bold sm:sky-h4-bold md:sky-h4-bold xs:sky-h4 sky-text-white md:whitespace-pre-line lg:whitespace-pre-line">
          {datass?.content.content[0].bannerContent?.detailParagraph?.content[0].content[0].value}
        </div>
      </div>
    );
  };
  return (
    <>
      {isValidating ? (
        <div className="h-128">
          <BlockLoader />
        </div>
      ) : (
        <div className="sky-bg-white">
          <div
            className="flex bg-no-repeat bg-cover md:hidden lg:hidden relative"
            style={{
              backgroundImage: `url(${datass?.content.content[0].bannerContent?.heroImage.url})`,
              height: '300px',
              backgroundSize: '100% 100%',
            }}>
            {bannerContent()}
          </div>
          <section>
            <div
              className="hidden lg:flex relative w-100 h-100 d-flex align-items-center bg-center bg-cover md:text-center lg:text-left px-6"
              style={{
                backgroundImage: `url(${datass?.content.content[0].bannerContent?.heroImage.url})`,
                height: '550px',
              }}>
              {bannerContent()}
            </div>
          </section>
          <section className="flex">
            <div className="w-full px-6 py-6">
              <div className="row no-gutters">
                <div className="flex flex-col items-center justify-center flex-shrink-0 justify-around md:flex-row items-center justify-center justify-evenly flex-shrink-0 justify-around">
                  <div className="col-span-6 justify-center md:col-span-6">
                    <div id="fragment-117142-nhtd">
                      <div className="fr-paragraph fr-paragraph-text-color-midnight fr-paragraph-text-align-center fr-paragraph-text-font-markprobold fr-paragraph-text-size-s">
                        <h4 className="sky-text-midnight text-center px-8 py-4 md:p-4 flex justify-center cursor-pointer">
                          <div className="sky-h6-bold md:sky-h7-bold text-2xl text-left sm:text-2xl">
                            <b>{datass?.content.content[1].componentCards[0].heading}</b>
                          </div>
                        </h4>
                      </div>
                    </div>
                    <div id="fragment-117142-nhtd" className="mt-2">
                      <div className="fr-paragraph fr-paragraph-bs-3 fr-paragraph-text-color-light fr-paragraph-text-align-center fr-paragraph-text-font-markprobold">
                        <div>
                          <strong>
                            <a
                              href={datass?.content.content[1].componentCards[0].links[0].linkDocument.url}
                              style={{ color: '#0b5fff', textDecoration: 'none', transition: 'color 0.3s ease-in-out' }}
                              onMouseEnter={(e: any) => (e.target.style.color = '#2e5aac')}
                              onMouseLeave={(e: any) => (e.target.style.color = '#0b5fff')}>
                              {datass?.content.content[1].componentCards[0].links[0].linkDocument.text}
                            </a>
                            <br></br>
                            <a
                              href={datass?.content.content[1].componentCards[0].links[1].linkDocument.url}
                              style={{ color: '#0b5fff', textDecoration: 'none', transition: 'color 0.3s ease-in-out' }}
                              onMouseEnter={(e: any) => (e.target.style.color = '#2e5aac')}
                              onMouseLeave={(e: any) => (e.target.style.color = '#0b5fff')}>
                              {datass?.content.content[1].componentCards[0].links[1].linkDocument.text}
                            </a>
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-6 justify-center md:col-span-6">
                    <div id="fragment-117142-nhtd">
                      <h4 className="sky-text-midnight text-center px-8 py-4 md:p-4 flex justify-center cursor-pointer">
                        <div className="sky-h6-bold md:sky-h7-bold text-2xl text-center sm:text-2xl">
                          {datass?.content.content[1].componentCards[1].heading}
                        </div>
                      </h4>
                    </div>
                    <div id="fragment-628242-nohy" className="mt-2">
                      <div className="custom-sky-button pb-5 text-center text-break">
                        <a
                          className="sky-button sky-button--secondary-light btn btn-sm"
                          href={datass?.content.content[1].componentCards[1].links[0].linkUrl}
                          id="fragment-nohy-link"
                          target="_blank">
                          {datass?.content.content[1].componentCards[1].links[0]?.internalName}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};
