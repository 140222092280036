import { interpret, assign, State, Machine } from 'xstate';
import { categoryIds, sessionStorage, CheckoutNavigationContext, CheckoutStates } from '@sky-tv-group/shared';
import { hasProduct } from '../../helper';
import history from '../../history';

// Allows transitions to different states from Review page
const EDIT_STATES = {
  [CheckoutStates.TV]: { target: CheckoutStates.TV, cond: 'hasTV' },
  [CheckoutStates.BROADBAND]: { target: CheckoutStates.BROADBAND, cond: 'hasBroadband' },
  [CheckoutStates.VOICE]: { target: CheckoutStates.VOICE, cond: 'hasVoice' },
  [CheckoutStates.DETAILS]: { target: CheckoutStates.DETAILS },
};

export const checkoutMachine = Machine<CheckoutNavigationContext>(
  {
    id: 'checkout',
    context: {
      details: {},
      broadband: {},
      tv: {},
      voice: {},
      review: {},
    },
    initial: 'details',
    states: {
      details: {
        entry: ['route'],
        meta: {
          path: '/checkout/account-details',
        },
        on: {
          NEXT: [
            { target: CheckoutStates.TV, cond: 'hasTV', actions: assign({ details: (_, e) => e.value }) },
            { target: CheckoutStates.BROADBAND, cond: 'hasBroadband', actions: assign({ details: (_, e) => e.value }) },
            { target: CheckoutStates.VOICE, cond: 'hasVoice', actions: assign({ details: (_, e) => e.value }) },
            { target: CheckoutStates.REVIEW, actions: assign({ details: (_, e) => e.value }) },
          ],
        },
      },
      tv: {
        entry: ['route'],
        meta: {
          path: '/checkout/tv',
        },
        on: {
          BACK: CheckoutStates.DETAILS,
          NEXT: [
            {
              target: CheckoutStates.BROADBAND,
              cond: 'hasBroadband',
              actions: assign({ tv: (_, e) => e.value }),
            },
            { target: CheckoutStates.VOICE, cond: 'hasVoice', actions: assign({ tv: (_, e) => e.value }) },
            { target: CheckoutStates.REVIEW, actions: assign({ tv: (_, e) => e.value }) },
          ],
        },
      },
      broadband: {
        entry: ['route'],
        meta: {
          path: '/checkout/broadband',
        },
        on: {
          BACK: [{ target: CheckoutStates.TV, cond: 'hasTV' }, { target: CheckoutStates.DETAILS }],
          NEXT: [
            { target: CheckoutStates.VOICE, cond: 'hasVoice', actions: assign({ broadband: (_, e) => e.value }) },
            { target: CheckoutStates.REVIEW, actions: assign({ broadband: (_, e) => e.value }) },
          ],
        },
      },
      voice: {
        entry: ['route'],
        meta: {
          path: '/checkout/home-phone',
        },
        on: {
          BACK: CheckoutStates.BROADBAND,
          NEXT: [{ target: CheckoutStates.REVIEW, actions: assign({ voice: (_, e) => e.value }) }],
        },
      },
      review: {
        entry: ['route', 'setReviewActive'],
        meta: {
          path: '/checkout/review',
        },
        on: {
          BACK: [
            { target: CheckoutStates.VOICE, cond: 'hasVoice' },
            { target: CheckoutStates.BROADBAND, cond: 'hasBroadband' },
            { target: CheckoutStates.TV, cond: 'hasTV' },
            { target: CheckoutStates.DETAILS },
          ],
          ...EDIT_STATES,
        },
      },
    },
  },
  {
    guards: {
      hasBroadband: () => hasProduct(categoryIds.broadband),
      hasVoice: () => hasProduct(categoryIds.voice),
      hasTV: () => hasProduct(categoryIds.package),
    },
    actions: {
      route: () => {
        const state = service.state;
        const url = state.meta[`${service.id}.${state.value}`].path;
        history.push(url);
      },
      setReviewActive: assign({ review: (_, e) => e.value }),
    },
  }
);

export const service = interpret(checkoutMachine);
export const next = () => service.send('NEXT', { value: { valid: true } });
export const back = () => service.send('BACK');
export const edit = (state: string) => service.send(state);

// Start the state machine with local state rehydration
export const startService = () => {
  const localState = sessionStorage.getItem(checkoutMachine.id) ?? JSON.stringify(null);
  const stateDefinition = JSON.parse(localState) || checkoutMachine.initialState;
  const previousState = State.create<CheckoutNavigationContext>(stateDefinition);
  service.start(checkoutMachine.resolveState(previousState));
};

// Stop the machine
export const stopService = () => service.stop();

// Delete session storage state
export const clearState = () => sessionStorage.removeItem(checkoutMachine.id);
