import { useJSONConfig } from '@sky-tv-group/shared';
import cx from 'classnames';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { AppRoutes } from '../constants';
import { configService } from '../services';

const SubMenu = () => {
  let location = useLocation();
  let config = useJSONConfig(configService);
  let menus: { name: string; link: string }[] = config?.broadband?.acquisition?.subMenu ?? [];
  // only show on home page
  if (
    location.pathname !== AppRoutes.Home &&
    location.pathname !== AppRoutes.NetworkStatus &&
    location.pathname !== AppRoutes.Setup &&
    location.pathname !== AppRoutes.Offers
  )
    return null;

  return (
    <div className="m-auto overflow-x-auto sticky bg-white top-4 z-30 shadow-md">
      <nav className="flex md:ml-auto pl-0 md:pl-28 2xl:pl-36 text-navy">
        {menus.map((menu, index) => {
          let selected = index === 0;
          if (location.pathname !== '/') {
            selected = menu.link.includes(location.pathname);
          }
          let cls = cx('sky-h4 md:sky-h7 font-bold whitespace-no-wrap hover:text-blue-light', {
            'text-blue-light border-b-2 border-blue-light': selected,
          });
          return (
            <div className="md:pl-4 2xl:pl-3 p-2 px-6" key={index}>
              <a href={menu.link} className={cls}>
                {menu.name}
              </a>
            </div>
          );
        })}
      </nav>
    </div>
  );
};

export { SubMenu };
