import { UserGroupType } from '@sky-tv-group/shared';
import React from 'react';

import { useAuth0 } from '../auth0/store';

interface Props {
  includes?: UserGroupType[];
  excludes?: UserGroupType[];
  fallback?: React.ReactNode;
}

const Permission: React.FunctionComponent<Props> = ({ includes = [], excludes = [], children, fallback = null }) => {
  const { userGroupTypes } = useAuth0();

  let isInIncludes = includes.length === 0 || userGroupTypes.some(ugt => includes.some(g => g === ugt));

  let isInExcludes = excludes.length !== 0 && userGroupTypes.some(ugt => excludes.some(g => g === ugt));

  let show = !isInExcludes && isInIncludes;

  return <> {show ? children : fallback} </>;
};

export { Permission };
