import React, { useEffect, useRef } from 'react';
import { Route, Switch } from 'react-router-dom';
import { sessionStorage, SPLITIO_KEY, useSplitIO } from '@sky-tv-group/shared';
import { BlockLoader, CheckoutNavigation } from '@sky-tv-group/components';
import { service, startService, stopService, checkoutMachine } from './checkout/transition';
import { routes } from './checkout/routes';
import { useCheckoutManager } from '../hooks/useCheckoutManager';
import history from '../history';
import { AppRoutes } from '../constants';

export const CheckoutManager = () => {
  const [allowCheckout] = useSplitIO(SPLITIO_KEY.SKYWEB_BROADBAND_AND_TV_ACQUISTION);
  const { allowedRoutes, showCheckout, initializedOrderStore } = useCheckoutManager();
  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (allowCheckout) {
      startService();

      const subscription = service.subscribe(state => {
        sessionStorage.setItem(checkoutMachine.id, JSON.stringify(state));
      });

      return () => {
        subscription.unsubscribe();
        stopService();
      };
    }
  }, [allowCheckout]);

  if (!allowCheckout || !showCheckout) {
    history.push(AppRoutes.Home);
  }

  if (!initializedOrderStore)
    return (
      <div className="h-128">
        <BlockLoader />
      </div>
    );

  return (
    <>
      <h2 ref={headerRef} className="sky-h2 md:sky-h2 text-center py-16 text-white sky-blue-gradient checkout-banner">
        Checkout
      </h2>
      <CheckoutNavigation routes={routes} allowedRoutes={allowedRoutes} headerRef={headerRef} service={service} />
      <Switch>
        {routes
          .filter(r => allowedRoutes.includes(r.type))
          .map(({ path, exact, component }) => {
            return <Route key={path} exact={exact} path={path} component={component} />;
          })}
      </Switch>
    </>
  );
};
