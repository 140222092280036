import { AddressPrequalification, T_Product, ReduxDevTools, StatePersistence, T_Customer } from '@sky-tv-group/shared';
import { SkyCustomerYesNoOption, YesNoValueType } from './types';
import create from 'zustand';

interface AddressLines {
  line1: string;
  line2: string;
  line3: string;
  line4: string;
}

interface StoreData {
  authenticatedPrequalData: AddressPrequalification | undefined;
  guestPrequalData: AddressPrequalification | undefined;
  isLoadingPrequal: boolean;
  isUpdatingPlan: boolean;
  selectedCardSku: string | null;
  useGuestPrequal: boolean;
  selectedInstallationAddress: string | undefined;
  selectedTuiAddress: string | undefined;
  selectedAddressId: string | undefined;
  authTuiAddress: string | undefined;
  selectedIsSkyStarterCustomer: boolean;
  broadbandProductSelected: T_Product | undefined;
  isBundle: boolean;
  customer: T_Customer | undefined;
  hasActiveOrPendingBroadbandPackage: boolean;
  persistedButtonStates: {
    vulnerableCustomerInfo: YesNoValueType;
    wifiBoosterQuestion1: YesNoValueType;
    wifiBoosterQuestion2: YesNoValueType;
    wifiBoosterQuestion3: YesNoValueType;
    wifiBoosterAlreadyKnow: YesNoValueType;
  };
  selectedAddressLines: AddressLines | undefined;
  redirectFromCheckingPage: boolean;
}

const sessionStorageStateName = 'Broadband SPA Store';
const getSessionStorageState: StoreData = JSON.parse(sessionStorage.getItem(sessionStorageStateName) ?? '{}');
const clearSessionStorageState = () => {
  sessionStorage.setItem(sessionStorageStateName, '{}');
};

// Converts prequal json data to AddressPrequalification objects
const transform = (storageState: StoreData) => {
  Object.keys(storageState).forEach(key => {
    if (key === 'authenticatedPrequalData' || key === 'guestPrequalData') {
      storageState[key] = new AddressPrequalification(storageState[key]?.prequal);
    }
  });
  return storageState;
};

const persistedButtonsDefaultState: StoreData['persistedButtonStates'] = {
  vulnerableCustomerInfo: SkyCustomerYesNoOption.NO,
  wifiBoosterQuestion1: SkyCustomerYesNoOption.NO,
  wifiBoosterQuestion2: SkyCustomerYesNoOption.NO,
  wifiBoosterQuestion3: SkyCustomerYesNoOption.NO,
  wifiBoosterAlreadyKnow: SkyCustomerYesNoOption.NO,
};

interface Store extends StoreData {
  customer: T_Customer | undefined;
  accountNumber: string;
  email: string;
  firstName: string;
  setAccountNumber: (value: string) => void;
  setEmail: (value: string) => void;
  setFirstName: (value: string) => void;
  setAuthenticatedPrequal: (prequal: AddressPrequalification) => void;
  setGuestPrequal: (prequal: AddressPrequalification) => void;
  setIsLoading: (value: boolean) => void;
  setUseGuestPrequal: (value: boolean) => void;
  setSelectedInstallationAddress: (value: string | undefined, selectedAddressLines: AddressLines) => void;
  setSelectedTuiAddress: (value: string | undefined) => void;
  setSelectedAddressId: (value: string | undefined) => void;
  setAuthTuiAddress: (value: string | undefined) => void;
  setBroadbandProductSelected: (value: T_Product) => void;
  setIsBundle: (value: boolean) => void;
  setCustomer: (value: T_Customer) => void;
  setPersistedButtonStates: <K extends keyof StoreData['persistedButtonStates']>(
    key: K,
    value: StoreData['persistedButtonStates'][K]
  ) => void;
  clearStore: () => void;
  clearPersistedButtonStates: () => void;
  persistToStorage: () => void;
  setSelectedIsSkyStarterCustomer: (value: boolean) => void;
  setIsUpdatingPlan: (value: boolean) => void;
  setSelectedCardSku: (value: string) => void;
}

let [useStore, storeApi] = create<Store>(
  ReduxDevTools(
    StatePersistence(
      (set, get) => ({
        authenticatedPrequalData: undefined,
        guestPrequalData: undefined,
        accountNumber: '',
        email: '',
        firstName: '',
        selectedInstallationAddress: '',
        selectedTuiAddress: '',
        selectedAddressId: '',
        authTuiAddress: '',
        selectedAddressLines: undefined,
        broadbandProductSelected: undefined,
        isBundle: false,
        customer: undefined,
        hasActiveOrPendingBroadbandPackage: false,
        selectedIsSkyStarterCustomer: false,
        redirectFromCheckingPage: false,
        persistedButtonStates: persistedButtonsDefaultState,

        ...(transform(getSessionStorageState) as {}),

        // booleans that should'nt be persisted in storage
        isLoadingPrequal: false,
        useGuestPrequal: getSessionStorageState.useGuestPrequal ?? false,
        isUpdatingPlan: false,
        selectedCardSku: null,
        setAccountNumber: (value: string) => {
          set({ accountNumber: value }, 'set Account number');
        },
        setEmail: (value: string) => {
          set({ email: value }, 'set Email');
        },
        setFirstName: (value: string) => {
          set({ firstName: value }, 'set First Name');
        },
        setAuthenticatedPrequal: (prequal: AddressPrequalification) => {
          set({ authenticatedPrequalData: prequal });
        },
        setGuestPrequal: (prequal: AddressPrequalification) => {
          set({ guestPrequalData: prequal });
        },
        setIsLoading: (isLoading: boolean) => {
          set({ isLoadingPrequal: isLoading });
        },
        setUseGuestPrequal: (value: boolean) => {
          set({ useGuestPrequal: value });
        },
        setSelectedInstallationAddress: (value: string | undefined, selectedAddressLines: AddressLines) => {
          set({ selectedInstallationAddress: value, selectedAddressLines });
        },
        setSelectedTuiAddress: (value: string | undefined) => {
          set({ selectedTuiAddress: value });
        },
        setSelectedAddressId: (value: string | undefined) => {
          set({ selectedAddressId: value });
        },

        setAuthTuiAddress: (value: string | undefined) => {
          set({ authTuiAddress: value });
        },

        setBroadbandProductSelected: (value: T_Product) => {
          set({ broadbandProductSelected: value }, `Product selected ${value.sku}`);
        },

        setIsBundle: (value: boolean) => {
          set({ isBundle: value });
        },

        setSelectedIsSkyStarterCustomer: (value: boolean) => {
          set({ selectedIsSkyStarterCustomer: value });
        },

        setCustomer: (value: T_Customer) => {
          set({ customer: value });
        },

        setIsUpdatingPlan: (value: boolean) => {
          set({ isUpdatingPlan: value });
        },

        setSelectedCardSku: (value: string) => {
          set({ selectedCardSku: value });
        },

        setPersistedButtonStates: <K extends keyof StoreData['persistedButtonStates']>(
          key: K,
          value: StoreData['persistedButtonStates'][K]
        ) => {
          set({
            persistedButtonStates: {
              ...get().persistedButtonStates,
              [key]: value,
            },
          });
        },

        clearStore: () => {
          set({
            guestPrequalData: undefined,
            selectedInstallationAddress: '',
          });
        },
        clearPersistedButtonStates: () => {
          set({
            persistedButtonStates: persistedButtonsDefaultState,
          });
        },
        persistToStorage: () => {
          const state = get();
          set({ ...state });
        },
      }),
      'Broadband SPA Store'
    ),
    sessionStorageStateName
  )
);

let set = storeApi.setState;
export { useStore, set, storeApi, clearSessionStorageState };
