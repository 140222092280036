import React from 'react';
import { T_Product } from '@sky-tv-group/shared';
import { Disclosure } from '../disclosure';
import { BoxProductItem } from '../box-acquisition/BoxProductItem';

/**
 * Parent container for the Box Acquisition.
 */
interface IBoxContainerProps {
  products: T_Product[];
  toggleBoxes: (
    product: T_Product,
    update?: boolean | undefined,
    removeAccessories?: boolean | undefined,
    trackEvent?: boolean
  ) => Promise<void>;
}

const BoxContainer = ({ products, toggleBoxes }: IBoxContainerProps) => {
  return (
    <div className="md:pb-0">
      <Disclosure header={'My Sky Recording'} hide={false} classNames={'px-1'}>
        <h4 className="hidden md:flex mb-2 relative text-gray-darker text-center h-24 p-4 justify-center items-center sky-h4 md:sky-h6">
          A Sky box is included with Sky Starter - if you want to add My Sky select an option below
        </h4>
        {/* // this check is required as on mobile display issues occur on carousel dots  */}
        {products.length !== 0 &&
          (products ?? []).map(product => (
            <BoxProductItem key={product.id} product={product} toggleProduct={toggleBoxes} removable={false} />
          ))}
      </Disclosure>
    </div>
  );
};

export { BoxContainer };
