export type YesNoValueType = 'YES' | 'NO';

export type SkyCustomerYesNoType = {
  YES: 'YES';
  NO: 'NO';
};

export const SkyCustomerYesNoOption: SkyCustomerYesNoType = {
  YES: 'YES',
  NO: 'NO',
};
