const ADDRESS_SERVICE_URL = process.env.REACT_APP_ADDRESS_LOOKUP_ENDPOINT!;
const FORM_ENDPOINT = process.env.REACT_APP_FORM_ENDPOINT!;
const CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_KEY!;
const CAPTCHA_ENABLED = process.env.REACT_APP_CAPTCHA_ENABLED!;
const ADDRESS_PREQUAL_ENDPOINT = process.env.REACT_APP_ENDPOINT!;
const AUTH0_LOGOUT_URL = process.env.REACT_APP_AUTH0_LOGOUT_URL!;
const NAVIGATION_URL = process.env.REACT_APP_LINKS_ENDPOINT!;
const BACKEND_ENDPOINT = process.env.REACT_APP_ENDPOINT!;
const KONAKART_ENDPOINT = process.env.REACT_APP_KONAKART_ENDPOINT!;
const SPLIT_AUTHORIZATION_KEY = process.env.REACT_APP_SPLIT_AUTHORIZATION_KEY!;
const SPLIT_KEY = 'skyweb';
const STATICBBJSON_ENDPOINT = process.env.REACT_APP_STATICBBJSON_ENDPOINT!;
const LIFERAY_CONNECT_ENDPOINT = process.env.REACT_APP_LIFERAY_CONNECT_ENDPOINT!;
const DOMAIN = process.env.REACT_APP_DOMAIN!;
const WIFI_GUY_URL = process.env.REACT_APP_WIFI_GUY_URL!;
const WIFI_GUY_CTA_ENABLE = process.env.REACT_APP_WIFI_GUY_CTA_ENABLE!;
const JSON_CONFIG_URL = process.env.REACT_APP_JSON_CONFIG_URL!;
const FAQ_ENDPOINT = process.env.REACT_APP_FAQ_ENDPOINT!;
const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL!;

const ACCOUNT_URL = process.env.REACT_APP_ACCOUNT_URL!;
// For local development testing.
// const ACCOUNT_URL = 'http://localhost:3000/';

const BB_REGISTER_URL = process.env.REACT_APP_BB_REGISTER_URL!;
const USER_GROUP_SOURCE = process.env.REACT_APP_USER_GROUP_SOURCE!;

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN_MYACCOUNT!;
const APP_ENVIRONMENT = process.env.REACT_APP_DEPLOY_ENV!;
// Sentry deny urls as arrays of RegExps
const SENTRY_DENY_URLS = (process.env.REACT_APP_SENTRY_DENY_URLS || '')
  .split(',')
  .filter(s => !!s)
  .map(s => new RegExp(s, 'i'));

/** Terms and conditions url */
const TERMS_AND_CONDITIONS_DOMESTIC = process.env.REACT_APP_TERMS_AND_CONDITIONS_DOMESTIC!;
const PRIVACY_POLICY = process.env.REACT_APP_PRIVACY_POLICY!;
const SKY_BROADBAND_T_AND_C_URL = process.env.REACT_APP_SKY_BROADBAND_T_AND_C_URL!;
const SKY_BROADBAND_KEY_THINGS_URL = process.env.REACT_APP_SKY_BROADBAND_KEY_THINGS_URL!;
const SKY_TV_KEY_THINGS_URL = process.env.REACT_APP_SKY_TV_KEY_THINGS_URL!;
const SKY_TV_T_AND_C_URL = process.env.REACT_APP_SKY_TV_T_AND_C_URL!;
const SKY_DISNEY_PLUS_T_AND_C_URL = process.env.REACT_APP_SKY_DISNEY_PLUS_T_AND_C_URL!;

const IS_CAPTCHA_ENABLED = CAPTCHA_ENABLED === '1';

const BUNDLE_OFFER_BANNER = 'Get Sky Bundle offer Banner';

export {
  ADDRESS_SERVICE_URL,
  FORM_ENDPOINT,
  CAPTCHA_KEY,
  CAPTCHA_ENABLED,
  IS_CAPTCHA_ENABLED,
  ADDRESS_PREQUAL_ENDPOINT,
  AUTH0_LOGOUT_URL,
  NAVIGATION_URL,
  BACKEND_ENDPOINT,
  KONAKART_ENDPOINT,
  SPLIT_AUTHORIZATION_KEY,
  SPLIT_KEY,
  STATICBBJSON_ENDPOINT,
  LIFERAY_CONNECT_ENDPOINT,
  DOMAIN,
  WIFI_GUY_URL,
  WIFI_GUY_CTA_ENABLE,
  JSON_CONFIG_URL,
  FAQ_ENDPOINT,
  ACCOUNT_URL,
  BB_REGISTER_URL,
  PRIVACY_POLICY,
  TERMS_AND_CONDITIONS_DOMESTIC,
  SKY_BROADBAND_KEY_THINGS_URL,
  SKY_TV_KEY_THINGS_URL,
  SKY_BROADBAND_T_AND_C_URL,
  SENTRY_DSN,
  APP_ENVIRONMENT,
  SENTRY_DENY_URLS,
  SKY_DISNEY_PLUS_T_AND_C_URL,
  SKY_TV_T_AND_C_URL,
  USER_GROUP_SOURCE,
  GRAPHQL_URL,
  BUNDLE_OFFER_BANNER,
};
