import React from 'react';
import {
  categoryIds,
  formatMoney,
  includedProductServiceCodes,
  T_OrderProduct,
  formatMoneyTwoDecimalPlaces,
} from '@sky-tv-group/shared';

interface MainProductProps {
  product: T_OrderProduct;
  isFreeProduct?: boolean;
  hidePrice: boolean;
  onRemove?: () => void;
}

const MainProduct = ({ product, isFreeProduct, onRemove, hidePrice }: MainProductProps) => {
  const isIncluded = includedProductServiceCodes.includes(product.product.sku);

  // For broadband products use price instead of priceIncTax
  // price is the discounted one
  const isBroadband = product.categoryId === categoryIds.broadband;
  const price = product.product.priceIncTax * product.quantity;

  let hasOffer = product.price !== product.product.priceIncTax;
  return (
    <div className="mb-6">
      <div className="flex justify-between">
        <div className="flex-1">
          <p className="sky-h6-bold pb-1">{`${product.product.name}${isIncluded ? ' (Use included)' : ''}
          ${product.quantity > 1 ? ' (x' + product.quantity + ')' : ''}`}</p>
          <p className="sky-h7-reg text-gray-darker pb-2">{product.product.description}</p>
        </div>
        {onRemove && (
          <div className="w-12 text-right">
            <p className="cursor-pointer" onClick={onRemove}>
              <img
                className="w-5 h-5 float-right"
                src="https://static.sky.co.nz/sky/broadband/icons/trashcan.svg"
                alt="Trash"></img>
            </p>
          </div>
        )}
      </div>
      {hidePrice === false && (
        <div className="flex">
          <p className="sky-h6-bold">
            {hasOffer && (
              <small className="align-top sky-text-red line-through " style={{ fontSize: '10px' }}>
                {`$${formatMoneyTwoDecimalPlaces(product.product.priceIncTax)} `}
              </small>
            )}
            {formatMoney(product?.price ?? product.product.priceIncTax)}
          </p>
          <p className="sky-h7-reg text-gray-darker">/mth</p>
        </div>
      )}
    </div>
  );
};

export { MainProduct };
