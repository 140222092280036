import React from 'react';
import cx from 'classnames';
import { telPhoneNumber } from '@sky-tv-group/shared';
import { callIcon } from '@sky-tv-group/components';

interface CallButtonProps {
  title?: string;
  subTitle?: string;
  className?: string;
}

const CallSkyButton = ({
  title = 'Give our Sky Crew a call.',
  subTitle = 'They’re a committed team who’ll answer your call pronto!',
  className = '',
}: CallButtonProps) => {
  const wrapperClassName = cx('text-center flex flex-col justify-center', className);
  return (
    <div className={wrapperClassName}>
      <div className="flex flex-col">
        <span className="sky-h6-bold">{title}</span>
        <span className="pb-4">{subTitle}</span>
      </div>
      <a href={`tel:${telPhoneNumber}`} className="flex sky-h2-black text-bborange justify-center">
        <span className="pr-2">{callIcon}</span>
        {telPhoneNumber}
      </a>
    </div>
  );
};

interface ErrorCallSkyProps {
  message?: string;
  className?: string;
}

const ErrorCallSky = ({ message = 'Oops... Looks like something went wrong.', className = '' }: ErrorCallSkyProps) => {
  const wrapperClassName = cx(className, 'text-center flex flex-col justify-center');
  return (
    <div className={wrapperClassName}>
      <h2 className={'sky-h2-bold text-blue-light mb-8'}>{message}</h2>
      <CallSkyButton />
    </div>
  );
};

export { ErrorCallSky, CallSkyButton };
