import {
  T_Product,
  useCartContainer,
  useBroadband,
  useProductStore,
  productSkuIds,
  useSplitIO,
  SPLITIO_KEY,
} from '@sky-tv-group/shared';
import React from 'react';

import { CouponTypeValue } from '@sky-tv-group/shared';
import { kkService, prequalService } from '../../services';
import { PackageCanBuy } from '../acquisition-products/packages.can.buy';

export const BroadbandAdditionalServices = () => {
  const [newJourney] = useSplitIO(SPLITIO_KEY.SKYWEB_BROADBAND_AND_TV_ACQUISTION);
  const { products } = useProductStore();
  const { toggleProduct } = useCartContainer(kkService, CouponTypeValue.Upgrade, false);
  const { broadbandJourney } = useBroadband(prequalService);

  const perfectInstall = products.find(p => p.sku === productSkuIds.broadbandPerfectInstall.primary);

  const toggleAddon = async (product: T_Product) => {
    await toggleProduct(product, true, true, broadbandJourney);
  };

  if (!newJourney) {
    return null;
  }

  return (
    <div id="addons" className="p-6 md:p-0 mb-8">
      <h4 className="bg-gray-light sky-h3 md:sky-h4 text-center h-24 flex justify-center items-center font-bold">
        Additional Services
      </h4>
      {perfectInstall && (
        <PackageCanBuy
          key={perfectInstall?.id}
          data={perfectInstall}
          toggle={toggleAddon}
          showZeroAsFree={true}
          toggleLabel={'Super Set Up Technician'}
          appendSlash={false}
          period={'one off cost'}
        />
      )}
    </div>
  );
};
