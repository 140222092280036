import React, { useState } from 'react';
import { caret } from '@sky-tv-group/components';

const Important = () => {
  const [showWholeMessage, setShowWholeMessage] = useState(false);
  const toggleShowWholeMessage = () => {
    setShowWholeMessage(!showWholeMessage);
  };

  const messagePreview = 'Please be aware that fibre services don’t work without power.  ';
  const restOfTheMessage =
    'During a power cut, your home phone and devices like cordless phones, medical alarms, etc may not work even with a battery and you will not be able to make calls including to 111 emergency services. You will need another way to make calls and contact 111 emergency services such as a charged mobile device or power back-up device.';

  return (
    <div className="md:container mx-auto md:py-4">
      <div className={`sky-text-midnight pt-4 px-14 md:px-8`} onClick={toggleShowWholeMessage}>
        <div className="cursor-pointer flex border border-solid border-gray-dark w-full p-4">
          <div className="w-11/12 p-3 flex items-center">
            <div>
              <span className="sky-text-red text-bold">IMPORTANT: </span>
              <span>{!showWholeMessage ? messagePreview : messagePreview + restOfTheMessage}</span>
            </div>
          </div>
          <div className="w-1/12 py-3 md:p-3">
            <span
              className={`w-8 h-8 flex justify-center items-center  ${
                showWholeMessage ? 'transform -rotate-180 -translate-y-1/2' : ''
              }`}>
              {caret}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Important };
