/* eslint-disable jsx-a11y/anchor-is-valid */
import cx from 'classnames';
import React from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@reach/tabs';
import { productSkuIds, T_Product, T_ProductSelector, bbNameInterface, useBBname } from '@sky-tv-group/shared';

// components
import { TabContent } from './tab-content/TabContent';
import { TabHeader } from './tab-header/TabHeader';

const wireUpProductInfo = (products: T_Product[], selectedIsSkyCustomer: boolean, bbName: bbNameInterface) => {
  const plan1g = products.find(p => p.sku === productSkuIds.broadbandLightningFastWiFi.primary);
  const plan100m = products.find(p => p.sku === productSkuIds.broadbandWifi100.primary);
  const meshDevice = products.find(p => p.sku === productSkuIds.meshDevice.primary);
  if (!plan1g || !plan100m || !meshDevice) return null;

  const data: T_ProductSelector[] = [
    {
      header: {
        title: bbName.broadbandLightningFastWiFi.label,
        subtitle: plan1g.custom8 ?? '',
      },
      price: {
        name: bbName.broadbandLightningFastWiFi.label,
        price: `$${plan1g.price1}`,
        term: 'On a 12 month contract',
        nonStarterPrice: `$${plan1g.priceIncTax}`,
      },
      features: [
        { main: 'Lightning fast ', sec: 'unlimited fibre up to 900Mbps', extra: '' },
        { main: 'Hassle-free ', sec: 'set up', extra: '' },
        { main: 'Local Kiwi service ', sec: 'you know and trust', extra: '' },
        { main: 'Includes ', sec: 'the use of a Sky WiFi6 Router', extra: '' },
        {
          main: 'Extend Coverage ',
          sec: 'by adding a Sky WiFi Booster',
          extra:
            `(Upgrade to ${bbName.broadbandLightningFastWiFiBoost.label} Plan for ` +
            (selectedIsSkyCustomer
              ? `${plan1g.price1 + meshDevice.priceIncTax}`
              : `${plan1g.priceIncTax + meshDevice.priceIncTax}`) +
            '/mth)',
        },
      ],
      bundle: false,
    },
    {
      header: {
        title: bbName.broadbandWifi100.label,
        subtitle: plan100m.custom8 ?? '',
      },
      price: {
        name: 'Our entry level fibre plan',
        price: `$${plan100m.price1}`,
        term: 'On a 12 month contract',
        nonStarterPrice: `$${plan100m.priceIncTax}`,
      },
      features: [
        { main: 'Unlimited ', sec: 'fibre up to 300Mbps', extra: '' },
        { main: 'Hassle-free ', sec: 'set up', extra: '' },
        { main: 'Local Kiwi service ', sec: 'you know and trust', extra: '' },
        { main: 'Includes ', sec: 'the use of a Sky WiFi6 Router', extra: '' },
        {
          main: 'Extend Coverage ',
          sec: 'by adding a Sky WiFi Booster',
          extra:
            `(Upgrade to ${bbName.broadbandWifi100Boost.label} Plan for ` +
            (selectedIsSkyCustomer
              ? `${plan100m.price1 + meshDevice.priceIncTax}`
              : `${plan100m.priceIncTax + meshDevice.priceIncTax}`) +
            '/mth)',
        },
      ],
      bundle: false,
    },
  ];
  return data;
};
interface PlanSelectorProps {
  hasMeshDevice: boolean;
  isLoadingProduct: boolean;
  broadbandProducts: T_Product[];
  broadbandSelected: T_Product | undefined;
  selectedIsSkyCustomer: boolean;
  onSelectChange: (product: T_Product | undefined) => void;
  isNotOnA12MonthContract: boolean;
}

const PlanSelector = ({
  hasMeshDevice,
  isLoadingProduct,
  broadbandSelected,
  broadbandProducts,
  selectedIsSkyCustomer,
  onSelectChange,
  isNotOnA12MonthContract,
}: PlanSelectorProps) => {
  const bbName = useBBname();
  const planArr = wireUpProductInfo(broadbandProducts, selectedIsSkyCustomer, bbName);

  const index =
    (broadbandSelected?.sku ?? productSkuIds.broadbandLightningFastWiFi.primary) ===
      productSkuIds.broadbandLightningFastWiFi.primary ||
    (broadbandSelected?.sku ?? productSkuIds.broadbandLightningFastWiFi.primary) ===
      productSkuIds.broadbandLightningFastWiFiBoost.primary
      ? 0
      : 1;

  const tabStyle = (cur: number, index: number) =>
    cx('w-1/2', {
      ' sky-bg-midnight sky-text-white  rounded-tr-md rounded-tl-md': index === cur,
      ' bg-gray-dark sky-text-midnight ': index !== cur,
    });
  const handleTabSelect = (idx: number) => {
    onSelectChange(
      idx === 0
        ? broadbandProducts.find(
            p =>
              p.sku ===
              (!hasMeshDevice
                ? productSkuIds.broadbandLightningFastWiFi.primary
                : productSkuIds.broadbandLightningFastWiFiBoost.primary)
          )
        : broadbandProducts.find(
            p =>
              p.sku ===
              (!hasMeshDevice ? productSkuIds.broadbandWifi100.primary : productSkuIds.broadbandWifi100Boost.primary)
          )
    );
  };

  if (!planArr) return null;

  return (
    <div className="shadow-lg md:container mx-auto">
      <Tabs onChange={handleTabSelect} index={index}>
        <TabList className="flex justify-center">
          <Tab
            className={`rounded-tl-md ${tabStyle(index, 0)}`}
            data-testid={planArr[0].header.title}
            disabled={isLoadingProduct}>
            <TabHeader
              isSelected={index === 0}
              title={planArr[0].header.title}
              subTitle={planArr[0].header.subtitle ?? undefined}
              bestPlanTitle={'OUR BEST PLAN'}
            />
          </Tab>
          <Tab
            className={`rounded-tr-md ${tabStyle(index, 1)}`}
            data-testid={planArr[1].header.title}
            disabled={isLoadingProduct}>
            <TabHeader
              isSelected={index === 1}
              title={planArr[1].header.title}
              subTitle={planArr[1].header.subtitle ?? undefined}
            />
          </Tab>
        </TabList>
        <TabPanels className="sky-bg-midnight md:bg-white sky-text-white md:text-navy">
          <TabPanel>
            <TabContent
              featuresContent={planArr[0].features}
              priceContent={planArr[0].price}
              isNotOnA12MonthContract={isNotOnA12MonthContract}
            />
          </TabPanel>
          <TabPanel>
            <TabContent
              featuresContent={planArr[1].features}
              priceContent={planArr[1].price}
              isNotOnA12MonthContract={isNotOnA12MonthContract}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export { PlanSelector };
