import React from 'react';

type ReviewProps = {
  review: string;
  name: string;
  title: string;
};

let Review = ({ review, name, title }: ReviewProps) => {
  return (
    <>
      <p className="relative">
        <span style={{ transform: 'scale(2)' }} className="md:sky-h3-reg  sky-h4  inline-block">
          &ldquo;
        </span>
        <span className="md:sky-h3-reg  sky-h4 " style={{ lineHeight: 1.5 }}>
          &nbsp;&nbsp;{review}&nbsp;&nbsp;
        </span>
        <span style={{ transform: 'scale(2)' }} className="md:sky-h3-reg  sky-h4  inline-block ">
          &rdquo;
        </span>
      </p>

      <p className="py-5 sky-h7  md:sky-h7 ">
        <strong>{name}</strong>
        &nbsp;{title}
      </p>
    </>
  );
};

export { Review };
