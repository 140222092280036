import React from 'react';
import cx from 'classnames';

interface Props {
  active?: boolean;
  disable?: boolean;
}

export function StepperLine({ active, disable }: Props) {
  const classes = cx('flex-auto mx-2 w-full h-2 rounded-round', {
    'sky-blue-gradient': active,
    'bg-gray-dark': !active,
    hidden: disable,
  });

  return <div className={classes} style={{ height: '6px' }}></div>;
}
