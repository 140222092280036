import { AddressPrequalification, BroadbandReady, getServiceAddress } from '@sky-tv-group/shared';
import React from 'react';
import { useStore } from '../store';
import { myAccountSWR } from '../swr';
import { useAuth0 } from '../auth0/store';

let AddressBar = () => {
  const { data: customer } = myAccountSWR.useCustomer();
  const { isAuthenticated } = useAuth0();
  const { authenticatedPrequalData, guestPrequalData, selectedAddress } = useStore(s => ({
    authenticatedPrequalData: s.authenticatedPrequalData,
    guestPrequalData: s.guestPrequalData,
    selectedAddress: s.selectedInstallationAddress,
  }));

  const prequal = guestPrequalData ? guestPrequalData : isAuthenticated ? authenticatedPrequalData : undefined;
  const address = selectedAddress !== '' ? selectedAddress : customer ? getServiceAddress(customer) : '';

  // TODO Brandify.
  // Test Addresses:
  // OK = 28 Burswood Drive, Burswood, Manukau, Auckland
  // FUTURE = 54 Pitcairn Crescent, Bryndwr, Christchurch
  // UNAVAILABLE = 41 Ototoika Road, Te Kuiti, Waitomo District
  let getStatus = (prequal: AddressPrequalification) => {
    let br = prequal.getBroadbandReadyStatus();
    return br.broadbandReadyStatus === BroadbandReady.FibreOK
      ? 'sky-bg-lime sky-text-white'
      : br.broadbandReadyStatus === BroadbandReady.FibreFuture
      ? 'bg-bborange sky-text-midnight'
      : 'sky-bg-red sky-text-white';
  };

  let getClassName = (prequal: AddressPrequalification) => {
    let classStatus = getStatus(prequal);
    return 'text-center p-3 h-16 flex flex-col justify-center ' + classStatus;
  };

  return (
    <div>
      {prequal && (
        <div className={getClassName(prequal)}>
          {selectedAddress === '' && <div className="font-medium">We have your current address as:</div>}
          <div>{address}</div>
        </div>
      )}
    </div>
  );
};

export { AddressBar };
