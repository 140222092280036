import React from 'react';
import { categoryIds, formatMoney, orderStoreApi } from '@sky-tv-group/shared';
import history from '../../history';
import { useStore } from '../../store';
import { AppRoutes } from '../../constants';

const TotalHeader = () => {
  const isBroadbandPage = history.location.pathname === AppRoutes.Broadband;
  const isTVPage = history.location.pathname === AppRoutes.TV;
  const { isBundle } = useStore();
  const order = orderStoreApi.getState().order;
  const total_class = isBundle ? 'ot_subtotal' : 'ot_total_data';
  const total = order?.orderTotals.find(t => total_class === t.className)?.value ?? 0;
  const broadbandProduct = order?.orderProducts.find(p => p.categoryId === categoryIds.broadband);

  let showStickyHeader = isTVPage || isBroadbandPage;

  if (!broadbandProduct || !showStickyHeader) {
    return null;
  }

  const handleBack = () => {
    isTVPage ? history.push(AppRoutes.Broadband) : history.push(AppRoutes.Home);
  };

  return (
    <div className="sticky z-40 w-full left-0 sky-bg-midnight overflow-x-auto">
      <div className="md:container md:m-auto">
        <div className="flex flex-row py-8 px-10 md:px-4">
          <div className="flex flex-col pr-4 sky-text-white cursor-pointer" onClick={handleBack}>
            <img src="/images/back_Stroke.png" className="mt-1" alt="Go back" />
          </div>
          <div className="flex flex-col flex-1 sky-text-white">
            <div className="sky-h5-bold md:sky-h5-bold">
              {broadbandProduct.product.name}
              {isBundle && ' with Sky TV'}
            </div>
            <div className="sky-h7-reg pt-2">{broadbandProduct.product.custom8}</div>
          </div>
          <div className="flex flex-row flex-1 items-center justify-end">
            <div className="flex flex-row items-baseline">
              <div className="text-bborange sky-h3-black md:sky-h4-black" data-testid="plan-amount">
                {formatMoney(total)}
              </div>
              <div className="sky-text-white">/mth&nbsp;</div>
              <div className="sky-text-white">(Before offer applied)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { TotalHeader };
