import React, { useEffect, useState } from 'react';
import {
  BroadbandReady,
  getServiceAddress,
  useCustomerStore,
  useSplitIO,
  SPLITIO_KEY,
  combineAddressArray,
  formatAddress,
  getServiceAddressAsArray,
  useGetWifiGuyRecommendations,
} from '@sky-tv-group/shared';
import { OneMonthOnUs } from '@sky-tv-group/components';
import history from '../history';
import get from 'lodash/get';

// components
import { Important } from '../components/Important';
import { ContinueButtonArea } from '../components/continue';
import { Faq } from '../components/faq';
import { useStore } from '../store';
import { useAuth0 } from '../auth0/store';
import { myAccountSWR } from '../swr';
import { addressService, prequalService } from '../services';
import { BlockLoader } from '@sky-tv-group/components';
import { ErrorCallSky } from '../components/error-call-sky';
import { useChoosePlan } from '../hooks/useChoosePlan';
import { AddressBar } from '../components/AddressBar';
import { ChooseProducts } from '../components/choose-plan/choose-products';
import { AppRoutes } from '../constants';
import { useIdleTimer } from 'react-idle-timer';

const Acquisition = () => {
  const [allowCheckout] = useSplitIO(SPLITIO_KEY.SKYWEB_BROADBAND_AND_TV_ACQUISTION);
  const { isAuthenticated } = useAuth0();
  const { customerId, setActiveGASession } = useCustomerStore(s => ({
    customerId: s.kk,
    setActiveGASession: s.setActiveGASession,
  }));
  const {
    authenticatedPrequalData,
    guestPrequalData,
    selectedIsSkyStarterCustomer,
    setSelectedTuiAddress,
    setGuestPrequal,
    setSelectedInstallationAddress,
    setUseGuestPrequal,
    authTuiAddress,
    setBroadbandProductSelected,
    setSelectedIsSkyStarterCustomer,
    setSelectedAddressId,
  } = useStore();
  const [showOneMonthOnUs] = useSplitIO(SPLITIO_KEY.SKYWEB_BB_ONE_MONTH_ON_US_ON);

  const { clearProducts, getSkyBroadbandAction, broadband } = useChoosePlan();
  const { wifiGuyRecommendedSku, wifiGuySelectedAddressId, wifiGuySelectedTui } = useGetWifiGuyRecommendations();

  const { hasActiveOrPendingBroadbandPackage, isCustomerNeedsAccountUpdate } = myAccountSWR.usePackages();
  const { data: customer } = myAccountSWR.useCustomer();

  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const showContinue = !hasActiveOrPendingBroadbandPackage && (selectedIsSkyStarterCustomer || allowCheckout);

  // Handle Iddle GA Sessions
  const handleOnIdle = () => {
    setActiveGASession(!isIdle);
  };

  const { isIdle } = useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
  });

  useEffect(() => {
    if (wifiGuyRecommendedSku) {
      setSelectedIsSkyStarterCustomer(true);
      const wifiGuyBroadbandProduct = broadband.find(p => p.sku === wifiGuyRecommendedSku);
      if (wifiGuyBroadbandProduct) {
        setBroadbandProductSelected(wifiGuyBroadbandProduct);
        getSkyBroadbandAction(wifiGuyBroadbandProduct);
      }
    }
    //we need to wait for broadband products to be loaded before adding the product
    // eslint-disable-next-line
  }, [wifiGuyRecommendedSku, broadband.length > 0]);

  useEffect(() => {
    async function prequalify(addressId: string) {
      try {
        const prequal = await prequalService.getPrequalDetails(addressId);
        const isFibreAvailable = prequal
          ? prequal.getBroadbandReadyStatus().broadbandReadyStatus === BroadbandReady.FibreOK
          : false;

        setSelectedTuiAddress(addressId);

        if (prequal) {
          setGuestPrequal(prequal);
        }

        // Redirect to Home page of Fibre is not available
        if (!isFibreAvailable) {
          return history.push(AppRoutes.Home);
        }
      } catch (err) {
        console.log('prequalify error', err);
      }
    }

    async function getAddressDetails() {
      if (wifiGuyRecommendedSku) {
        //Set address details from the selected Address ID
        if (wifiGuySelectedAddressId) {
          const addressDetails = await addressService.getAddressDetail(wifiGuySelectedAddressId);
          const tui = get(addressDetails, 'references.tui', undefined);

          if (!tui) {
            setHasError(true);
            return;
          }

          const formattedAddress = combineAddressArray([
            addressDetails.formatted_delivery_address?.line1,
            addressDetails.formatted_delivery_address?.line2,
            addressDetails.formatted_delivery_address?.line4,
            addressDetails.formatted_delivery_address?.line4,
          ]);
          const addressLines = formatAddress(
            addressDetails.formatted_delivery_address.line1,
            addressDetails.formatted_delivery_address.line2,
            addressDetails.formatted_delivery_address.line3,
            addressDetails.formatted_delivery_address.line4
          );

          setSelectedInstallationAddress(formattedAddress.toUpperCase(), addressLines);
          setSelectedAddressId(addressDetails.references.id);
          setUseGuestPrequal(true);
          prequalify(tui);
        } else if (wifiGuySelectedTui) {
          // Assume Tui only returns for logged in user - Error if not logged in or tui do not match
          if (!customer || (authTuiAddress && wifiGuySelectedTui !== authTuiAddress)) {
            return history.push(AppRoutes.Home);
          }

          const address = getServiceAddress(customer);
          const addressArray = getServiceAddressAsArray(customer);
          const addressLines = formatAddress(addressArray[0], addressArray[1], addressArray[2], addressArray[3]);

          setSelectedInstallationAddress(address.toUpperCase(), addressLines);
          prequalify(wifiGuySelectedTui);
        }
      } else {
        const prequal = isAuthenticated ? authenticatedPrequalData : guestPrequalData;
        const isFibreAvailable = prequal
          ? prequal.getBroadbandReadyStatus().broadbandReadyStatus === BroadbandReady.FibreOK
          : false;

        if (!customerId || !isFibreAvailable) {
          return history.push(AppRoutes.Home);
        }
      }
    }

    if (isCustomerNeedsAccountUpdate) {
      clearProducts();
      return history.push(AppRoutes.Home);
    }

    setLoading(true);
    getAddressDetails();
    setLoading(false);

    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {!hasError && (
        <>
          {loading && (
            <div className="relative">
              <BlockLoader />
            </div>
          )}
          {!loading && (
            <div className="relative">
              <AddressBar />

              {showOneMonthOnUs && (
                <div className="md:container m-auto md:pb-8">
                  <OneMonthOnUs
                    copy="Get your first month on us when you sign up to a selected Sky Broadband plan
. "
                    tAndCLink="https://www.sky.co.nz/offer-terms"
                  />
                </div>
              )}

              <Important />
              <ChooseProducts />
              {showContinue && <ContinueButtonArea />}
              <Faq filter={'Acquisition'} title={'Important Information'} />
            </div>
          )}
        </>
      )}
      {hasError && (
        <div className="h-screen flex items-center justify-center m-auto">
          <ErrorCallSky />
        </div>
      )}
    </div>
  );
};

export { Acquisition };
