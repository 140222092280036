import { useStore } from '../store';
import {
  BroadbandReady,
  useJSONConfig,
  telPhoneNumber,
  UserGroupType,
  unformattedTelPhoneNumber,
} from '@sky-tv-group/shared';
import history from '../history';
import { useAuth0 } from '../auth0/store';
import { BB_REGISTER_URL } from '../config';
import { configService } from '../services';
import { ACCOUNT_URL } from '../config';
import { useChoosePlan } from './useChoosePlan';
import { myAccountSWR } from '../swr';
import { AppRoutes } from '../constants';

function useCheckYourAddress() {
  const { isAuthenticated, userGroupTypes } = useAuth0();
  const {
    authenticatedPrequalData,
    guestPrequalData,
    useGuestPrequal,
    selectedTuiAddress,
    authTuiAddress,
    hasActiveOrPendingBroadbandPackage,
    broadbandProductSelected,
    redirectFromCheckingPage,
  } = useStore(s => ({
    authenticatedPrequalData: s.authenticatedPrequalData,
    guestPrequalData: s.guestPrequalData,
    useGuestPrequal: s.useGuestPrequal,
    selectedTuiAddress: s.selectedTuiAddress,
    authTuiAddress: s.authTuiAddress,
    hasActiveOrPendingBroadbandPackage: s.hasActiveOrPendingBroadbandPackage,
    broadbandProductSelected: s.broadbandProductSelected,
    redirectFromCheckingPage: s.redirectFromCheckingPage,
  }));
  const { getSkyBroadbandAction, selectProduct, broadbandProductInCart } = useChoosePlan();
  const prequalData = useGuestPrequal ? guestPrequalData : isAuthenticated ? authenticatedPrequalData : undefined;

  const { isCustomerNeedsAccountUpdate, summerSetAccount, isInArrears } = myAccountSWR.usePackages();

  const focusOnPackageSelector = () => {
    const packageSelectorInputField = document.getElementById('package-selector-div');
    if (packageSelectorInputField) {
      packageSelectorInputField.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      packageSelectorInputField.focus({ preventScroll: true });
    }
  };

  let isFibreOk = () => {
    let br = prequalData?.getBroadbandReadyStatus();
    return br?.broadbandReadyStatus === BroadbandReady.FibreOK;
  };

  let isFibreFuture = () => {
    let br = prequalData?.getBroadbandReadyStatus();
    return br?.broadbandReadyStatus === BroadbandReady.FibreFuture;
  };

  let isFibreNeverOrUnknown = () => {
    let br = prequalData?.getBroadbandReadyStatus();
    return (
      br?.broadbandReadyStatus === BroadbandReady.FibreNever || br?.broadbandReadyStatus === BroadbandReady.Unknown
    );
  };

  let fibreETA = () => {
    let br = prequalData?.getBroadbandReadyStatus();
    return br?.broadbandReadyDate;
  };

  let authTuiDiffersFromSelected = () => {
    if (!authTuiAddress || !selectedTuiAddress || !isAuthenticated) {
      return false;
    }
    return selectedTuiAddress.toString() !== authTuiAddress?.toString();
  };

  let getTitle = () => {
    if (authTuiDiffersFromSelected() && redirectFromCheckingPage) {
      return `Whoops! We notice the address entered is different to what we have for you. To change your address contact our friendly Sky Crew on ${unformattedTelPhoneNumber}.`;
    }

    if (isCustomerNeedsAccountUpdate && !summerSetAccount) {
      if (isInArrears) {
        return 'Your Sky account is currently overdue so you cannot proceed with this purchase. Please contact us on 0800 759 779 to review options for settling your account.';
      } else
        return "Looks like your account needs updating. Give our friendly Sky crew a call and they'll sort you out pronto.";
    }

    if (summerSetAccount) {
      return `If you would like to make changes to your current Sky subscription, such as upgrading your packages, please call our friendly support team on ${telPhoneNumber} .`;
    }

    if (hasActiveOrPendingBroadbandPackage && !authTuiDiffersFromSelected()) {
      return 'Lucky you! You already have Sky Broadband at this address';
    }
    return isFibreOk()
      ? "1. Perfect! Fibre's available at your home"
      : isFibreFuture()
      ? 'Fibre not available yet'
      : isFibreNeverOrUnknown()
      ? '1. No Fibre - Sorry!'
      : isAuthenticated
      ? '1. Check another address'
      : '1. Check your address here';
  };

  const config = useJSONConfig(configService);
  const wifiGuyImg: { available: string; comingSoon: string; neutral: string; unavailable: string } =
    config?.broadband?.acquisition?.wifiGuy?.checkAddress ?? undefined;

  let availableImg = wifiGuyImg?.available;
  let comingSoonImg = wifiGuyImg?.comingSoon;
  let neutralImg = wifiGuyImg?.neutral;
  let unavailableimg = wifiGuyImg?.unavailable;

  let getWifiGuy = () => {
    return isCustomerNeedsAccountUpdate
      ? unavailableimg
      : isFibreOk()
      ? availableImg
      : isFibreFuture()
      ? comingSoonImg
      : isFibreNeverOrUnknown()
      ? unavailableimg
      : neutralImg;
  };

  let getButtonLabel = () => {
    if (userGroupTypes.indexOf(UserGroupType.SKY_VTV_CUSTOMER) !== -1) {
      return 'Contact Sky';
    }

    if (isCustomerNeedsAccountUpdate || authTuiDiffersFromSelected()) {
      return telPhoneNumber;
    }

    if (hasActiveOrPendingBroadbandPackage && !authTuiDiffersFromSelected()) {
      return 'Go to My Account';
    }

    if (isFibreOk()) {
      if (authTuiDiffersFromSelected()) {
        return telPhoneNumber;
      }
      return 'Get Sky Broadband Now';
    } else {
      return "Let me know when it's available";
    }
  };

  let executeHomeButtonAction = async () => {
    executeButtonAction(true);
  };

  let executeOtherButtonAction = async () => {
    executeButtonAction(false);
  };

  let executeButtonAction = async (isHomePage: boolean | false) => {
    if (isCustomerNeedsAccountUpdate || userGroupTypes.indexOf(UserGroupType.SKY_VTV_CUSTOMER) !== -1) {
      window.open('tel:' + telPhoneNumber, '_self');
      return;
    }

    if (hasActiveOrPendingBroadbandPackage && !authTuiDiffersFromSelected()) {
      window.location.assign(ACCOUNT_URL);
    } else if (isFibreOk()) {
      if (authTuiDiffersFromSelected()) {
        window.open('tel:' + telPhoneNumber, '_self');
        return;
      } else {
        if (isHomePage) {
          focusOnPackageSelector();
        } else {
          if (broadbandProductInCart) {
            selectProduct(broadbandProductSelected);
          } else {
            getSkyBroadbandAction(broadbandProductSelected);
          }
          history.push(AppRoutes.Broadband);
          getSkyBroadbandAction();
        }
      }
    } else {
      window.open(BB_REGISTER_URL);
    }
  };

  let isInHomePage = () => {
    return history.location.pathname === AppRoutes.Home;
  };

  return {
    isFibreOk,
    isFibreFuture,
    isFibreNeverOrUnknown,
    fibreETA,
    getTitle,
    getButtonLabel,
    executeHomeButtonAction,
    executeOtherButtonAction,
    isInHomePage,
    authTuiDiffersFromSelected,
    getWifiGuy,
    hasActiveOrPendingBroadbandPackage,
  };
}
export { useCheckYourAddress };
