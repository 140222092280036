import React, { useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import {
  FormElement,
  Loader,
  Progression,
  ConditionsCheckbox,
  AccountDetailsCheckbox,
  AccountDetailsReview,
  InstallationDetailsReview,
} from '@sky-tv-group/components';
import { back, edit } from './transition';
import {
  TERMS_AND_CONDITIONS_DOMESTIC,
  SKY_BROADBAND_KEY_THINGS_URL,
  SKY_BROADBAND_T_AND_C_URL,
  PRIVACY_POLICY,
  IS_CAPTCHA_ENABLED,
  CAPTCHA_KEY,
} from '../../config';
import { useReview } from '../../hooks/useReview';

import {
  SPLITIO_KEY,
  useSplitIO,
  useReceiveMarketingMessagesCheckbox,
  useAccountDetailsStore,
} from '@sky-tv-group/shared';
import { useCheckoutManager } from '../../hooks/useCheckoutManager';

const Review = () => {
  const [enableMarketingMessage] = useSplitIO(SPLITIO_KEY.SKYWEB_ALLOW_MARKETING_MESSAGE);
  const { hasPackage, hasBroadband } = useCheckoutManager();
  const {
    form,
    prequal,
    submitting,
    captchaRef,
    accountDetails,
    broadbandDetails,
    broadbandDeliveryDetails,
    keyThingsToKnowField,
    termsAndConditionsField,
  } = useReview();

  // Checkbox fields - might need to
  const marketingMessagesCheckbox = useReceiveMarketingMessagesCheckbox();
  const isNewFiber = prequal?.isNewFibreJourney();

  const showBroadbandInstallationDetails =
    hasBroadband &&
    (broadbandDeliveryDetails?.installationAddress ||
      (isNewFiber ? broadbandDetails?.installationDate : broadbandDetails?.activationDate) ||
      (isNewFiber ? broadbandDetails?.installationTime : undefined));

  const { setAccountDetails } = useAccountDetailsStore();

  const onTermsAndConditionsChanged = (value: boolean) => termsAndConditionsField.setValue(value);
  const onKeyThingsToKnowFieldChanged = (value: boolean) => keyThingsToKnowField.setValue(value);

  // Set installation address from check address results
  useEffect(() => {
    handleMarketingMaterials(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEdit = (state: string) => {
    edit(state);
  };

  const handleMarketingMaterials = (value: boolean) => {
    marketingMessagesCheckbox.setValue(value);
    accountDetails.marketingEmails = value;
    setAccountDetails(accountDetails);
  };

  return (
    <div className="container m-auto md:pb-10 text-navy">
      <FormElement {...form.props}>
        <AccountDetailsReview accountDetails={accountDetails} edit={onEdit} />
        <InstallationDetailsReview
          hasPackage={hasPackage}
          hasBroadband={hasBroadband}
          edit={onEdit}
          accountDetails={accountDetails}
          broadbandDetails={broadbandDetails}
          broadbandDeliveryDetails={broadbandDeliveryDetails}
          isNewFiber={isNewFiber}
          showBroadbandInstallationDetails={!!showBroadbandInstallationDetails}
        />
        <div className="flex flex-col p-4 mb-4">
          <h4 className="flex-1 sky-h6-bold md:sky-h6-bold mb-4">Terms & Conditions</h4>
          <ConditionsCheckbox id="key-things" field={keyThingsToKnowField} onChange={onKeyThingsToKnowFieldChanged}>
            <label htmlFor="key-things">
              I have read the &nbsp;
              <a
                className="underline cursor-pointer sky-text-daylight"
                href={SKY_BROADBAND_KEY_THINGS_URL}
                target="_blank"
                rel="noreferrer noopener">
                Key Things to Know
              </a>
            </label>
          </ConditionsCheckbox>
          <ConditionsCheckbox
            id="broadband-terms"
            field={termsAndConditionsField}
            onChange={onTermsAndConditionsChanged}>
            <label htmlFor="broadband-terms">
              I agree to the Sky &nbsp;
              <a
                className="underline cursor-pointer sky-text-daylight"
                href={TERMS_AND_CONDITIONS_DOMESTIC}
                target="_blank"
                rel="noreferrer noopener">
                Consumer Terms
              </a>
            </label>
            ,&nbsp;
            <a
              className="underline cursor-pointer sky-text-daylight"
              href={SKY_BROADBAND_T_AND_C_URL}
              target="_blank"
              rel="noreferrer noopener">
              Broadband Terms
            </a>
            ,&nbsp; and &nbsp;
            <a
              className="underline cursor-pointer sky-text-daylight"
              href={PRIVACY_POLICY}
              target="_blank"
              rel="noreferrer noopener">
              Privacy Policy
            </a>
          </ConditionsCheckbox>

          {enableMarketingMessage && (
            <AccountDetailsCheckbox
              marketingMessagesCheckbox={marketingMessagesCheckbox}
              onChange={handleMarketingMaterials}
            />
          )}
        </div>
        {IS_CAPTCHA_ENABLED && (
          <ReCAPTCHA ref={captchaRef as any} size="invisible" badge="bottomright" sitekey={CAPTCHA_KEY} theme="light" />
        )}
        {submitting && (
          <div className="w-full h-full z-50 fixed bottom-0 top-0 right-0 left-0">
            <Loader bgOpacity={true} bgColor={'bg-black'} />
          </div>
        )}
        <Progression next={() => {}} back={back} nextText="Submit Order" disableSubmit={submitting} />
      </FormElement>
    </div>
  );
};

export { Review };
