import { addDays, isValid } from 'date-fns';
import { categoryIds, productSkuIds } from '../../../config';
import { InstallationDetail, OrderDetails, T_Coupon, T_OrderProduct } from '../../../types';
import { getInstallationDate, getInstallationTimeCode, getCouponCampaign, getAddedProducts } from './helpers';

// get Order Details
export const createOrderDetails = (
  orderProducts: Pick<T_OrderProduct, 'product' | 'quantity'>[],
  coupons: Pick<T_Coupon, 'couponCode' | 'description'>[] | undefined,
  installationDetails: Pick<InstallationDetail, 'installationDate' | 'installationTime'>,
  usingTechnicianAvailable: boolean
): OrderDetails => {
  const formattedInstallationDate = isValid(installationDetails.installationDate)
    ? getInstallationDate(installationDetails.installationDate)
    : //Always send 14 days from today date if the installation date is not selected
      getInstallationDate(addDays(new Date(), 14));

  const time = installationDetails.installationTime ? installationDetails.installationTime : '';
  let campaigns = getCouponCampaign(coupons);

  // Separate packages and boxes.
  const orderPackages = orderProducts.filter(
    p =>
      p.product.categoryId !== categoryIds.box &&
      p.product.categoryId !== categoryIds.multiroom &&
      p.product.sku !== productSkuIds.paperBilling.primary &&
      p.product.categoryId !== categoryIds.oneOffFee &&
      p.product.categoryId !== categoryIds.technicianFee
  );
  const orderBoxes = orderProducts.filter(p => p.product.categoryId === categoryIds.box);
  const oneOffFeesProducts = orderProducts.filter(p => p.product.categoryId === categoryIds.oneOffFee);
  const technicianFeesProducts = orderProducts.filter(p => p.product.categoryId === categoryIds.technicianFee);
  const orderMultirooms = orderProducts.filter(p => p.product.categoryId === categoryIds.multiroom);

  let addedItems = getAddedProducts(orderPackages);
  let addedBoxes = getAddedProducts(orderBoxes);
  let addedOneOffFees = getAddedProducts(oneOffFeesProducts);
  let addedTechnicianFees = getAddedProducts(technicianFeesProducts);

  const orderDetails: OrderDetails = {
    products: [
      {
        type: 'cable',
        campaigns: campaigns,
        services: [
          {
            installDate: formattedInstallationDate,
            installTime: time,
            actions: [
              {
                add: [...addedItems, ...addedBoxes, ...addedOneOffFees, ...addedTechnicianFees],
                remove: [],
              },
            ],
            details: [{}],
          },
        ],
      },
    ],
  };

  // Put multirooms in its own occurence.
  orderMultirooms.forEach(item => {
    let addedMultirooms = getAddedProducts([item]);
    // Handle multiple multirooms of the same kind.
    for (let i = 0; i < item.quantity; i++) {
      orderDetails.products[0].services[0].actions.push({
        add: [...addedItems, ...addedMultirooms],
        remove: [],
      });
    }
  });

  return orderDetails;
};
