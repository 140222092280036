import React from 'react';
import { WifiBooster } from '../../acquisition-products/WifiBooster';
import { Router } from '../../acquisition-products/Router';
import { Voice } from '../../acquisition-products/Voice';
import { VulnerableCustomerInfo } from '../vulnerable-customer-info';
import { StaticIp } from '../../acquisition-products/StaticIp';
import { BroadbandAdditionalServices } from '../../checkout';

const ChooseProducts = () => {
  return (
    <div id="products-header" className="w-full p-6 pt-0">
      <div className="md:container m-auto md:pb-0">
        <div className="sky-bg-white sky-text-midnight py-4 px-8">
          <Router />
          <WifiBooster />
          <Voice />
          <StaticIp />
          <BroadbandAdditionalServices />
          <VulnerableCustomerInfo />
        </div>
      </div>
    </div>
  );
};

export { ChooseProducts };
