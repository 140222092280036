import React from 'react';

import { next, back } from './transition';
import { FormElement, HomePhone, Progression } from '@sky-tv-group/components';
import { useVoiceDetails } from '@sky-tv-group/shared';
import { myAccountSWR } from '../../swr';
import { serviceProviderService, orderService, voiceService } from '../../services';
import { useStore } from '../../store';

const VoiceDetails = () => {
  const { selectedTuiAddress } = useStore();

  const {
    form,
    providers,
    homePhoneFields,
    customer,
    hasExistingNumber,
    hasSameProvider,
    keepSameNumber,
    otherPhoneProvider,
    requireAccountNumber,
    showHomePhoneSelector,
    notShowPhoneProviderMatchBroadbandDropDownField,
  } = useVoiceDetails(myAccountSWR, serviceProviderService, next);

  const goNext = () => {
    form.submit();
  };

  return (
    <div className="container m-auto md:pb-10 text-navy">
      <FormElement {...form.props}>
        <HomePhone
          form={form}
          providers={providers}
          fields={homePhoneFields}
          customer={customer!}
          hasExistingNumber={hasExistingNumber}
          hasSameProvider={hasSameProvider}
          keepSameNumber={keepSameNumber}
          otherPhoneProvider={otherPhoneProvider}
          requireAccountNumber={requireAccountNumber}
          showHomePhoneSelector={showHomePhoneSelector}
          notShowPhoneProviderMatchBroadbandDropDownField={notShowPhoneProviderMatchBroadbandDropDownField}
          orderService={orderService}
          voiceService={voiceService}
          selectedTui={selectedTuiAddress}
          labelEnabled={true}
        />
      </FormElement>
      <Progression next={goNext} back={back} />
    </div>
  );
};

export { VoiceDetails };
