import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Arrow } from './Arrow';
import { ISliderProps } from './CarouselContainer';

/**
 * Designed for 3 column items, than when goes to 2 column then 1 column carousel
 * @param param0
 */
const PackedCarouselContainer: React.FC<ISliderProps> = ({
  children,
  arrowColorClassName,
  dots = false,
  infinite = false,
  speed = 500,
  slidesToShow = 3,
  slidesToScroll = 3,
  arrows = true,
  prevArrow = <Arrow invert={true} colorClassName={arrowColorClassName} />,
  nextArrow = <Arrow invert={false} colorClassName={arrowColorClassName} />,
  customClassName = 'packed-carousel',
  responsiveBlock = [
    {
      breakpoint: 1023,
      settings: {
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        className: 'packed-carousel',
      },
    },
    {
      breakpoint: 767,
      settings: {
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: 'packed-carousel',
      },
    },
  ],
}) => {
  let slider = React.createRef<Slider>();

  const carouselSettings = {
    dots,
    infinite,
    speed,
    slidesToShow,
    slidesToScroll,
    arrows,
    prevArrow,
    nextArrow,
    className: customClassName,
    responsive: responsiveBlock,
  };

  return (
    <>
      <Slider {...carouselSettings} ref={slider}>
        {React.Children.map(children, child => (
          <div>{child}</div>
        ))}
      </Slider>
    </>
  );
};

export { PackedCarouselContainer };
