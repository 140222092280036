import React from 'react';
import { SelectedSpeed, SelectedCoverage, SelectedSetup } from './buttonImages';

type ButtonProps = {
  icon: string;
  title: string;
  isSelected: boolean;
  selectedReviewIndex: number;
  getReview: () => void;
};

let Button = ({ icon, title, isSelected, selectedReviewIndex, getReview }: ButtonProps) => {
  let color = (isSelected: boolean) => {
    return isSelected ? 'sky-text-sunny' : 'sky-text-midnight';
  };
  let buttonStyle = (isSelected: boolean) => {
    return 'sky-h5 md:sky-h7-bold text-center flex-item' + color(isSelected);
  };

  const selectedImage = () => {
    switch (selectedReviewIndex) {
      case 0:
        return { image: SelectedSpeed() };
      case 1:
        return { image: SelectedCoverage() };
      case 2:
        return { image: SelectedSetup() };
    }
  };

  const visualData = selectedImage();

  return (
    <div className="flex flex-col items-center z-5 py-4 cursor-pointer">
      <div
        className="flex-item bg-transparent rounded-full relative border-gray-300 border-solid border-2 block"
        style={{ backgroundColor: isSelected ? '#ff9e1b' : 'white' }}>
        {isSelected && (
          <div className={'object-contain text-center'} style={{ width: '89px', height: '89px' }}>
            {visualData?.image}
          </div>
        )}
        {!isSelected && (
          <img
            src={isSelected ? '' : icon}
            className={'object-contain text-center '}
            style={{ width: '89px', height: '89px' }}
            alt={title}
            onClick={getReview}></img>
        )}
      </div>
      <p className={buttonStyle(isSelected)}>{title}</p>
    </div>
  );
};

export { Button };
