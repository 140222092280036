import React from 'react';
import { Field } from 'react-jeff';
import { SPLITIO_KEY, useSplitIO } from '@sky-tv-group/shared';
import { ConditionsCheckbox } from '@sky-tv-group/components';

interface Props {
  creditCheckCheckbox: Field<boolean>;
}

const CreditCheckCheckbox = ({ creditCheckCheckbox }: Props) => {
  const [enableCreditCheck] = useSplitIO(SPLITIO_KEY.SKYWEB_ENABLE_CREDIT_CHECK);

  const onCreditCheckCheckbox = (value: boolean) => {
    creditCheckCheckbox.setValue(value);
  };

  return (
    <div className="flex flex-col p-6 md:px-0 mb-4">
      {enableCreditCheck && (
        <ConditionsCheckbox field={creditCheckCheckbox} onChange={onCreditCheckCheckbox}>
          I give permission to use the personal information for the purposes of conducting a credit change
        </ConditionsCheckbox>
      )}
    </div>
  );
};

export { CreditCheckCheckbox };
