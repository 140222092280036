import React, { useState } from 'react';
import { Field, useForm } from 'react-jeff';
import {
  useInstallationDetailsInstallationDateField,
  useInstallationDetailsInstallationTimeField,
} from '@sky-tv-group/shared';
import { InstallationDetails, FormElement, Progression } from '@sky-tv-group/components';

import { next, back } from './transition';
import { addressService, configService, technicianLookup } from '../../services';

const TVInstallation = () => {
  let [installationOptionsAvailable, setInstallationOptionsAvailable] = useState(true);

  // -- Installation Details
  const installationDetailsInstallationDateField = useInstallationDetailsInstallationDateField();
  const installationDetailsInstallationTimeField = useInstallationDetailsInstallationTimeField();

  // TODO: Interim const as not all of the form fields have validation components
  const fieldsThatHaveUISupport: Field<any, any>[] = [
    // TODO: Remove this once technician API is available and check for slots.
    installationDetailsInstallationDateField,
    installationDetailsInstallationTimeField,
  ];

  // TODO: Uncomment once technician API is available.
  // Check if Technician Booking dates are availables, otherwise dont display the Installation details fields.
  if (installationOptionsAvailable) {
    //   fieldsThatHaveUISupport.push(installationDetailsInstallationDateField);
    //   fieldsThatHaveUISupport.push(installationDetailsInstallationTimeField);
  }

  // TODO: Set setInstallationOptionsAvailable(available) once technician API is available.
  let setInstallationOptionsAvailability = (available: boolean): void => {
    setInstallationOptionsAvailable(true);
  };

  // Form - validation component
  const form = useForm({
    fields: fieldsThatHaveUISupport,
    onSubmit: () => {}, // this onSubmit is useless when we want to do stuff with state, only good for getting required validation on form submit
  });

  const goNext = async () => {
    await form.submit();
    if (form.valid) next();
  };

  return (
    <div className="container m-auto md:pb-10 text-navy">
      <FormElement {...form.props}>
        <InstallationDetails
          titleText="Sky TV Installation"
          formValidation={form}
          installationDateField={installationDetailsInstallationDateField}
          installationTimeField={installationDetailsInstallationTimeField}
          setInstallationOptionsAvailability={setInstallationOptionsAvailability}
          addressService={addressService}
          configService={configService}
          technicianLookupService={technicianLookup}
        />
        <Progression
          next={goNext}
          back={back}
          backDataTestId="back-from-tv-installation"
          nextDataTestId="next-from-tv-installtion"
        />
      </FormElement>
    </div>
  );
};

export { TVInstallation };
