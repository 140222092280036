import axios, { AxiosRequestConfig } from 'axios';

import {
  ADDRESS_SERVICE_URL,
  FORM_ENDPOINT,
  ADDRESS_PREQUAL_ENDPOINT,
  NAVIGATION_URL,
  BACKEND_ENDPOINT,
  KONAKART_ENDPOINT,
  STATICBBJSON_ENDPOINT,
  JSON_CONFIG_URL,
  FAQ_ENDPOINT,
  GRAPHQL_URL,
} from './config';
import { getClient } from './auth0/store';
import { setContext } from '@apollo/client/link/context';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { headerChannelInterceptor, kkServiceResponseInterceptor } from '@sky-tv-group/shared';
import { sentryService } from './services';

const requestInterceptor = async (config: AxiosRequestConfig) => {
  const accessToken = await getClient()?.getTokenSilently();
  config.headers['authorization'] = config.headers['authorization'] ?? `Bearer ${accessToken}`;
  return config;
};
const responseInterceptor = async (err: any) => {
  if (err.response) {
    sentryService.captureException(err, { contexts: { response: err.response } });
    if (err.response.status === 401 || err.response.status === 403) {
      window.localStorage.setItem('redirectTo', window.location.pathname + window.location.search);
      getClient()?.logout({ returnTo: window.location.origin });
    }
  }
  return Promise.reject(err);
};

export const addressAgent = axios.create({ baseURL: ADDRESS_SERVICE_URL });
export const formAgent = axios.create({ baseURL: FORM_ENDPOINT });
export const prequalAgent = axios.create({ baseURL: ADDRESS_PREQUAL_ENDPOINT });
export const agentNavigation = axios.create({
  baseURL: NAVIGATION_URL,
});
export const agentKK = axios.create({
  baseURL: KONAKART_ENDPOINT,
});
export const agentMyAccount = axios.create({
  baseURL: BACKEND_ENDPOINT,
});

const httpLink = createHttpLink({
  uri: GRAPHQL_URL,
});

const authLink = setContext(async (_, { headers }) => {
  try {
    const accessToken = await getClient()?.getTokenSilently();
    return {
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    };
  } catch (e) {
    // @ts-ignore
    if (e.error === 'login_required') {
      return {
        headers: {
          ...headers,
          authorization: '',
        },
      };
    }

    return null;
  }
});

export const gqlAgentMyAccount = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export const agentTechSlots = axios.create({
  baseURL: BACKEND_ENDPOINT,
});
export const agentStaticJson = axios.create({
  baseURL: STATICBBJSON_ENDPOINT,
});
export const agentJSONConfig = axios.create({
  baseURL: JSON_CONFIG_URL,
});
export const agentFaq = axios.create({
  baseURL: FAQ_ENDPOINT,
});
export const agentCart = axios.create({
  baseURL: BACKEND_ENDPOINT,
});
export const agentVoice = axios.create({
  baseURL: BACKEND_ENDPOINT,
});
export const agentTracker = axios.create({
  baseURL: BACKEND_ENDPOINT,
});

agentCart.interceptors.request.use(headerChannelInterceptor('WEB'));
agentCart.interceptors.response.use(resp => resp, responseInterceptor);
agentMyAccount.interceptors.request.use(requestInterceptor);
agentMyAccount.interceptors.response.use(resp => resp, responseInterceptor);
agentKK.interceptors.response.use(resp => resp, kkServiceResponseInterceptor);
