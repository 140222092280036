import React from 'react';
import { useCartContainer } from '@sky-tv-group/shared';
import { Disclosure, MultiroomProductItem } from '@sky-tv-group/components';
import { kkService } from '../services';

const MultiRoomContainer = () => {
  const { acquisitionMultirooms, multiroomsNumber, addMultiRoom } = useCartContainer(kkService, 'ACQUISITION');
  return (
    <div className="md:pb-0">
      <Disclosure header="Multiroom" hide={true} classNames={'px-1'}>
        <h4 className="hidden md:flex mb-2 relative text-gray-darker text-center p-4  justify-center items-center sky-h4 md:sky-h6">
          Get another box installed so that people in another room can watch different channels. <br /> (Maximum of 5)
        </h4>
        {/* <PackedCarouselContainer {...sliderProps}> */}
        {acquisitionMultirooms.map(product => (
          <MultiroomProductItem
            key={product.id}
            product={product}
            addMultiRoom={addMultiRoom}
            totalMultiRooms={multiroomsNumber}
          />
        ))}
        {/* </PackedCarouselContainer> */}
      </Disclosure>
    </div>
  );
};

export { MultiRoomContainer };
