import React, { useState } from 'react';
import { T_Product } from '@sky-tv-group/shared';
import { Button } from '../button';
import { LineProductItem } from '../box-line-items/LineProductItem';

export interface IBoxProductItemProps {
  product: T_Product;
  toggleProduct: (product: T_Product) => Promise<void>;
  removable?: boolean;
}

const BoxProductItem: React.FC<IBoxProductItemProps> = ({ product, toggleProduct, removable = true }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const handleToggle = async () => {
    setIsDisabled(true);
    await toggleProduct(product);
    setIsDisabled(false);
  };

  const isInCart = product.quantityInCart > 0;
  const labelInCart = removable ? 'Remove' : 'Selected';
  const preventRemove = !removable && isInCart;
  return (
    <LineProductItem product={product}>
      <Button
        colorMode={isInCart ? 'success' : 'light'}
        className="mr-0"
        variant={isInCart ? 'primary' : 'secondary'}
        data-testid={'add-product-' + product.sku}
        onClick={handleToggle}
        disabled={isDisabled || preventRemove}>
        {isInCart && (
          <div className="stepper-dot float-left">
            <div className="stepper-active" />
          </div>
        )}
        {isInCart ? labelInCart : 'Select'}
      </Button>
    </LineProductItem>
  );
};

export { BoxProductItem };
