import React from 'react';
import { CouponTypeValue, useCartContainer, useManagePackage } from '@sky-tv-group/shared';
import { myAccountSWR } from '../../swr';
import { PackageCanBuy } from './packages.can.buy';
import { kkService } from '../../services';

export let StaticIp = () => {
  const { staticIp } = useManagePackage(myAccountSWR);
  let { toggleProduct } = useCartContainer(kkService, CouponTypeValue.Upgrade, false);
  const specialToggleLabel = "I'd like a static IP";

  return (
    <div
      className="border border-solid border-gray-dark p-2 md:px-0 h-full mx-auto mb-4"
      data-testid={'staticIp-container'}>
      {staticIp && (
        <PackageCanBuy
          key={staticIp?.id}
          data={staticIp}
          toggle={toggleProduct}
          showZeroAsFree={true}
          toggleLabel={specialToggleLabel}
        />
      )}
    </div>
  );
};
