import React, { useEffect, useState } from 'react';

// Hook use to check if the CheckoutNavigation component is "sticky" activated (sticky mode)
//
// This uses IntersectionObserver on the "Checkout" header ref to determine if CheckoutNavigation
// is in sticky mode.
function useCheckoutSticky(ref: React.RefObject<HTMLDivElement>) {
  const [sticking, setSticking] = useState(false);

  const options = {
    root: null,
    rootMargin: '-60px', // 60px is the height of the nav
    threshold: 0,
  };

  useEffect(() => {
    const elem = ref.current;
    const observer = new IntersectionObserver(entries => {
      const [entry] = entries;
      setSticking(!entry.isIntersecting);
    }, options);

    if (elem) {
      observer.observe(elem);
    }

    return () => {
      if (elem) observer.unobserve(elem);
    };
  }, [ref, options]);

  return { sticking };
}

export { useCheckoutSticky };
