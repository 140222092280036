import { productSkuIds } from '../config';
import { useLocation } from 'react-router-dom';

function useGetWifiGuyRecommendations() {
  const getSkuFromProductId = (productID: string | null) => {
    switch (productID) {
      case null:
        return '';
      case 'lfw':
        return productSkuIds.broadbandLightningFastWiFi.primary;
      case 'lfwb':
        return productSkuIds.broadbandLightningFastWiFiBoost.primary;
    }
    return '';
  };

  const GetWifiGuyRecommendedSku = () => {
    const search = useLocation().search;
    const productId = new URLSearchParams(search).get('product');
    return getSkuFromProductId(productId);
  };

  /* This is only for logged-in user. */
  const GetWifiGuySelectedTui = () => {
    const search = useLocation().search;
    return new URLSearchParams(search).get('tui');
  };

  /* This is for non-logged in user. */
  const GetWifiGuySelectedAddressId = () => {
    const search = useLocation().search;
    return new URLSearchParams(search).get('addressId');
  };

  const GetWifiGuySelectedSrc = () => {
    const search = useLocation().search;
    return new URLSearchParams(search).get('src');
  };

  const wifiGuyRecommendedSku = GetWifiGuyRecommendedSku();
  const wifiGuySelectedTui = GetWifiGuySelectedTui();
  const wifiGuySelectedAddressId = GetWifiGuySelectedAddressId();
  const wifiGuySelectedSrc = GetWifiGuySelectedSrc();

  return {
    wifiGuyRecommendedSku,
    wifiGuySelectedAddressId,
    wifiGuySelectedTui,
    wifiGuySelectedSrc,
  };
}

export { useGetWifiGuyRecommendations };
