import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import get from 'lodash/get';

// components
import { BroadbandBanner } from '../components/banner';
import { CheckYourAddress } from '../components/check-your-address';
import { ChoosePlan } from '../components/choose-plan';
import { Promo } from '../components/promotions/promo';
import { Reviews } from '../components/promotions/testimonials';
import { Faq } from '../components/faq';
import { FirstMonthBannerConfig, FirstMonthOffer, SpeedShowCase, MarketingInfo } from '@sky-tv-group/components';

import { configService, addressService, prequalService } from '../services';

import { useStore } from '../store';
import { useAuth0 } from '../auth0/store';
import {
  SPLITIO_KEY,
  useJSONConfig,
  useSplitIO,
  useProductStore,
  telPhoneNumber,
  UserGroupType,
  useGetWifiGuyRecommendations,
  combineAddressArray,
  formatAddress,
  bbNamesOld,
  bbNamesNew,
  unformattedTelPhoneNumber,
} from '@sky-tv-group/shared';
import { Permission } from '../components/permission';
import { myAccountSWR } from '../swr';

const Home = () => {
  const { selectedIsSkyStarterCustomer } = useStore();
  const {
    setSelectedTuiAddress,
    setGuestPrequal,
    setSelectedInstallationAddress,
    setUseGuestPrequal,
    setSelectedAddressId,
  } = useStore();
  const { wifiGuySelectedAddressId } = useGetWifiGuyRecommendations();

  const { hasActiveOrPendingBroadbandPackage } = useStore();
  const { setSelectedIsSkyStarterCustomer } = useStore();
  const { setProductStoreCartTypeUpgrade } = useProductStore();
  const { hasPriceHoldCampaign } = myAccountSWR.usePackages();
  const { isAuthenticated } = useAuth0();

  const marketingInfoRef = useRef<HTMLInputElement>(null);

  const search = useLocation().search;
  const urlSearchParams = new URLSearchParams(search);
  const section = urlSearchParams.get('section');
  const customer = urlSearchParams.get('customer');

  const [shouldShowOneMonthBanner] = useSplitIO(SPLITIO_KEY.SKYWEB_ONE_MONTH_ON_US);
  const [shouldShowBBPromoVideo] = useSplitIO(SPLITIO_KEY.SKYWEB_BB_TVC);
  const [broadbandNewpriceEnabled] = useSplitIO(SPLITIO_KEY.SKYWEB_BROADBAND_NEWPRICE);
  const config = useJSONConfig(configService);
  const firstMonthBannerConfig: FirstMonthBannerConfig =
    config?.broadband?.acquisition?.firstMonthBannerConfigWithoutTitle;

  let priceOverride: string | undefined;
  if (hasPriceHoldCampaign || !broadbandNewpriceEnabled) {
    priceOverride = `$${bbNamesOld.broadbandWifi100.price1}.00`;
  } else {
    priceOverride = `$${bbNamesNew.broadbandWifi100.price1}.00`;
  }

  useEffect(() => {
    async function prequalify(addressId: string) {
      try {
        const prequal = await prequalService.getPrequalDetails(addressId);

        setSelectedTuiAddress(addressId);

        if (prequal) {
          setGuestPrequal(prequal);
        }
      } catch (err) {
        console.log('prequalify error', err);
      }
    }

    async function getAddressDetails() {
      //Set address details from the selected Address ID
      if (wifiGuySelectedAddressId) {
        const addressDetails = await addressService.getAddressDetail(wifiGuySelectedAddressId);
        const tui = get(addressDetails, 'references.tui', undefined);

        const formattedAddress = combineAddressArray([
          addressDetails.formatted_delivery_address?.line1,
          addressDetails.formatted_delivery_address?.line2,
          addressDetails.formatted_delivery_address?.line4,
          addressDetails.formatted_delivery_address?.line4,
        ]);
        const addressLines = formatAddress(
          addressDetails.formatted_delivery_address.line1,
          addressDetails.formatted_delivery_address.line2,
          addressDetails.formatted_delivery_address.line3,
          addressDetails.formatted_delivery_address.line4
        );

        setSelectedInstallationAddress(formattedAddress.toUpperCase(), addressLines);
        setSelectedAddressId(addressDetails.references.id);
        setUseGuestPrequal(true);
        prequalify(tui);
      }
    }
    getAddressDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function prequalify(addressId: string) {
      try {
        const prequal = await prequalService.getPrequalDetails(addressId);

        setSelectedTuiAddress(addressId);

        if (prequal) {
          setGuestPrequal(prequal);
        }
      } catch (err) {
        console.log('prequalify error', err);
      }
    }

    async function getAddressDetails() {
      //Set address details from the selected Address ID
      if (wifiGuySelectedAddressId) {
        const addressDetails = await addressService.getAddressDetail(wifiGuySelectedAddressId);
        const tui = get(addressDetails, 'references.tui', undefined);

        const formattedAddress = combineAddressArray([
          addressDetails.formatted_delivery_address?.line1,
          addressDetails.formatted_delivery_address?.line2,
          addressDetails.formatted_delivery_address?.line4,
          addressDetails.formatted_delivery_address?.line4,
        ]);
        const addressLines = formatAddress(
          addressDetails.formatted_delivery_address.line1,
          addressDetails.formatted_delivery_address.line2,
          addressDetails.formatted_delivery_address.line3,
          addressDetails.formatted_delivery_address.line4
        );

        setSelectedInstallationAddress(formattedAddress.toUpperCase(), addressLines);
        setSelectedAddressId(addressDetails.references.id);
        setUseGuestPrequal(true);
        prequalify(tui);
      }
    }
    getAddressDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (section) {
      case 'marketinginfo':
        if (marketingInfoRef && marketingInfoRef.current) {
          window.scrollTo(0, marketingInfoRef.current.offsetTop - 25);
        }
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, [marketingInfoRef]);

  useEffect(() => {
    setSelectedIsSkyStarterCustomer(isAuthenticated ? true : customer === 'y');

    /* Make isSkyStarterCustomer behaviour consistent with analytics. */
    setProductStoreCartTypeUpgrade(isAuthenticated ? true : customer === 'y');

    // eslint-disable-next-line
  }, [isAuthenticated]);

  return (
    <div className="sky-bg-gray-light">
      <div>
        <div className="relative">
          <BroadbandBanner priceOverride={priceOverride} />
          {/* <div className="md:flex  md:container md:mx-auto md:relative"> */}
          <CheckYourAddress />
          {/* </div> */}
        </div>

        {!hasActiveOrPendingBroadbandPackage && (
          <Permission excludes={[UserGroupType.SKY_VTV_CUSTOMER]}>
            <ChoosePlan />
            {shouldShowOneMonthBanner && !selectedIsSkyStarterCustomer && (
              <FirstMonthOffer
                infoConfig={firstMonthBannerConfig}
                className={'container m-auto pb-10 px-4 md:px-0 md:pb-13'}
              />
            )}
            {/* <WifiGuy /> */}
          </Permission>
        )}

        <Permission includes={[UserGroupType.SKY_VTV_CUSTOMER]}>
          <div className="container c-container m-auto py-4">
            <h4 className="bg-gray-light text-navy text-center h-24 flex justify-center items-center font-bold">
              Call&nbsp;<a href={`tel:${telPhoneNumber}`}> {telPhoneNumber} to Get Sky Broadband Now.</a>
            </h4>
          </div>
        </Permission>

        <div className="sky-bg-white">
          <div className="md:container mx-auto">
            <SpeedShowCase data={config?.broadband?.acquisition?.speedShowCase} />
            <div className="mt-14">
              <section
                className="w-11/12 md:w-9/12"
                style={{
                  backgroundImage:
                    'url(https://sky.co.nz/documents/117005/8169531/Broadband-Flar-Phone-Number-Background.png/ecb264ed-14b5-6c2c-e5fe-0c7d5db3ee94?t=1690949289612)',
                  backgroundPosition: '50% 50',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  margin: '0rem auto',
                  borderRadius: '10px',
                }}>
                <div className="py-10 md:py-8 px-8">
                  <div className="row no-gutters">
                    <div className="flex flex-col items-center justify-center md:flex-row">
                      <div id="fragment-117142-nhtd">
                        <div className="fr-paragraph fr-paragraph-text-color-white fr-paragraph-text-align-center fr-paragraph-text-font-markprobold fr-paragraph-text-size-s">
                          <div>
                            <span className="text-2xl">Join Sky Broadband today by giving us a call</span>
                          </div>
                        </div>
                      </div>
                      <div id="fragment-628242-nohy" className="mt-6 md:mt-0">
                        <div className="custom-sky-button pb-0 text-center text-break">
                          <div className="md:ml-6">
                            <a
                              className="sky-button sky-button--primary-pure btn btn-sm"
                              href={`tel:${unformattedTelPhoneNumber}`}
                              id="fragment-nohy-link"
                              target="_blank">
                              Call {`${unformattedTelPhoneNumber}`}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        {/* This element is to focus here when section = 'MarketingInfo' */}
        <div ref={marketingInfoRef}></div>
        <MarketingInfo />
        <Reviews />
        {/* Was asked to be removed in WEB-2275 */}
        {/* <LatestTech /> */}
        {shouldShowBBPromoVideo ? (
          <div className=" flex justify-center w-full  overflow-hidden min-w-0 sky-bg-white">
            <Promo />
          </div>
        ) : (
          ''
        )}
        <Faq filter={'Marketing'} title={"FAQ's"} />
      </div>
    </div>
  );
};

export { Home };
