import React, { useState, useEffect } from 'react';
import { getProductImageUrl, T_Product, useBBname } from '@sky-tv-group/shared';
import { Aspect, Switch, TwoOptionsSelector, Image, Pricing } from '@sky-tv-group/components';
import { getProductOrangeImage } from '../../helper';
import { useStore } from '../../store';
import { YesNoValueType, SkyCustomerYesNoOption } from '../../types';

interface PackageCanBuyProps {
  data: T_Product;
  broadband?: T_Product;
  toggle: (prodcut: T_Product) => Promise<void>;
  showZeroAsFree?: boolean;
  toggleLabel?: string;
  hidePrice?: boolean;
  isAddonProduct?: boolean;
  recommended: boolean;
}

export let WifiBoosterPackageCanBuy = ({
  data,
  broadband,
  toggle,
  showZeroAsFree = false,
  toggleLabel,
  hidePrice = false,
  isAddonProduct = false,
  recommended = false,
}: PackageCanBuyProps) => {
  const bbNames = useBBname();
  const { isUpdatingPlan, setIsUpdatingPlan } = useStore();
  const productImg = getProductOrangeImage(data?.sku) ?? getProductImageUrl(data);
  const [isBoosterRecommended, setIsBoosterRecommended] = useState(false);
  const [isBoosterAlreadyRecommended, setIsBoosterAlreadyRecommended] = useState(false);

  useEffect(() => {
    if (recommended) {
      setIsBoosterRecommended(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommended]);

  const copy = {
    product: {
      title: 'Sky WiFi Booster',
      subtitle: "Extend your WiFi's range",
      titleInfo: 'If your home is multi-level or you live in a palace, then you may need an extra boost.',
      description: 'Do you already know you need a booster in your home?',
      toggleCaption: `${bbNames.broadbandLightningFastWiFi.label} Plan`,
      tip: 'One booster should do the trick, but if you find you need more coverage give us a call.',
      questionsTitle: 'Answer the questions below to see if you need a booster.',
      question1: 'Does your home have 2 or more levels?',
      question2: 'Does your home have 4+ bedrooms?',
      question3: 'Do you have 2 or more lounge rooms?',
      dontNeed: "We don't think you need a Sky WiFi Booster.",
      doNeed: 'Based on this we recommend a Sky WiFi Booster.',
      reminderBoldSection: '',
      reminderNormalSection: 'Just checking, as we do recommend a Sky WiFi Booster based on the specs of your home.',
    },
  };

  const {
    alreadyKnow,
    setAlreadyKnow,
    question1,
    setQuestion1,
    question2,
    setQuestion2,
    question3,
    setQuestion3,
  } = useStore(s => ({
    alreadyKnow: s.persistedButtonStates.wifiBoosterAlreadyKnow,
    setAlreadyKnow: (value: YesNoValueType) => s.setPersistedButtonStates('wifiBoosterAlreadyKnow', value),
    question1: s.persistedButtonStates.wifiBoosterQuestion1,
    setQuestion1: (value: YesNoValueType) => s.setPersistedButtonStates('wifiBoosterQuestion1', value),
    question2: s.persistedButtonStates.wifiBoosterQuestion2,
    setQuestion2: (value: YesNoValueType) => s.setPersistedButtonStates('wifiBoosterQuestion2', value),
    question3: s.persistedButtonStates.wifiBoosterQuestion3,
    setQuestion3: (value: YesNoValueType) => s.setPersistedButtonStates('wifiBoosterQuestion3', value),
  }));

  const [showReminder, setShowReminder] = useState<boolean>(false);
  const [showTip, setShowTip] = useState<boolean>(false);
  const [isInitialRender, setIsInitialRender] = useState<boolean>(true);
  const [manualProductToggle, setManualProductToggle] = useState<boolean>(false);

  const needBooster =
    question1 === SkyCustomerYesNoOption.YES ||
    question2 === SkyCustomerYesNoOption.YES ||
    question3 === SkyCustomerYesNoOption.YES;

  let onToggle = async () => {
    setIsBoosterRecommended(false);
    setManualProductToggle(true);
    setIsUpdatingPlan(true);
    await toggle(data);
    setIsUpdatingPlan(false);
  };

  useEffect(() => {
    setShowTip(data?.quantityInCart > 0);
    if (isBoosterRecommended === true && isBoosterAlreadyRecommended === false) {
      setIsBoosterAlreadyRecommended(true);
      onToggle();
      return;
    }

    if (isBoosterRecommended === true && isBoosterAlreadyRecommended === false) {
      setIsBoosterAlreadyRecommended(true);
      onToggle();
      return;
    }

    if (isInitialRender) return;

    setShowReminder(
      (question1 === SkyCustomerYesNoOption.YES ||
        question2 === SkyCustomerYesNoOption.YES ||
        question3 === SkyCustomerYesNoOption.YES) &&
        data?.quantityInCart === 0
    );

    if (manualProductToggle) return;

    if (alreadyKnow === SkyCustomerYesNoOption.YES && data?.quantityInCart === 0) {
      onToggle();
    } else if (
      question1 === SkyCustomerYesNoOption.YES ||
      question2 === SkyCustomerYesNoOption.YES ||
      question3 === SkyCustomerYesNoOption.YES
    ) {
      if (data?.quantityInCart === 0) {
        onToggle();
      }
    } else if (
      question1 === SkyCustomerYesNoOption.NO ||
      question2 === SkyCustomerYesNoOption.NO ||
      question3 === SkyCustomerYesNoOption.NO
    ) {
      if (alreadyKnow !== SkyCustomerYesNoOption.YES && data?.quantityInCart > 0) {
        onToggle();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question1, question2, question3, alreadyKnow, data?.quantityInCart, isBoosterRecommended]);

  const onQuestion1Select = (value: YesNoValueType) => {
    setManualProductToggle(false);
    setIsInitialRender(false);
    setQuestion1(value);
  };

  const onQuestion2Select = (value: YesNoValueType) => {
    setManualProductToggle(false);
    setIsInitialRender(false);
    setQuestion2(value);
  };

  const onQuestion3Select = (value: YesNoValueType) => {
    setManualProductToggle(false);
    setIsInitialRender(false);
    setQuestion3(value);
  };

  const onAlreadyKnowSelect = (value: YesNoValueType) => {
    setManualProductToggle(false);
    setIsInitialRender(false);
    setAlreadyKnow(value);
  };

  return (
    <>
      {/* MOBILE VIEW */}
      <div className="md:hidden">
        <div className="p-6">
          <div className="flex items-center">
            <div className="w-32 min-w-32 h-20 flex items-center">
              <Aspect ratio={3 / 4}>
                <Image className="h-full w-full object-scale-down" src={productImg} alt={data?.description} />
              </Aspect>
            </div>
            <div className="flex flex-col">
              <div className="sky-h6-bold">{data?.name}</div>
              <div className="sky-h7-reg mt-1">{data?.custom8}</div>
            </div>
          </div>
          <div className="sky-h7-reg text-left text-black pt-4">{data?.description}</div>

          <div className="sky-h7-reg text-left mt-8">{copy.product.description}</div>

          <div className="flex justify-center pb-4 mt-6">
            <div style={{ width: '100%', maxWidth: '500px' }}>
              <TwoOptionsSelector
                valueSelected={alreadyKnow}
                valueLeft={SkyCustomerYesNoOption.YES}
                valueRight={SkyCustomerYesNoOption.NO}
                onSelectChange={onAlreadyKnowSelect}
                isDisabled={isUpdatingPlan}
              />
            </div>
          </div>
          {alreadyKnow === SkyCustomerYesNoOption.NO && (
            <div>
              <div className="mb-8 pt-4 sky-h7-bold text-left">{copy.product.questionsTitle}</div>
              <div className="sky-h7-reg text-left mb-4">{copy.product.question1}</div>
              <div className="flex justify-center">
                <div style={{ width: '100%', maxWidth: '500px' }}>
                  <TwoOptionsSelector
                    valueSelected={question1}
                    valueLeft={SkyCustomerYesNoOption.YES}
                    valueRight={SkyCustomerYesNoOption.NO}
                    onSelectChange={onQuestion1Select}
                    isDisabled={isUpdatingPlan}
                  />
                </div>
              </div>
              <div className="sky-h7-reg text-left mb-4 mt-6">{copy.product.question2}</div>
              <div className="flex justify-center">
                <div style={{ width: '100%', maxWidth: '500px' }}>
                  <TwoOptionsSelector
                    valueSelected={question2}
                    valueLeft={SkyCustomerYesNoOption.YES}
                    valueRight={SkyCustomerYesNoOption.NO}
                    onSelectChange={onQuestion2Select}
                    isDisabled={isUpdatingPlan}
                  />
                </div>
              </div>
              <div className="sky-h7-reg text-left mb-4 mt-6">{copy.product.question3}</div>
              <div className="flex justify-center">
                <div style={{ width: '100%', maxWidth: '500px' }}>
                  <TwoOptionsSelector
                    valueSelected={question3}
                    valueLeft={SkyCustomerYesNoOption.YES}
                    valueRight={SkyCustomerYesNoOption.NO}
                    onSelectChange={onQuestion3Select}
                    isDisabled={isUpdatingPlan}
                  />
                </div>
              </div>
              {needBooster && <div className="sky-h7-reg text-left mt-8 mb-4">{copy.product.doNeed}</div>}
              {!needBooster && <div className="sky-h7-reg text-left mt-8 mb-4">{copy.product.dontNeed}</div>}
            </div>
          )}

          <div className="flex justify-between pt-4 items-center">
            <div className="flex flex-col w-4/5">
              {toggleLabel && <div className="sky-h7-bold">{toggleLabel}</div>}
              {!hidePrice && <Pricing showZeroAsFree={showZeroAsFree} priceIncTax={data?.priceIncTax} />}
            </div>
            <div>
              <Switch
                value={data?.quantityInCart > 0}
                onChange={onToggle}
                disable={isUpdatingPlan}
                dataTestId={data?.sku}
              />
            </div>
          </div>

          {showReminder && (
            <div className="sky-h7-reg text-left text-red mt-8">
              <span className="sky-h7-bold">{copy.product.reminderBoldSection}</span>{' '}
              {copy.product.reminderNormalSection}
            </div>
          )}
        </div>
        {showTip && (
          <div className="sky-bg-gray-light p-3 mt-4 justify-center">
            <div className="sky-h7-bold text-center">{copy.product.tip}</div>
          </div>
        )}
      </div>
      {/* DESKTOP VIEW */}
      <div className="hidden md:block">
        {/* main product view. e.g.: Router, Booster, StaticIP */}
        {!isAddonProduct && (
          <div>
            <div className="flex flex-col xl:flex-row py-6 xl:px-6">
              <div className="flex flex-col xl:w-1/3 px-10 xl:pl-0 mb-8 xl:mb-0">
                <div className="flex flex-row md:items-center xl:items-start">
                  <div className="w-32 min-w-32 h-20 flex items-center">
                    <Aspect ratio={9 / 16}>
                      <Image className="h-full w-full object-scale-down" src={productImg} alt={data?.description} />
                    </Aspect>
                  </div>
                  <div className="flex flex-col">
                    <div className="sky-h5-bold">{data?.name}</div>
                    <div className="sky-h7-reg mt-3">{data?.custom8}</div>
                  </div>
                </div>
                <div className="xl:hidden xl:pl-8 mt-5 text-black sky-h6-reg">{data?.description}</div>
                <div className="hidden xl:block xl:pl-8 mt-5 text-black sky-h7-reg" style={{ lineHeight: '21px' }}>
                  {data?.description}
                </div>
              </div>
              <div className="flex flex-col justify-evenly px-10 xl:w-2/3 xl:px-0">
                <div className="flex flex-col">
                  <div className="sky-h6-reg text-left mb-6 w-3/4">{copy.product.description}</div>
                  <div className="flex justify-center mb-10">
                    <div style={{ width: '100%', maxWidth: '500px' }}>
                      <TwoOptionsSelector
                        valueSelected={alreadyKnow}
                        valueLeft={SkyCustomerYesNoOption.YES}
                        valueRight={SkyCustomerYesNoOption.NO}
                        onSelectChange={onAlreadyKnowSelect}
                        isDisabled={isUpdatingPlan}
                      />
                    </div>
                  </div>
                  {alreadyKnow === SkyCustomerYesNoOption.NO && (
                    <div>
                      <div className="mb-10 sky-h5-bold text-left">{copy.product.questionsTitle}</div>
                      <div className="sky-h6-reg text-left mb-4">{copy.product.question1}</div>
                      <div className="flex justify-center">
                        <div style={{ width: '100%', maxWidth: '500px' }}>
                          <TwoOptionsSelector
                            valueSelected={question1}
                            valueLeft={SkyCustomerYesNoOption.YES}
                            valueRight={SkyCustomerYesNoOption.NO}
                            onSelectChange={onQuestion1Select}
                            isDisabled={isUpdatingPlan}
                          />
                        </div>
                      </div>
                      <div className="sky-h6-reg text-left mb-4 mt-6">{copy.product.question2}</div>
                      <div className="flex justify-center">
                        <div style={{ width: '100%', maxWidth: '500px' }}>
                          <TwoOptionsSelector
                            valueSelected={question2}
                            valueLeft={SkyCustomerYesNoOption.YES}
                            valueRight={SkyCustomerYesNoOption.NO}
                            onSelectChange={onQuestion2Select}
                            isDisabled={isUpdatingPlan}
                          />
                        </div>
                      </div>
                      <div className="sky-h6-reg text-left mb-4 mt-6">{copy.product.question3}</div>
                      <div className="flex justify-center">
                        <div style={{ width: '100%', maxWidth: '500px' }}>
                          <TwoOptionsSelector
                            valueSelected={question3}
                            valueLeft={SkyCustomerYesNoOption.YES}
                            valueRight={SkyCustomerYesNoOption.NO}
                            onSelectChange={onQuestion3Select}
                            isDisabled={isUpdatingPlan}
                          />
                        </div>
                      </div>

                      {needBooster && <div className="sky-h6-reg text-left my-10">{copy.product.doNeed}</div>}
                      {!needBooster && <div className="sky-h6-reg text-left my-10">{copy.product.dontNeed}</div>}
                    </div>
                  )}
                </div>

                <div className="flex flex-row items-center justify-between w-full">
                  {toggleLabel && (
                    <div>
                      <div className="sky-h5-bold">{toggleLabel}</div>
                      {broadband && <p>{broadband.custom8}</p>}
                    </div>
                  )}
                  <div className="flex flex-row 2-1/4 items-center">
                    <div className="pr-4">
                      <Pricing showZeroAsFree={showZeroAsFree} priceIncTax={data?.price0} priceClassName="sky-h6-reg" />
                    </div>
                    <Switch
                      value={data?.quantityInCart > 0}
                      onChange={onToggle}
                      disable={isUpdatingPlan}
                      dataTestId={data?.sku}
                    />
                  </div>
                </div>

                {showReminder && (
                  <div className="sky-h6-reg text-left text-red mt-10">
                    <span className="sky-h6-bold">{copy.product.reminderBoldSection}</span>{' '}
                    {copy.product.reminderNormalSection}
                  </div>
                )}
              </div>
            </div>

            {showTip && (
              <div className="sky-bg-gray-light p-3 justify-center">
                <div className="sky-h7-bold text-center">{copy.product.tip}</div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
