import React, { useState } from 'react';
import { WifiBoosterPackageCanBuy } from './wifiBooster.package.can.buy';
import { useBroadbandSwapProduct, useGetWifiGuyRecommendations } from '@sky-tv-group/shared';
import { useEffect } from 'react';
import { productSkuIds, useBBname } from '@sky-tv-group/shared';
import { useStore } from '../../store';
import { kkService } from '../../services';

const WifiBooster = () => {
  const { broadbandProductSelected, setBroadbandProductSelected } = useStore(s => ({
    broadbandProductSelected: s.broadbandProductSelected,
    setBroadbandProductSelected: s.setBroadbandProductSelected,
  }));
  const bbName = useBBname();

  const {
    toggleMeshWithProductSwap,
    swapToBoostPlan,
    meshAdded,
    meshDevice,
    currentBroadband,
  } = useBroadbandSwapProduct(kkService);

  const { wifiGuyRecommendedSku } = useGetWifiGuyRecommendations();
  const isBoosterrecommended = wifiGuyRecommendedSku === productSkuIds.broadbandLightningFastWiFiBoost.primary;

  const [specialToggleLabel, setSpecialToggleLabel] = useState(`${bbName.broadbandLightningFastWiFiBoost.label} Plan`);

  // Cleanup these useEffects - We shouldnt react to the store to change the products
  useEffect(() => {
    if (meshAdded) {
      swapToBoostPlan(true);
    }
    //When ever mesh is added or removed updated the current selected broadband.
    if (currentBroadband) {
      setBroadbandProductSelected(currentBroadband);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meshAdded]);

  useEffect(() => {
    if (broadbandProductSelected) {
      const toggleLabel = broadbandProductSelected.name.includes(bbName.broadbandLightningFastWiFi.label)
        ? bbName.broadbandLightningFastWiFiBoost.label + ' Plan'
        : broadbandProductSelected.name.includes(bbName.broadbandWifi100.label)
        ? bbName.broadbandWifi100Boost.label + ' Plan'
        : bbName.broadbandStarterWiFiBoost.label + ' Plan';
      setSpecialToggleLabel(toggleLabel);
    }
  }, [broadbandProductSelected, bbName]);

  if (!meshDevice) return null;

  return (
    <div className="border border-solid border-gray-dark h-full mx-auto mb-4 py-2" data-testid={'booster-container'}>
      <WifiBoosterPackageCanBuy
        data={meshDevice}
        broadband={broadbandProductSelected}
        toggle={toggleMeshWithProductSwap}
        showZeroAsFree={false}
        toggleLabel={specialToggleLabel}
        recommended={isBoosterrecommended}
      />
    </div>
  );
};

export { WifiBooster };
