import React from 'react';
import { blueIcon } from '@sky-tv-group/components';

interface InfoPlacardProps {
  className?: string;
  info: string;
}

const InfoPlacard = ({ className = '', info }: InfoPlacardProps) => {
  return (
    <div className={'flex flex-row items-start md:items-center bg-blue-cool px-3 py-4 ' + className}>
      <div className={'mr-3'}>{blueIcon}</div>
      <div className="sky-h7-reg">{info}</div>
    </div>
  );
};

export { InfoPlacard };
