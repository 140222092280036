import React from 'react';
import cx from 'classnames';
import { links } from '@sky-tv-group/shared';

interface TermsAndConditionsProps {
  isMobileView: boolean;
}

const TermsAndConditions = ({ isMobileView = true }: TermsAndConditionsProps) => {
  return (
    <div className="flex flex-row w-full">
      {!isMobileView && <img src="/images/check_icon.svg" alt="feat" className="py-2 mr-4 h-8 invisible" />}
      <div
        className={cx('w-full  py-4 sky-text-midnight', {
          'sky-bg-gray-lightest text-center mx-auto py-7': isMobileView,
        })}>
        <a target="blank" href={links.bbTerms} className="underline cursor-pointer sky-text-blue">
          Terms & Conditions
        </a>{' '}
        and{' '}
        <a target="blank" href={links.bbExitFees} className="underline cursor-pointer sky-text-blue">
          exit fees
        </a>{' '}
        apply.
      </div>
    </div>
  );
};

export { TermsAndConditions };
