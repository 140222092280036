import React from 'react';
import { getProductImageUrl, T_Product } from '@sky-tv-group/shared';
import { Aspect, Switch, Image, Pricing } from '@sky-tv-group/components';
import { getProductOrangeImage } from '../../helper';
import { useStore } from '../../store';

interface PackageCanBuyProps {
  data: T_Product;
  toggle: (prodcut: T_Product) => Promise<void>;
  showZeroAsFree?: boolean;
  toggleLabel?: string;
  hidePrice?: boolean;
  isAddonProduct?: boolean;
  hidePriceOnDesktop?: boolean;
  appendSlash?: boolean;
  period?: string;
}

export let PackageCanBuy = ({
  data,
  toggle,
  showZeroAsFree = false,
  toggleLabel,
  hidePrice = false,
  isAddonProduct = false,
  hidePriceOnDesktop = false,
  appendSlash = true,
  period = 'mth',
}: PackageCanBuyProps) => {
  const { isUpdatingPlan, setIsUpdatingPlan } = useStore();

  let onToggle = async () => {
    setIsUpdatingPlan(true);
    await toggle(data);
    setIsUpdatingPlan(false);
  };

  const productImg = getProductOrangeImage(data?.sku) ?? getProductImageUrl(data);

  return (
    <>
      {/* MOBILE VIEW */}
      <div className="md:hidden p-4">
        <div className="flex items-center">
          <div className="w-32 min-w-32 h-20 flex items-center">
            <Aspect ratio={3 / 4}>
              <Image className="h-full w-full object-scale-down" src={productImg} alt={data?.description} />
            </Aspect>
          </div>
          <div className="flex flex-col">
            <div className="sky-h6-bold">{data?.name}</div>
            <div className="sky-h7-reg mt-1">{data?.custom8}</div>
          </div>
        </div>

        <div className="sky-h7-reg text-left text-black pt-4">{data?.description}</div>
        <div className="flex justify-between pt-6 items-center">
          <div className="flex flex-col w-4/5">
            {toggleLabel && <div className="sky-h7-bold">{toggleLabel}</div>}
            {!hidePrice && (
              <Pricing
                showZeroAsFree={showZeroAsFree}
                priceIncTax={data?.price0}
                period={period}
                appendSlash={appendSlash}
              />
            )}
          </div>
          <div>
            <Switch
              value={data?.quantityInCart > 0}
              onChange={onToggle}
              disable={isUpdatingPlan}
              dataTestId={data?.sku}
            />
          </div>
        </div>
      </div>
      {/* DESKTOP VIEW */}
      <div className="hidden md:block p-6" data-testid={data?.name}>
        {/* main product view. e.g.: Router, Booster, StaticIP */}
        {!isAddonProduct && (
          <div className="flex flex-col xl:flex-row">
            <div className="flex flex-row xl:w-1/3 px-6 xl:pl-0 md:items-center xl:items-start">
              <div className="w-32 min-w-32 h-20 flex items-center">
                <Aspect ratio={9 / 16}>
                  <Image className="h-full w-full object-scale-down" src={productImg} alt={data?.description} />
                </Aspect>
              </div>
              <div className="flex flex-col">
                <div className="sky-h5-bold">{data?.name}</div>
                <div className="sky-h7-reg mt-3">{data?.custom8}</div>
              </div>
            </div>
            <div className="flex flex-col justify-evenly px-6 xl:w-2/3 xl:px-0">
              <div className="xl:hidden sky-h6-reg text-black w-3/4 mt-5">{data?.description}</div>
              <div className="hidden xl:block sky-h6-reg w-3/4">{data?.description}</div>

              <div className="flex flex-row items-center justify-between w-full mt-4">
                {toggleLabel && <div className="sky-h5-bold">{toggleLabel}</div>}
                <div className="flex flex-row 2-1/4 items-center">
                  <div className="pr-4">
                    {!hidePriceOnDesktop && (
                      <Pricing
                        showZeroAsFree={showZeroAsFree}
                        priceIncTax={data?.price0}
                        priceClassName="sky-h6-reg"
                        appendSlash={appendSlash}
                        period={period}
                      />
                    )}
                  </div>
                  <Switch
                    value={data?.quantityInCart > 0}
                    onChange={onToggle}
                    disable={isUpdatingPlan}
                    dataTestId={data?.sku}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {/* addons product view. e.g.: Voice call packs */}
        {isAddonProduct && (
          <div className="flex flex-row">
            <div className="w-32 min-w-32 h-20 flex items-center">
              <Aspect ratio={9 / 16}>
                <Image className="h-full w-full object-scale-down" src={productImg} alt={data?.description} />
              </Aspect>
            </div>
            <div className="flex flex-col justify-evenly w-full">
              <div className="md:sky-h6-bold">{data?.name}</div>
              <div className="flex flex-row items-end justify-between">
                <div className="sky-h6-reg w-3/4 pr-4">{data?.description}</div>
                <div className="flex flex-row 2-1/4 items-center">
                  <div className="pr-4">
                    <Pricing showZeroAsFree={showZeroAsFree} priceIncTax={data?.price0} priceClassName="sky-h6-reg" />
                  </div>
                  <Switch
                    value={data?.quantityInCart > 0}
                    onChange={onToggle}
                    disable={isUpdatingPlan}
                    dataTestId={data?.sku}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
