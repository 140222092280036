import React from 'react';

export function SelectedSpeed() {
  const pathStyle = {
    fill: 'white',
  };
  return (
    <svg id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 520 520" width="100%" height="100%">
      <path
        style={pathStyle}
        d="M185.51,470.66a6.5,6.5,0,0,1-5.95-9.12c26.47-60.12,66.36-151.09,68.79-157.63a6.5,6.5,0,0,1,12.77,1.74c0,2,0,2.33-35.1,82.44-5.05,11.52-10.11,23.06-14.75,33.62L328.81,275.37,166.3,290a11.51,11.51,0,0,1-12.14-14.46L205.67,85.13a11.51,11.51,0,0,1,11.1-8.5H356.55a11.49,11.49,0,0,1,10.3,16.6L289.53,249.42a6.5,6.5,0,1,1-11.65-5.76l76.25-154H217.92L167.27,276.86l163.88-14.75a11.5,11.5,0,0,1,10,18.66L190.58,468.23A6.48,6.48,0,0,1,185.51,470.66Zm62.61-165Z"
      />
    </svg>
  );
}

export function SelectedCoverage() {
  const pathStyle = {
    fill: 'white',
  };
  return (
    <svg
      style={pathStyle}
      id="Icons"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 520 520"
      width="100%"
      height="100%">
      <path
        style={pathStyle}
        d="M233.57,300.81,224.38,297c-2.69-1.15-3.71-1.92-3.55-2.86s.83-1.69,3.38-1.69h18.93a1,1,0,0,0,1-.8l1.47-6.95a1,1,0,0,0-1-1.23H225.07c-9.29.06-13,5-14.18,10.23-1.46,6.61,3.83,9.67,7.61,11.24l10.08,4.1c2.47,1,2.86,1.9,2.59,2.92s-1.16,1.84-4.3,1.84H207.44a1,1,0,0,0-1,.8L205,321.51a1,1,0,0,0,1,1.24h20.9c9.59-.09,12.93-5.06,14.12-10.48C242.51,305.4,238.25,302.79,233.57,300.81Z"
      />
      <path
        style={pathStyle}
        d="M321.67,283.44h-8.94a1,1,0,0,0-.76.34l-14.42,16.3-9.67-16.15a1,1,0,0,0-.87-.49H274.58a1,1,0,0,0-.76.34l-16.81,19,11.66,19.47a1,1,0,0,0,.87.49h9.28a1,1,0,0,0,.87-1.54l-11-18.42,11.58-13.1,11.49,19.23-5.4,25.38a1,1,0,0,0,1,1.23h7.59a1,1,0,0,0,1-.81l5.49-25.82,21-23.77A1,1,0,0,0,321.67,283.44Z"
      />
      <path
        style={pathStyle}
        d="M263.54,266.15h-7.89a1,1,0,0,0-1,.8l-11.6,54.57a1,1,0,0,0,1,1.23h7.88a1,1,0,0,0,1-.81l11.59-54.56A1,1,0,0,0,263.54,266.15Z"
      />
      <path
        style={pathStyle}
        d="M380.14,118.92a6.48,6.48,0,0,1-4.59-1.91,163.42,163.42,0,0,0-231.1,0,6.5,6.5,0,0,1-9.19-9.19,176.41,176.41,0,0,1,249.48,0,6.51,6.51,0,0,1-4.6,11.1Z"
      />
      <path
        style={pathStyle}
        d="M346.19,152.87a6.5,6.5,0,0,1-4.6-1.91,115.51,115.51,0,0,0-163.18,0,6.5,6.5,0,0,1-9.2-9.19,128.55,128.55,0,0,1,181.58,0,6.51,6.51,0,0,1-4.6,11.1Z"
      />
      <path
        style={pathStyle}
        d="M312.24,186.82a6.47,6.47,0,0,1-4.6-1.9,67.45,67.45,0,0,0-95.28,0,6.5,6.5,0,1,1-9.19-9.19,80.45,80.45,0,0,1,113.66,0,6.5,6.5,0,0,1-4.59,11.09Z"
      />
      <path
        style={pathStyle}
        d="M169.66,463.85a6.5,6.5,0,0,1-6.5-6.5V438.14a6.5,6.5,0,0,1,13,0v19.21A6.5,6.5,0,0,1,169.66,463.85Z"
      />
      <path
        style={pathStyle}
        d="M350.34,463.85a6.5,6.5,0,0,1-6.5-6.5V438.14a6.5,6.5,0,0,1,13,0v19.21A6.5,6.5,0,0,1,350.34,463.85Z"
      />
      <path
        style={pathStyle}
        d="M260,458.74a126.21,126.21,0,0,1-21.82-1.91l-77.35-13.64c-18-3-29.14-14.55-29.14-30.16V216.19c0-31.37,2.19-38.87,15.61-53.46a6.5,6.5,0,0,1,9.57,8.8c-10.05,10.93-12.18,14.3-12.18,44.66V413c0,12.9,12.83,16.43,18.34,17.34L240.44,444a112.3,112.3,0,0,0,39.13,0l39.26-6.92a6.5,6.5,0,0,1,2.26,12.8l-39.27,6.92A125.24,125.24,0,0,1,260,458.74Z"
      />
      <path
        style={pathStyle}
        d="M350.33,444.64a6.5,6.5,0,0,1-1.12-12.9l7.7-1.36c5.57-.92,18.4-4.45,18.4-17.35V216.19c0-30.36-2.13-33.73-12.18-44.66a6.5,6.5,0,0,1,9.57-8.8c13.42,14.59,15.61,22.09,15.61,53.46V413c0,15.61-11.19,27.17-29.21,30.17l-7.63,1.34A6.43,6.43,0,0,1,350.33,444.64Z"
      />
      <path
        style={pathStyle}
        d="M169.42,388.82a6.76,6.76,0,0,1-1.27-.13,7.17,7.17,0,0,1-1.22-.37,6.69,6.69,0,0,1-1.12-.6,6.43,6.43,0,0,1-1.8-1.8,6.64,6.64,0,0,1-.59-1.12,6.53,6.53,0,0,1-.38-1.21,6.83,6.83,0,0,1-.12-1.27,6.67,6.67,0,0,1,.12-1.27,6.4,6.4,0,0,1,.38-1.22,6.64,6.64,0,0,1,.59-1.12,6.43,6.43,0,0,1,1.8-1.8,7.51,7.51,0,0,1,1.12-.6,7.17,7.17,0,0,1,1.22-.37,6.39,6.39,0,0,1,2.54,0,7.35,7.35,0,0,1,1.21.37,7.62,7.62,0,0,1,1.13.6,6.91,6.91,0,0,1,1,.81,5.93,5.93,0,0,1,.81,1,6,6,0,0,1,.6,1.12,6.37,6.37,0,0,1,.37,1.22,6.67,6.67,0,0,1,.13,1.27,6.84,6.84,0,0,1-.13,1.27,6.5,6.5,0,0,1-.37,1.21,6,6,0,0,1-.6,1.12,5.93,5.93,0,0,1-.81,1,6.91,6.91,0,0,1-1,.81,6.79,6.79,0,0,1-1.13.6,7.35,7.35,0,0,1-1.21.37A6.84,6.84,0,0,1,169.42,388.82Z"
      />
      <path
        style={pathStyle}
        d="M169.42,412.72a6.76,6.76,0,0,1-1.27-.13,6.37,6.37,0,0,1-1.22-.37,6,6,0,0,1-1.12-.6,6.43,6.43,0,0,1-1.8-1.8,6.64,6.64,0,0,1-.59-1.12,6.53,6.53,0,0,1-.38-1.21,6.83,6.83,0,0,1-.12-1.27A6.75,6.75,0,0,1,163,405a6.4,6.4,0,0,1,.38-1.22,6.64,6.64,0,0,1,.59-1.12,6.43,6.43,0,0,1,1.8-1.8,7.51,7.51,0,0,1,1.12-.6,7.17,7.17,0,0,1,1.22-.37,6.39,6.39,0,0,1,2.54,0,7.35,7.35,0,0,1,1.21.37,7.62,7.62,0,0,1,1.13.6,6.91,6.91,0,0,1,1,.81,5.93,5.93,0,0,1,.81,1,6,6,0,0,1,.6,1.12,6.37,6.37,0,0,1,.37,1.22,6.76,6.76,0,0,1,.13,1.27,6.84,6.84,0,0,1-.13,1.27,6.5,6.5,0,0,1-.37,1.21,6,6,0,0,1-.6,1.12,5.93,5.93,0,0,1-.81,1,6.91,6.91,0,0,1-1,.81,6.12,6.12,0,0,1-1.13.6,6.5,6.5,0,0,1-1.21.37A6.84,6.84,0,0,1,169.42,412.72Z"
      />
    </svg>
  );
}

export function SelectedSetup() {
  const pathStyle = {
    fill: 'white',
  };
  return (
    <svg
      style={pathStyle}
      id="Icons"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 520 520"
      width="100%"
      height="100%">
      <path
        style={pathStyle}
        d="M124.41,403a6.51,6.51,0,0,1-2.49-12.51,43.78,43.78,0,0,0,26.58-47L131.58,230.12,72.64,254.53l46.43,112.08a6.5,6.5,0,0,1-12,5L58.14,253.5A6.5,6.5,0,0,1,61.66,245l72.63-30.08a6.49,6.49,0,0,1,8.92,5l18.14,121.64A56.74,56.74,0,0,1,126.9,402.5,6.47,6.47,0,0,1,124.41,403Z"
      />
      <path
        style={pathStyle}
        d="M457,176H352.31a6.5,6.5,0,0,1-6.5-6.5V113.37a6.5,6.5,0,0,1,6.5-6.5H457a6.5,6.5,0,0,1,6.5,6.5v56.17A6.5,6.5,0,0,1,457,176Zm-98.17-13h91.67V119.87H358.81Z"
      />
      <path
        style={pathStyle}
        d="M457,119.87a6.5,6.5,0,0,1-2.8-.64L393.74,90.3a6.5,6.5,0,1,1,5.61-11.72l60.44,28.92A6.5,6.5,0,0,1,457,119.87Z"
      />
      <path
        style={pathStyle}
        d="M272.18,369.07H179.61a6.5,6.5,0,0,1,0-13h92.57a11.31,11.31,0,0,0,11.3-11.29V327a6.5,6.5,0,0,1,6.5-6.5h3.71A11.31,11.31,0,0,0,305,309.19V291.4a6.5,6.5,0,0,1,6.5-6.5h3.72a11.3,11.3,0,0,0,11.29-11.29V255.83a6.5,6.5,0,0,1,6.5-6.5h3.72a11.3,11.3,0,1,0,0-22.59h-119A116.7,116.7,0,0,0,180,233l-38.26,13.09a6.5,6.5,0,1,1-4.2-12.3l38.26-13.09a129.5,129.5,0,0,1,41.89-6.94h119a24.29,24.29,0,0,1,2.78,48.43v11.44A24.32,24.32,0,0,1,318,297.74v11.45a24.33,24.33,0,0,1-21.5,24.14v11.45A24.32,24.32,0,0,1,272.18,369.07Z"
      />
      <path
        style={pathStyle}
        d="M253,222a6.5,6.5,0,0,1-6.5-6.5V193.45A58.57,58.57,0,0,1,305,135H327a6.5,6.5,0,0,1,0,13H305a45.56,45.56,0,0,0-45.5,45.5V215.5A6.5,6.5,0,0,1,253,222Z"
      />
      <path
        style={pathStyle}
        d="M253,452.91a6.5,6.5,0,0,1-6.5-6.5v-58a6.5,6.5,0,0,1,13,0v58A6.5,6.5,0,0,1,253,452.91Z"
      />
    </svg>
  );
}
