import {
  AddressService,
  PrequalService,
  NavigationService,
  MyAccountService,
  KonakartService,
  JSONConfigService,
  FaqService,
  TechnicianAvailabilityService,
  ServiceProviderService,
  OrderService,
  VoiceService,
  TrackerService,
  SentryService,
  MyAccountV2Service,
  MyAccountV2SWR,
} from '@sky-tv-group/shared';

import {
  addressAgent,
  formAgent,
  prequalAgent,
  agentKK,
  agentNavigation,
  agentMyAccount,
  gqlAgentMyAccount,
  agentTechSlots,
  agentJSONConfig,
  agentFaq,
  agentCart,
  agentVoice,
  agentTracker,
} from '../agent';

import { APP_ENVIRONMENT, LIFERAY_CONNECT_ENDPOINT, SENTRY_DENY_URLS, SENTRY_DSN } from '../config';

export const myAccountService = new MyAccountService(agentMyAccount);
export const myAccountV2Service = new MyAccountV2Service(agentMyAccount, gqlAgentMyAccount);
export const addressService = new AddressService(addressAgent);
export const navService = new NavigationService(agentNavigation);
export const kkService = new KonakartService(agentKK, LIFERAY_CONNECT_ENDPOINT);
export const configService = new JSONConfigService(agentJSONConfig);
export const faqService = new FaqService(agentFaq);
export const serviceProviderService = new ServiceProviderService(prequalAgent);
export const technicianLookup = new TechnicianAvailabilityService(agentTechSlots);
export const orderService = new OrderService(agentCart);
export const voiceService = new VoiceService(agentVoice);
export const trackerService = new TrackerService(agentTracker);
export const sentryService = new SentryService(SENTRY_DSN, APP_ENVIRONMENT, SENTRY_DENY_URLS);
export const myAccountV2SWR = new MyAccountV2SWR(myAccountV2Service);

export const submitForm = (payload: any) => {
  return formAgent.post('/create', payload);
};

export const prequalService = new PrequalService(prequalAgent);
