/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useCheckYourAddress } from '../../hooks/useCheckYourAddress';
import { format, parseISO } from 'date-fns';
import { useAuth0 } from '../../auth0/store';
import { useStore } from '../../store';

type FibreDescriptionProps = {
  clearInstallationAddressField: () => void;
  showNeverMsg?: boolean;
};

const FibreDescription = ({ clearInstallationAddressField, showNeverMsg = false }: FibreDescriptionProps) => {
  const { isAuthenticated } = useAuth0(s => ({
    isAuthenticated: s.isAuthenticated,
  }));

  const {
    fibreETA,
    isFibreOk,
    isFibreFuture,
    isFibreNeverOrUnknown,
    authTuiDiffersFromSelected,
  } = useCheckYourAddress();
  const descriptionStart = 'Fibre is not due to be available in your street';
  const descriptionEnd = ", so we'll be in touch nearer the time so you can start enjoying Sky Broadband!";

  const formatDate = (date: Date | undefined): string => {
    return date ? format(parseISO(date.toString()), 'MMM-yyyy') : '';
  };

  const fibreETADate = formatDate(fibreETA());
  const crownfibreURL = 'http://www.crownfibre.govt.nz';

  const { selectedTuiAddress } = useStore(s => ({
    selectedTuiAddress: s.selectedTuiAddress,
  }));

  if (showNeverMsg && !isFibreNeverOrUnknown()) return null;

  if (isFibreOk()) {
    return (
      <div className="sky-h6-reg py-3">
        <div className="">
          Not your address?{' '}
          <a className="underline cursor-pointer text-bborange" onClick={clearInstallationAddressField}>
            Check another address
          </a>
        </div>
      </div>
    );
  } else if (isFibreFuture()) {
    return (
      <div className="sky-h6-reg py-3 text-bborange">
        {descriptionStart} {fibreETADate ? 'until ' + fibreETADate : fibreETADate}
        {descriptionEnd}
      </div>
    );
  } else if (isFibreNeverOrUnknown()) {
    return showNeverMsg ? (
      <div>
        Thank you for your interest in new Sky Broadband, but unfortunately fibre is not available in your area. There
        are no current government plans to roll out fibre to your address. Visit{' '}
        <a
          href={crownfibreURL}
          target="_blank"
          rel="noreferrer noopener"
          className="sky-text-daylight text-bborange underline">
          www.crownfibre.govt.nz
        </a>{' '}
        to find out more.
      </div>
    ) : null;
  } else if (!(isAuthenticated && authTuiDiffersFromSelected()) || selectedTuiAddress === '') {
    return (
      <div className="sky-h6-reg py-3 sky-text-white">
        See if fibre is available at {isAuthenticated ? 'another address' : 'your place'}{' '}
      </div>
    );
  } else {
    return <div></div>;
  }
};

export { FibreDescription };
