export enum AppRoutes {
  Home = '/',
  Broadband = '/broadband',
  TV = '/tv',
  Checking = '/checking',
  OrderSummary = '/order-summary',
  Checkout = '/checkout',
  Confirmation = '/confirmation',
  NotFound = '/notfound',
  NetworkStatus = '/network-status',
  Setup = '/set-up',
  Offers = '/offers',
}
export const BroadbandWebTitle: string = 'Sky Broadband – Fibre Plans Made for Entertainment';
