import { formatMoneyWithoutSign, T_Product } from '@sky-tv-group/shared';
import React from 'react';

export interface ILineProductItemProps {
  product: T_Product;
}
const LineProductItem: React.FC<ILineProductItemProps> = ({ product, children }) => {
  return (
    <div className="flex flex-wrap mx-6 lg:mx-0 border-b last:border-b-0 py-3 my-3">
      <div className="w-full lg:w-1/2 flex items-center mb-4 lg:mb-0">
        <div className="text-navy">
          <h4 className="sky-h4 md:sky-h4-reg font-bold py-2">{product.name}</h4>
          <p className="w-full sky-h5 md:sky-h7 text-gray-darker pr-6">{product.description}</p>
        </div>
      </div>

      <div className="w-full lg:w-1/2 flex justify-between items-end lg:items-center">
        <div>
          <h4 className="md:sky-h4-reg sky-h4 pb-3 font-bold text-navy">
            <span>$</span>
            {formatMoneyWithoutSign(product?.priceIncTax)}
            <span className="text-xl font-normal">/mth</span>
          </h4>
        </div>
        <div>
          <div className="flex justify-center lg:justify-around text-center">{children}</div>
        </div>
      </div>
    </div>
  );
};

export { LineProductItem };
