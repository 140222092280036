import React, { FC, useEffect, useRef, useState } from 'react';
import { Box, T_Product } from '@sky-tv-group/shared/src/types';
import {
  boxUpgradeModalCopy,
  categoryIds,
  formatMoneyTwoDecimalPlaces,
  formatProduct,
  isNonNil,
  newSkyBoxImportantInfo,
  productSkuIds,
  SPLITIO_KEY,
  useAddUpdateBox,
  useboxesStore,
  useEligibilityOffers,
  useIdentifyRecording,
  useLockBodyScroll,
  useSplitIO,
  newSkyBoxInfoURL,
  skyPodInfoURL,
  useCoupon,
  SKY_REWARD_TEMPLATE_TYPE,
  useCouponStore,
  useProductStore,
  useProductInCart,
  useToastContainer,
  useHandleRemove,
  KonakartService,
  useManagePackage,
  MyAccountSWR,
  BFriday,
  fusionSKU,
  ServiceStatus,
  BoxTypes,
  NSB_SKYPOD_MONTHLY_AND_ONEOFF_FEE_ON_US,
} from '@sky-tv-group/shared';
import {
  ArrowRightIcon,
  Checked,
  HardDrive,
  InternetSymbol,
  LoyaltyDiscount,
  MonthlyPayment,
  OneOffPayment,
  RecordingIcon,
  SpeechBubble,
  Unchecked,
} from '../icon';
import { ICEBoxCard } from '../box-line-items/ICEBoxCard';
import { ICEBoxCardSelectable } from '../box-line-items/ICEBoxCardSelectable';
import { Button } from '../button';
import { CurrentAddress } from '../address/CurrentAddress';
import AnimatedModal from '../AnimatedModal';
import { TechInstallOptions } from './TechInstallOptions';
import { MarketingRectangle } from '../arrow-marketing/MarketingRectangle';
import { SkyRewardsTemplate } from '../arrow-marketing/NewMarketingCardTemplates';
import { CarouselContainer } from '../carousel/CarouselContainer';
import { SkyBoxDescription } from '../SkyBoxDescription';
import { useHistory } from 'react-router-dom';
import { CheckIcon } from '../icon';
import { SourceJourney } from '../SkyBoxPrimary';

export interface BoxModalConfirmParams {
  // selected product SKU
  selected: string;
  // box options to add
  products: T_Product[];
  // existing product
  editing: Box;
  // upgrade to box
  upgrading?: Box;
  // existing and upgrading box's products to remove
  removeProducts?: T_Product[];
}

export interface BoxModalCancelParams {
  // the box to upgrade
  upgrading: Box;
  // box options to add back
  products: T_Product[];
  // additional products to remove not in the Box.products for example: tech-install
  removeProducts: T_Product[];
}

export interface BoxModalProps {
  // Customer address
  address: string;

  // The existing box we are editing or upgrading
  editingBox: Box;

  // The konakart product of the editing or upgrading box
  editingProduct: T_Product;

  // Existing box upgrade options
  upgradeOptions: T_Product[];

  // If tech install was selected
  techInstallSelected: boolean;

  // Callback to either add or change the existing box
  confirm?: (params: BoxModalConfirmParams) => void;

  // Cancel the multiroom
  cancel?: (params: BoxModalCancelParams) => void;

  // useModal() hook toggle function, should be already handled in
  toggleModal: () => void;

  //myAccountSWR for review order function
  myAccountSWR?: MyAccountSWR;

  //konakart service for review order function
  konakartService?: KonakartService;
}

export const isArrowBox = (sku: string) => {
  return sku.includes(productSkuIds.arrowBox.primary) || sku.includes(productSkuIds.arrowBoxBlack.primary);
};

/**
 * This modal is used when editing or upgrading an existing box Primary or Multiroom
 *
 * @param param0
 * @returns */ export const BoxModal: FC<BoxModalProps> = ({
  address,
  editingBox,
  editingProduct,
  upgradeOptions,
  techInstallSelected,
  confirm,
  cancel,
  toggleModal,
  myAccountSWR,
  konakartService,
}) => {
  const [newSkyBoxCSGEnabled] = useSplitIO(SPLITIO_KEY.SKYWEB_NEW_SKY_BOX_CSG);
  const [technicianEnabled] = useSplitIO(SPLITIO_KEY.SKYWEB_ARROW_UPGRADE_TECHNICIAN);
  const [showDelayedArrowInstallation] = useSplitIO(SPLITIO_KEY.SKYWEB_SHOW_DELAYED_ARROW_INSTALLATION);

  const {
    recording: arrowRecording,
    upFrontRecording,
    newSkyBoxWhite,
    newSkyBoxOneOffFee,
    newSkyBoxTechVisit,
    newSkyBoxUpfront,
    newSkyBoxMonthly,
    multiroomFee,
    noRecording,
    skyPod,
    skyPodOneOffFee,
  } = useAddUpdateBox();

  const { getRecording } = useIdentifyRecording();
  const { getUpgradingBoxFromOccurenceNo } = useboxesStore();

  const upgradeBox = getUpgradingBoxFromOccurenceNo(editingBox?.occurence?.occurrenceNumber || '');
  const upgradeProduct = upgradeBox?.products.find(
    b => b.categoryId === categoryIds.box || b.sku === productSkuIds.arrowBoxRecording.primary
  );

  // check if Sky Technician One-off Fee is already added in product(s) store
  const products = useProductStore(s => s.products);
  const hasSkyTechnicianOneoffFeeIncluded = products
    .filter(p => p.quantityInCart > 0 && p.serviceStatus !== ServiceStatus.PendingInstall)
    ?.some(p => p.sku === productSkuIds.ArrowTechVisit.primary);

  const boxRecordingFunctionality: T_Product | undefined = getRecording(editingBox.occurence);
  const boxHasRecording =
    boxRecordingFunctionality && boxRecordingFunctionality.sku !== productSkuIds.noRecording.primary;
  const isEligibleForFreeRecording = boxRecordingFunctionality?.sku === productSkuIds.upfrontRecording.primary;

  const isUpgradingArrow = editingBox?.serviceCode?.includes(productSkuIds.arrowBox.primary);

  const isEditingArrow = upgradeProduct?.sku.includes(productSkuIds.arrowBox.primary);
  const oneOffFeeSelected = upgradeBox?.products.some(p => p.sku === productSkuIds.arrowUpfrontBoxFee.primary);
  const chosenOneoffWithOffer = upgradeBox?.products.find(
    p => p.sku === productSkuIds.arrowUpfrontBoxFee.primary && p.price0 !== p.priceIncTax
  );

  const hasRecordingSelected =
    upgradeBox?.products.some(p => p.sku === productSkuIds.arrowBoxRecording.primary) ||
    upgradeBox?.products.some(p => p.sku === productSkuIds.upfrontRecording.primary) ||
    upgradeBox?.products.some(p => p.sku === productSkuIds.recording1TB.primary);

  useLockBodyScroll();

  // selected options states
  const [selectedProductSKU, setSelectedProductSKU] = useState(upgradeProduct?.sku ?? '');
  const [arrowRecordingSelected, setArrowRecordingSelection] = useState(
    isEditingArrow || isUpgradingArrow ? hasRecordingSelected : false
  );
  const [arrowTechVisitSelected, setArrowTechVisitSelection] = useState(techInstallSelected ?? false);
  const [paymentOneoff, setPaymentOneoff] = useState(isEditingArrow ? (oneOffFeeSelected ? true : false) : true);

  const isArrowSelected = selectedProductSKU.includes(productSkuIds.arrowBox.primary);
  const isPodSelected = selectedProductSKU.includes(productSkuIds.skyPod.primary);

  // eligibilty stuff
  const discountedBoxFeeIfAvailable = useEligibilityOffers().discountedBoxFeeIfAvailable();
  const currentUserArrowLoyaltyOfferDetails = useEligibilityOffers().currentUserArrowLoyaltyOfferDetails();
  const rewardTemplateType: SKY_REWARD_TEMPLATE_TYPE | null = useCoupon().couponRewardTemplateType(
    currentUserArrowLoyaltyOfferDetails.coupon?.couponCode
  );
  const { anyArrowLoyaltyOffers, newSkyBoxVisible } = useEligibilityOffers();
  const { coupons } = useCouponStore();
  // check if Arrow Loyalty Offers coupons is already in store or product(s) is subscribed
  const isArrowLoyaltyOffersSubscribed = (coupons ?? [])?.some(
    c => c?.couponCode === currentUserArrowLoyaltyOfferDetails?.coupon?.couponCode
  );
  const isSkyPodAvailable = upgradeOptions.find(prod => prod.sku === productSkuIds.skyPod.primary);
  const isSkyPodSelected = selectedProductSKU === productSkuIds.skyPod.primary;
  const enablePodTechVisit = editingBox?.serviceCode === productSkuIds.skyPod.primary && isArrowSelected;
  const paymentOptionsRef = useRef<HTMLInputElement>(null);

  //used for hiding monthly and one off fee
  const [acqFusionBoxHide] = useSplitIO(SPLITIO_KEY.SKYWEB_ACQ_HIDE_MNTH_ONEOF_CHRGE);
  const [pageState, setPageState] = useState(1);
  useEffect(() => {
    if ((isArrowSelected || isSkyPodSelected) && paymentOptionsRef.current && !upgradeBox) {
      paymentOptionsRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArrowSelected, isSkyPodSelected]);

  // prevent arrow recording from being added if mySky is selected rather than arrow
  useEffect(() => {
    if (!isArrowSelected && !isUpgradingArrow) {
      setArrowRecordingSelection(false);
      setArrowTechVisitSelection(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProductSKU]);

  // returns konakart products based on selected options
  const getSelectedBoxProducts = () => {
    const products = [
      // if fusion is upgraded to arrow
      ...(isArrowSelected ? [newSkyBoxWhite!] : []),

      // if fusion is upgraded to pod
      ...(isSkyPodSelected ? [skyPod!, skyPodOneOffFee!] : []),

      // if arrow box is upgraded to recording, or fusion box is upgraded to arrow and already
      // has recording capability
      ...(!isSkyPodSelected && (arrowRecordingSelected || boxHasRecording)
        ? isEligibleForFreeRecording
          ? [upFrontRecording!]
          : [arrowRecording!]
        : []),

      // when tech visit was selected, only applies to fusion to arrow upgrades
      ...(arrowTechVisitSelected && !hasSkyTechnicianOneoffFeeIncluded ? [newSkyBoxTechVisit!] : []),

      // if arrow is selected, we pick what payment option the customer selects
      ...(isArrowSelected ? (paymentOneoff ? [newSkyBoxUpfront!] : [newSkyBoxMonthly!]) : []),

      // if a fusion box is upgraded - we don't add another multiroom fee
      // but if a fusion is upgraded to arrow and is not primary - we add a multiroom fee to show in running totals
      ...((!isArrowSelected && !isSkyPodSelected) || upgradeBox?.primary || editingBox?.primary ? [] : [multiroomFee]),

      // if a fusion box is upgraded to my sky
      ...(!isArrowSelected && !isSkyPodSelected && !isUpgradingArrow
        ? !arrowRecordingSelected && !boxHasRecording
          ? [arrowRecording!]
          : []
        : []),

      // if arrow is selected but with no recording and existing box has no recording functionality
      ...(isArrowSelected && !arrowRecordingSelected && !boxHasRecording ? [noRecording!] : []),
    ];

    return products.filter(isNonNil).map(formatProduct);
  };

  // returns products that needs to be removed from cart when canceling upgrades
  const cancelSelectedProducts = () => {
    const products = upgradeBox ? upgradeBox.products : [];
    if (techInstallSelected && newSkyBoxTechVisit && !arrowTechVisitSelected) {
      products.push(newSkyBoxTechVisit);
    }
    return products;
  };

  // returns the products that needs to be removed from cart when upgrading boxes
  const getRemovedProducts = () => {
    // if fusion to arrow or fusion to pod upgrades, remove all fusion products
    if (isArrowSelected || isPodSelected) return editingBox.products;
    if (arrowRecordingSelected) {
      return editingBox.products?.filter(p => p.sku === productSkuIds.noRecording.primary);
    }
  };

  const changed =
    upgradeProduct?.sku !== selectedProductSKU ||
    (isArrowSelected && (hasRecordingSelected !== arrowRecordingSelected || oneOffFeeSelected !== paymentOneoff)) ||
    !!techInstallSelected !== arrowTechVisitSelected;

  const slickSettings = {
    autoSlidesToShow: true,
    variableWidth: true,
    infinite: false,
    arrows: false,
    dots: false,
    customClassName: 'custom-carousel slick-slide-px-r-20 slick-slide-px-r-30  -mr-10vw md:mr-0',
    responsiveBlock: [],
  };

  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);

  let history = useHistory();

  //Get the subscribed music
  const { productsInCart } = useProductInCart();
  const musicInCart = productsInCart?.find(p => p.product.sku === productSkuIds.skyDigitalMusic.primary);

  //Get the skymusic name.
  const skyMusic: T_Product = useProductStore(s => {
    return s.products.find(p => p.sku === productSkuIds.skyDigitalMusic.primary);
  })!;

  const [toastid, settoastid] = useState('');
  const { addToast } = useToastContainer();

  //CurrentlySubscribed products

  const { analyticsCheckoutStarted, currentlySubscribedProducts } = useManagePackage(myAccountSWR!);
  const digiMusicSubscribed = currentlySubscribedProducts.find(p => p.sku === productSkuIds.skyDigitalMusic.primary);

  //handle to remove the music channel.
  const handleRemove = useHandleRemove(konakartService!);
  let boxes = useboxesStore(s => s.boxes);
  const subscribedBoxes = boxes?.filter(b => b.boxType === 'SUBSCRIBED');
  const upgradeBoxes = boxes?.filter(b => b.boxType === 'UPGRADE');
  const newlyAddedBoxes = boxes?.filter(b => b.boxType === 'NEW');

  //music toast

  const musicToast = (hasPod: boolean, hasArrowBox: boolean) => {
    if (!toastid) {
      let name = `${hasPod && hasArrowBox ? 'Both the ' : 'The'}${hasArrowBox ? ' new Sky Box' : ''}${
        hasPod && hasArrowBox ? ' and ' : ''
      }${hasPod ? ' Sky Pod' : ''}${hasPod && hasArrowBox ? ' do' : ' does'}`;
      if (!digiMusicSubscribed) {
        const toastID = addToast({
          title: 'Sky Digital Music channels',
          message: ` ${name}  not support ${skyMusic.name} channels, however, there are music apps available (e.g. Spotify) on the Apps page. `,
          type: 'error',
          onClose: async () => {
            await handleRemove(skyMusic);
            settoastid('');
          },
          closeText: `Remove ${skyMusic.name} from cart`,
        });
        settoastid(toastID);
      } else {
        const toastID = addToast({
          title: 'Sky Digital Music channels',
          message: ` ${name}  not support ${skyMusic.name} channels. Contact our call centre if you wish to remove this from your subscription. `,
          type: 'warning',
          onClose: () => {
            // settoastid('');
          },
          closeText: `Ok`,
        });
        settoastid(toastID);
      }
    }
  };
  // offer banner should always be visible until customer reaches the max limit
  const isBoxReachesMaxLimit =
    (boxes?.filter(b => b?.boxType === BoxTypes.NEW || b.boxType === BoxTypes.UPGRADE)?.length ?? 0) >= 5;

  const showOfferBanner = !(currentUserArrowLoyaltyOfferDetails?.coupon?.couponCode ===
  NSB_SKYPOD_MONTHLY_AND_ONEOFF_FEE_ON_US
    ? isBoxReachesMaxLimit
    : isArrowLoyaltyOffersSubscribed);

  const scrollRef = useRef(null) as any;
  return (
    <AnimatedModal isShown={true} toggleModal={toggleModal} isBoxModal={true}>
      <div
        className="mx-5  mt-12 md:px-5 lg:px-20 text-navy flex flex-col  last:mb-12 justify-center items-center"
        ref={scrollRef}>
        {pageState !== 1 && (
          <div className="min-w-full">
            <button
              className="flex flex-row text-blue-pure pb-4"
              onClick={() => {
                acqFusionBoxHide ? setPageState(s => s - 2) : setPageState(s => s - 1);
              }}>
              <div className="mr-1">
                <ArrowRightIcon />
              </div>
              <div className="text-sky-blue sky-h6-reg font-medium">
                {pageState === 2
                  ? 'Go back to upgrade options'
                  : pageState === 3 && acqFusionBoxHide
                  ? 'Go back to upgrade options'
                  : 'Go back to payment options'}
              </div>
            </button>
          </div>
        )}
        {upgradeBox && pageState === 1 && (
          <div className="min-w-full">
            <h2 className="sky-h5-black md:sky-h5-black  pb-4">Edit Upgrade</h2>
          </div>
        )}
        <div className="min-w-full opacity-50 pb-4">
          Step {acqFusionBoxHide && pageState === 3 ? pageState - 1 : pageState}{' '}
          {(acqFusionBoxHide || !boxHasRecording) && pageState === 3 ? '(optional)' : ''}
        </div>
        {/* Upgrade Options */}
        {!isUpgradingArrow && pageState === 1 && (
          <div className="min-w-full">
            <div>
              <h2 className="sky-h5-black md:sky-h5-black  pb-4">How would you like to upgrade your device?</h2>
            </div>
            <div>
              <p>
                You have selected your current{' '}
                <span className="font-bold">
                  {editingBox.name}: Smartcard no. {editingBox?.occurence?.serialNumber}
                </span>{' '}
                to upgrade{' '}
              </p>
            </div>
            <div
              className="flex flex-row gap-4 md:gap-4 lg:gap-8 overflow-x-auto mt-4 w-96 md:min-w-full lg:min-w-full overflow-y-hidden"
              style={{ scrollSnapType: 'x mandatory' }}>
              {upgradeOptions?.map(upgradeOption => {
                return (
                  /* Display New Sky Box based on flag. */
                  upgradeOption &&
                  (!isArrowBox(upgradeOption.sku) || newSkyBoxVisible) && (
                    <ICEBoxCardSelectable
                      editingBox={upgradeBox}
                      product={upgradeOption}
                      handleProductSelection={setSelectedProductSKU}
                      isProductSelected={upgradeOption.sku === selectedProductSKU}
                    />
                  )
                );
              })}
            </div>
            {isArrowSelected && showDelayedArrowInstallation ? (
              <p className="pt-4">
                Due to the popularity of the new Sky Box there may be some delay in processing your order and shipping
                your device. We will get it on its way to you as soon as possible and will notify you when it is being
                shipped.
              </p>
            ) : null}

            <div className="flex flex-col pt-8">
              {(isArrowSelected || (isSkyPodAvailable && isSkyPodSelected)) && (
                <div className="flex flex-col" ref={paymentOptionsRef}>
                  <div className="sky-h5-black min-w-full pb-4">
                    <h1 className="text-2xl">{`Things to know about your ${
                      selectedProductSKU === '624' ? ' new Sky Box' : 'Sky Pod'
                    }`}</h1>
                  </div>
                  <SkyBoxDescription
                    isSkyPodSelected={isSkyPodSelected}
                    boxHasRecording={boxHasRecording}
                    isArrowSelected={isArrowSelected}
                    source={SourceJourney.WEB_ACCOUNT}
                  />
                </div>
              )}
            </div>
            {isArrowSelected && newSkyBoxCSGEnabled && (
              <div className="flex pt-5">
                <span>
                  <SpeechBubble />
                </span>
                <span className="ml-3 sky-h7-reg md:sky-h7-reg">
                  {newSkyBoxImportantInfo.satisfactionGuarantee} Click here for full{' '}
                  <a className="text-blue-pure" href="https://sky.co.nz/csg-terms">
                    T&Cs
                  </a>
                </span>
              </div>
            )}
          </div>
        )}

        {(isArrowSelected || isUpgradingArrow) && pageState !== 1 && (
          <>
            {!isUpgradingArrow && (
              <>
                <div
                  id="main-container"
                  className={`${
                    acqFusionBoxHide ? 'hidden md:flex' : 'flex'
                  } flex-row items-start gap-10 p-0 mb-12 min-w-full `}>
                  {pageState === 2 && (
                    <div
                      id="payment-container"
                      className={`${
                        acqFusionBoxHide ? 'hidden' : 'flex'
                      } flex-col items-start gap-4 pt-8 w-564px h-503px scrollNone overflow-x-scroll`}>
                      <h2 className="sky-h5-black md:sky-h5-black pb-4">Choose the payment option to suit you </h2>
                      <div className="flex">
                        <p className="sky-h7-reg md:sky-h7-reg w-540">{boxUpgradeModalCopy.paymentOptionsCopy}</p>
                      </div>
                      <div className="flex flex-row items-start gap-8 pt-4 h-363px w-800px">
                        <div
                          className={`payment-options-card ${paymentOneoff ? 'checked' : undefined}`}
                          onClick={() => {
                            setPaymentOneoff(true);
                          }}>
                          {rewardTemplateType === SKY_REWARD_TEMPLATE_TYPE.REWARD &&
                            (chosenOneoffWithOffer || discountedBoxFeeIfAvailable.isDiscounted) && (
                              <MarketingRectangle
                                mRectColor={
                                  'linear-gradient(270deg, #9100D4 -5.35%, #9100D4 -0.07%, #9100D4 57.93%, #9100D4 126.47%)'
                                }
                                mRectTag={
                                  currentUserArrowLoyaltyOfferDetails?.coupon?.couponCode ===
                                  BFriday.BFridayUpgarade.cCode
                                    ? 'Special offer'
                                    : `You save $${
                                        chosenOneoffWithOffer
                                          ? chosenOneoffWithOffer.price0 - chosenOneoffWithOffer.priceIncTax
                                          : discountedBoxFeeIfAvailable.originalPrice -
                                            discountedBoxFeeIfAvailable.price
                                      }`
                                }
                                mRectTagClasses={'text-white text-center sky-h5-bold py-2 pl-3 pr-12'}
                              />
                            )}
                          <button className="absolute top-0 right-0" style={{ marginTop: '10px', marginRight: '10px' }}>
                            {paymentOneoff ? <Checked /> : <Unchecked />}
                          </button>
                          <div className="content-wrapper">
                            {chosenOneoffWithOffer || discountedBoxFeeIfAvailable.isDiscounted ? (
                              <LoyaltyDiscount />
                            ) : (
                              <OneOffPayment />
                            )}
                            <p
                              className="inline-flex flex-row text-midnight items-center sky-h5-black"
                              style={{ marginTop: '15px' }}>
                              {'One-off Charge'}
                            </p>
                            <p
                              className="inline-flex flex-row text-midnight items-center sky-h6 mx-2 text-center"
                              style={{ marginTop: '10px' }}>
                              {newSkyBoxUpfront?.description}
                            </p>
                            {rewardTemplateType === SKY_REWARD_TEMPLATE_TYPE.REWARD &&
                            (chosenOneoffWithOffer || discountedBoxFeeIfAvailable.isDiscounted) ? (
                              <div className="text-black text-center" style={{ marginTop: '50px' }}>
                                <small className="align-top sky-sub sky-text-red line-through">
                                  ${formatMoneyTwoDecimalPlaces(discountedBoxFeeIfAvailable?.originalPrice ?? 200)}
                                </small>
                                <small className="sky-h7-black align-top" style={{ fontSize: '8px' }}>
                                  $
                                </small>
                                <span className="sky-h5-bold inline-block">
                                  {' '}
                                  {formatMoneyTwoDecimalPlaces(
                                    chosenOneoffWithOffer
                                      ? chosenOneoffWithOffer.priceIncTax
                                      : discountedBoxFeeIfAvailable.price
                                  )}
                                </span>
                                <small className="align-bottom sky-sub">/one-off charge</small>
                              </div>
                            ) : (
                              <div className="text-black text-center" style={{ marginTop: '10px' }}>
                                <small className="sky-h7-black align-top" style={{ fontSize: '8px' }}>
                                  $
                                </small>
                                <span className="sky-h5-bold inline-block">
                                  {' '}
                                  {formatMoneyTwoDecimalPlaces(
                                    chosenOneoffWithOffer
                                      ? chosenOneoffWithOffer.priceIncTax
                                      : discountedBoxFeeIfAvailable.price
                                  )}
                                </span>
                                {discountedBoxFeeIfAvailable.isDiscounted && (
                                  <>
                                    <br></br>
                                    <small className="align-bottom">
                                      (price without offer ${discountedBoxFeeIfAvailable.originalPrice}){' '}
                                    </small>
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        </div>

                        <div
                          className={`payment-options-card ${!paymentOneoff ? 'checked' : undefined}`}
                          onClick={() => {
                            setPaymentOneoff(false);
                          }}>
                          <button className="absolute top-0 right-0" style={{ marginTop: '10px', marginRight: '10px' }}>
                            {!paymentOneoff ? <Checked /> : <Unchecked />}
                          </button>
                          <div className="content-wrapper">
                            <MonthlyPayment />
                            <p
                              className="inline-flex flex-row text-midnight items-center sky-h5-black"
                              style={{ marginTop: '15px' }}>
                              {'Pay Monthly'}
                            </p>
                            <p
                              className="inline-flex flex-row text-midnight items-center sky-h6 mx-2 text-center"
                              style={{ marginTop: '10px' }}>
                              {newSkyBoxMonthly?.description}
                            </p>
                            <div className="text-black" style={{ marginTop: '10px' }}>
                              <small className="sky-h7-black align-top" style={{ fontSize: '8px' }}>
                                $
                              </small>
                              <span className="sky-h5-bold inline-block">
                                {' '}
                                {formatMoneyTwoDecimalPlaces(newSkyBoxMonthly?.price0 || 0)}
                              </span>
                              <small className="align-bottom sky-sub">/mth</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Add My Sky Recording  Desktop Screen*/}
                  {pageState === 3 && (
                    <div className="hidden md:flex flex-col items-start gap-2   w-280px flex-grow">
                      <h2 className="sky-h5-black md:sky-h5-black ">Add My Sky Recording to your new Sky Box</h2>
                      <div className="flex">
                        {!boxHasRecording ? (
                          <p className="sky-h7-reg md:sky-h7-reg py-2 text-sm">
                            With My Sky you can pause, rewind and record live TV. Never miss an episode again with our
                            smart series link feature.
                          </p>
                        ) : (
                          ''
                        )}
                      </div>
                      <div
                        className={`min-w-full flex justify-between items-center rounded-lg px-4 py-3 ${
                          boxHasRecording
                            ? 'border-l-8'
                            : !arrowRecordingSelected
                            ? 'border-2 border-gray-copy'
                            : 'border-2 border-gray-dark'
                        }`}
                        style={boxHasRecording ? { borderColor: ' #00B1EB', background: '#F0FBFE' } : {}}>
                        {boxHasRecording ? (
                          <>
                            <p>
                              <b>You currently have My Sky recording on your Sky subscription.</b> Your monthly charge
                              will not change but your recording storage will increase to 1TB on the new Sky Box.
                            </p>
                          </>
                        ) : (
                          <>
                            {' '}
                            <p className="font-black text-sm">No Recording</p>
                            <button
                              onClick={() => {
                                setArrowRecordingSelection(!arrowRecordingSelected);
                              }}>
                              {arrowRecordingSelected ? <Unchecked /> : <Checked />}
                            </button>
                          </>
                        )}
                      </div>
                      {boxHasRecording ? (
                        ''
                      ) : (
                        <div className="flex flex-row items-start gap-8 pt-4 min-w-full">
                          <div
                            className={` flex flex-row-reverse justify-between items-start min-w-full border-2 rounded-md relative hover:border-gray-copy ${
                              arrowRecordingSelected ? 'border-gray-copy' : 'border-gray-dark'
                            }`}>
                            <button
                              className={'' + (boxHasRecording ? ' cursor-default' : '')}
                              style={{ marginTop: '10px', marginRight: '10px' }}
                              onClick={
                                boxHasRecording
                                  ? undefined
                                  : () => {
                                      // Dont allow user the unselect recording if upgrading from mysky box
                                      if (!boxHasRecording) {
                                        setArrowRecordingSelection(!arrowRecordingSelected);
                                        if (isUpgradingArrow) {
                                          setSelectedProductSKU(
                                            !arrowRecordingSelected ? arrowRecording?.sku ?? '' : ''
                                          );
                                        }
                                      }
                                    }
                              }>
                              {arrowRecordingSelected || boxHasRecording ? <Checked /> : <Unchecked />}
                            </button>
                            <div className="flex flex-col px-4 pt-3 items-start gap-2">
                              <p className="font-bold">Add My Sky Recording</p>
                              <div className="text-black ">
                                <small className="sky-sub align-top" style={{ fontSize: '10px' }}>
                                  $
                                </small>
                                <span
                                  className="sky-h5-bold inline-block text-5xl font-black relative "
                                  style={{ top: '-0.5rem' }}>
                                  {formatMoneyTwoDecimalPlaces(
                                    isEligibleForFreeRecording ? 0 : arrowRecording?.price0!
                                  ).substring(0, 2)}
                                </span>
                                <small className="align-top sky-sub">00</small>
                                <small className="relative  sky-sub" style={{ left: '-1.25rem', top: '-0.5rem' }}>
                                  /mth
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {/* Add My Sky Recording  Mobile Screen*/}
                {pageState === 3 && (
                  <div className=" md:hidden lg:hidden flex flex-col items-start gap-2   flex-grow">
                    <h2 className="sky-h5-black md:sky-h5-black ">Add My Sky Recording to your new Sky Box</h2>
                    <div className="flex">
                      {!boxHasRecording ? (
                        <p className="sky-h7-reg md:sky-h7-reg py-2 text-sm">
                          With My Sky you can pause, rewind and record live TV. Never miss an episode again with our
                          smart series link feature.
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="flex flex-col overflow-x-auto min-w-full gap-4">
                      <div
                        className={`min-w-full flex justify-between items-center rounded-lg px-4 py-3 ${
                          boxHasRecording
                            ? 'border-l-8'
                            : !arrowRecordingSelected
                            ? 'border-2 border-gray-copy'
                            : 'border-2 border-gray-dark'
                        }`}
                        style={boxHasRecording ? { borderColor: ' #00B1EB', background: '#F0FBFE' } : {}}>
                        {boxHasRecording ? (
                          <>
                            <p>
                              <b>You currently have My Sky recording on your Sky subscription.</b> Your monthly charge
                              will not change but your recording storage will increase to 1TB on the new Sky Box.
                            </p>
                          </>
                        ) : (
                          <>
                            {' '}
                            <p className="font-black text-sm">No Recording</p>
                            <button
                              onClick={() => {
                                setArrowRecordingSelection(!arrowRecordingSelected);
                              }}>
                              {arrowRecordingSelected ? <Unchecked /> : <Checked />}
                            </button>
                          </>
                        )}
                      </div>
                      {boxHasRecording ? (
                        ''
                      ) : (
                        <div
                          className={` border border-gray-dark rounded-md relative hover:border-gray-copy ${
                            arrowRecordingSelected ? 'border-gray-copy' : undefined
                          }`}>
                          <button
                            className={'absolute top-0 right-0' + (boxHasRecording ? ' cursor-default' : '')}
                            style={{ marginTop: '10px', marginRight: '10px' }}
                            onClick={
                              boxHasRecording
                                ? undefined
                                : () => {
                                    // Dont allow user the unselect recording if upgrading from mysky box
                                    if (!boxHasRecording) {
                                      setArrowRecordingSelection(!arrowRecordingSelected);
                                      if (isUpgradingArrow) {
                                        setSelectedProductSKU(!arrowRecordingSelected ? arrowRecording?.sku ?? '' : '');
                                      }
                                    }
                                  }
                            }>
                            {arrowRecordingSelected || boxHasRecording ? <Checked /> : <Unchecked />}
                          </button>
                          <div className="flex flex-col px-4 pt-3 items-start gap-2">
                            <p className="font-bold">Add My Sky Recording</p>
                            <div className="text-black" style={{ marginTop: '10px' }}>
                              <small className="sky-sub align-top" style={{ fontSize: '10px' }}>
                                $
                              </small>
                              <span
                                className="sky-h5-bold inline-block text-5xl font-black relative "
                                style={{ top: '-0.5rem' }}>
                                {formatMoneyTwoDecimalPlaces(
                                  isEligibleForFreeRecording ? 0 : arrowRecording?.price0!
                                ).substring(0, 2)}
                              </span>
                              <small className="align-top sky-sub">00</small>
                              <small className="relative  sky-sub" style={{ left: '-1.25rem', top: '-0.5rem' }}>
                                /mth
                              </small>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}

            {/* Tech Install */}
            {!isUpgradingArrow && technicianEnabled && !enablePodTechVisit && pageState === 3 && (
              <div className="md:w-920px border-t mt-4">
                <div className="min-w-full opacity-50 py-4">
                  Step {acqFusionBoxHide ? pageState : pageState + 1} (optional)
                </div>
                <h2 className="sky-h5-black md:sky-h5-black  pb-6">Sky Technician </h2>
                <div className="flex">
                  <p className="sky-h7-reg md:sky-h7-reg text-sm">
                    {
                      'Get one of our Sky Technicians to visit your home and do the set up for you. Or you can self-install as you’ve already got Sky. Your new Sky Box is super simple to set up on your own, and it’s a piece of cake to use too. Refer to the Quick Start Guide included with your new Sky Box or check out our online'
                    }{' '}
                    <a
                      href="https://help.sky.co.nz/s/article/set-up-your-New-Sky-Box"
                      className=" sky-text-daylight underline">
                      self install video
                    </a>{' '}
                    {' in our help section.'}
                  </p>
                </div>
                {arrowTechVisitSelected && (
                  <CurrentAddress
                    address={address ? address : ''}
                    isBoxUpgrade={true}
                    containerClass="sky-h7-reg md:sky-h7-reg flex pt-4"></CurrentAddress>
                )}
                <div className="flex flex-col pt-4 gap-4 ">
                  <TechInstallOptions
                    arrowTechVisit={newSkyBoxTechVisit!}
                    arrowTechVisitSelected={arrowTechVisitSelected}
                    setArrowTechVisitSelection={v => setArrowTechVisitSelection(v)}
                  />
                </div>
              </div>
            )}
          </>
        )}

        {/* Upgrade NSB with Sky Recording */}
        {isUpgradingArrow && !boxHasRecording && pageState === 1 && (
          <>
            {/* Add My Sky Recording  Desktop Screen*/}
            <div className=" flex flex-col items-start min-w-full ">
              <h2 className="sky-h5-black md:sky-h5-black pt-8 pb-4">How would you like to upgrade your device?</h2>
              <p>
                You have selected your current{' '}
                <span className="font-bold">
                  {editingBox.name}: Smartcard no. {editingBox?.occurence?.serialNumber}
                </span>{' '}
                to upgrade{' '}
              </p>

              <div className="flex flex-row items-start gap-8 pt-4">
                <div
                  className={`w-280px mh-370px border border-gray-dark rounded-md relative hover:border-gray-copy ${
                    arrowRecordingSelected ? 'border-gray-copy' : undefined
                  }`}>
                  <div className="flex flex-col  items-center">
                    <img
                      src="https://static.sky.co.nz/sky/box/recording/my_sky.png"
                      alt="my_sky.png"
                      loading="lazy"></img>
                    <p
                      className="inline-flex flex-row text-midnight items-center sky-h5-black"
                      style={{ marginTop: '20px' }}>
                      {isArrowSelected || isUpgradingArrow ? 'My Sky recording' : '500GB Recording Storage'}
                    </p>
                    <p
                      className="inline-flex flex-row text-midnight items-center sky-h6 mx-2 text-center"
                      style={{ marginTop: '10px' }}>
                      Added to your current sky box
                    </p>
                    <div className="text-black mt-4">
                      <small className="sky-h7-black align-top" style={{ fontSize: '8px' }}>
                        $
                      </small>
                      <span className="sky-h5-bold inline-block">
                        {formatMoneyTwoDecimalPlaces(isEligibleForFreeRecording ? 0 : arrowRecording?.price0!)}
                      </span>
                      <small className="align-bottom sky-sub">/mth</small>
                    </div>
                    <Button
                      className="addboxbtn mt-4"
                      variant="primary"
                      colorMode={arrowRecordingSelected ? 'pink' : 'pure'}
                      icon={arrowRecordingSelected ? 'check' : undefined}
                      templateType={rewardTemplateType}
                      onClick={
                        boxHasRecording
                          ? undefined
                          : () => {
                              // Dont allow user the unselect recording if upgrading from mysky box
                              if (!boxHasRecording) {
                                setArrowRecordingSelection(!arrowRecordingSelected);
                                if (isUpgradingArrow) {
                                  setSelectedProductSKU(!arrowRecordingSelected ? arrowRecording?.sku ?? '' : '');
                                }
                              }
                            }
                      }>
                      {arrowRecordingSelected || boxHasRecording ? 'Added' : 'Add'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {selectedProductSKU &&
          (pageState === 3 ||
            selectedProductSKU === productSkuIds.arrowBoxRecording.primary ||
            selectedProductSKU === productSkuIds.skyPod.primary) &&
          confirm &&
          !upgradeBox && (
            <div className="flex addbox  border-t py-8 my-8 mr-4 items-stretch xl:flex-row-reverse md:flex-row-reverse min-w-full">
              <Button
                className="addboxbtn"
                variant="primary"
                colorMode="pure"
                icon={loadingConfirm ? 'loading' : undefined}
                templateType={rewardTemplateType}
                onClick={async () => {
                  const hasPodSelected = getSelectedBoxProducts().some(p => p.sku === productSkuIds.skyPod.primary); //sky-pod
                  const hasArrowBoxSelected = getSelectedBoxProducts().some(
                    p => p.sku === productSkuIds.arrowBox.primary
                  );
                  const hasskyBox =
                    getSelectedBoxProducts().some(p => fusionSKU.includes(p.sku)) &&
                    editingBox.products.some(p => p.sku === productSkuIds.skyBoxCharge.primary) &&
                    !hasArrowBoxSelected &&
                    !hasPodSelected; //sky-box
                  let remaingBoxes = subscribedBoxes
                    .filter(
                      box =>
                        !upgradeBoxes.some(subbox => subbox.upgradeFrom?.serialNumber === box.occurence?.serialNumber)
                    )
                    .filter(box => box.occurence?.serialNumber !== editingBox.occurence?.serialNumber);
                  let finalboxes = [...remaingBoxes, ...upgradeBoxes, ...newlyAddedBoxes];
                  let hasSkyBoxInRemaining = finalboxes.some(box =>
                    box.products.some(p => p.sku === productSkuIds.skyBoxCharge.primary)
                  );
                  let hasPodInRemaining = finalboxes.some(box =>
                    box.products.some(p => p.sku === productSkuIds.skyPod.primary)
                  );
                  let hasArrowBoxInRemaining = finalboxes.some(box =>
                    box.products.some(p => p.sku === productSkuIds.arrowBox.primary)
                  );
                  if (
                    !toastid &&
                    musicInCart &&
                    (hasPodInRemaining || hasPodSelected || hasArrowBoxInRemaining || hasArrowBoxSelected) &&
                    !(hasskyBox || hasSkyBoxInRemaining)
                  ) {
                    musicToast(hasPodInRemaining || hasPodSelected, hasArrowBoxInRemaining || hasArrowBoxSelected);
                  } else {
                    analyticsCheckoutStarted();
                    setLoadingConfirm(true);
                    //sleep the UI thread before the async method which freeze the app. Loading indicator to customer
                    setTimeout(() => {
                      setLoadingConfirm(false);
                      //toggleModal();
                      confirm({
                        selected: selectedProductSKU,
                        products: getSelectedBoxProducts(),
                        editing: editingBox,
                        upgrading: upgradeBox,
                        removeProducts: getRemovedProducts(),
                      });
                      history.push({ pathname: '/mp/checkout', state: { prevPath: history.location.pathname } });
                    }, 500);
                  }
                }}>
                Next: Review Order
              </Button>
              <Button colorMode="pure" variant="secondary" className="addboxbtn" onClick={toggleModal}>
                Cancel Upgrade
              </Button>
            </div>
          )}
        {(!selectedProductSKU ||
          (pageState !== 3 &&
            selectedProductSKU !== productSkuIds.arrowBoxRecording.primary &&
            productSkuIds.skyPod.primary !== selectedProductSKU)) &&
          confirm &&
          !upgradeBox && (
            <div className="flex addbox min-w-full border-t py-8 my-8 mr-4  items-stretch xl:flex-row-reverse md:flex-row-reverse">
              <Button
                className={`addboxbtn ${selectedProductSKU ? 'opacity-100' : 'opacity-0'}`}
                variant="primary"
                colorMode="pure"
                onClick={() => {
                  acqFusionBoxHide ? setPageState(s => s + 2) : setPageState(s => s + 1);
                  scrollRef?.current?.scrollIntoView();
                }}>
                Next
              </Button>
              <Button colorMode="pure" variant="secondary" className="addboxbtn" onClick={toggleModal}>
                Cancel Upgrade
              </Button>
            </div>
          )}
        {confirm && cancel && upgradeBox && (
          <div
            className="addbox min-w-full border-t py-8 my-8 mr-4 items-stretch xl:flex-row-reverse md:flex-row-reverse"
            style={{ maxWidth: '100%' }}>
            {!changed ? (
              <Button
                variant="primary"
                className="addboxbtn"
                templateType={rewardTemplateType}
                colorMode={'pure'}
                disabled={false}
                onClick={() => {
                  if (
                    pageState !== 3 &&
                    selectedProductSKU !== productSkuIds.skyPod.primary &&
                    selectedProductSKU !== productSkuIds.arrowBoxRecording.primary
                  ) {
                    acqFusionBoxHide ? setPageState(s => s + 2) : setPageState(s => s + 1);
                    scrollRef?.current?.scrollIntoView();
                  } else {
                    history.push({ pathname: '/mp/checkout', state: { prevPath: history.location.pathname } });
                  }
                }}>
                {pageState !== 3 &&
                selectedProductSKU !== productSkuIds.skyPod.primary &&
                selectedProductSKU !== productSkuIds.arrowBoxRecording.primary
                  ? 'Next'
                  : 'Next: Review Order'}
              </Button>
            ) : (
              <div className="editbox items-stretch xl:flex-row-reverse md:flex-row-reverse">
                {changed && pageState !== 3 && productSkuIds.arrowBox.primary === selectedProductSKU ? (
                  <Button
                    variant="primary"
                    className="addboxbtn"
                    templateType={rewardTemplateType}
                    colorMode={'pure'}
                    disabled={false}
                    onClick={() => {
                      acqFusionBoxHide ? setPageState(s => s + 2) : setPageState(s => s + 1);
                      scrollRef?.current?.scrollIntoView();
                    }}>
                    Next
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    colorMode={!changed || loadingCancel ? 'disabled' : 'pure'}
                    disabled={!changed || loadingCancel}
                    className="editboxbtn"
                    icon={loadingConfirm ? 'loading' : undefined}
                    templateType={rewardTemplateType}
                    onClick={() => {
                      setLoadingConfirm(true);
                      //sleep the UI thread before the async method which freeze the app. Loading indicator to customer
                      setTimeout(() => {
                        setLoadingConfirm(false);
                        toggleModal();
                        confirm({
                          selected: selectedProductSKU,
                          products: getSelectedBoxProducts(),
                          editing: editingBox,
                          upgrading: upgradeBox,
                          removeProducts: cancelSelectedProducts(),
                        });
                      }, 500);
                    }}>
                    Confirm Changes
                  </Button>
                )}
              </div>
            )}
            <div className="flex md:flex-row flex-col-reverse gap-4">
              <Button
                variant="secondary"
                className={`${loadingCancel ? 'cancel-upgrade-btn' : 'addboxbtn'} ${
                  loadingConfirm ? 'cancel-upgrade-disabled' : ''
                } ${!changed ? 'px-10' : ''} min-w-fit`}
                templateType={rewardTemplateType}
                colorMode={loadingConfirm ? 'disabled' : 'pure'}
                disabled={loadingConfirm}
                onClick={toggleModal}>
                {changed ? 'Discard changes' : 'Close'}
              </Button>
              <Button
                className={`${loadingCancel ? 'cancel-upgrade-btn' : 'addboxbtn'} ${
                  loadingConfirm ? 'cancel-upgrade-disabled' : ''
                } min-w-fit`}
                variant="secondary"
                templateType={rewardTemplateType}
                colorMode={loadingConfirm ? 'disabled' : 'pure'}
                disabled={loadingConfirm}
                icon={loadingCancel ? 'loading' : undefined}
                onClick={() => {
                  setLoadingCancel(true);
                  //sleep the UI thread before the async method which freeze the app. Loading indicator to customer
                  setTimeout(() => {
                    setLoadingCancel(false);
                    toggleModal();
                    cancel({
                      upgrading: upgradeBox,
                      products: isArrowSelected || isPodSelected ? editingBox.products : [],
                      removeProducts: cancelSelectedProducts(),
                    });
                  }, 500);
                }}>
                Cancel Upgrade
              </Button>
            </div>
          </div>
        )}
      </div>
    </AnimatedModal>
  );
};
