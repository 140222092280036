import React, { useEffect, useState } from 'react';
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { PlanSelector } from './PlanSelector';
import {
  BroadbandReady,
  T_Product,
  useSplitIO,
  SPLITIO_KEY,
  useCustomerStore,
  useOrderStore,
  productStoreApi,
  orderStoreApi,
  T_OrderProduct,
  useToastContainer,
  ServiceStatus,
  segment
} from '@sky-tv-group/shared';
import { OneMonthOnUs } from '@sky-tv-group/components';
import { StarterSelector } from './StarterSelector';
import history from '../../history';
import { useAuth0 } from '../../auth0/store';
import { useStore } from '../../store';
import { useChoosePlan } from '../../hooks/useChoosePlan';
import { CardPlanSelector, MoreChoicesForYouProduct } from './CardPlanSelector';
import { myAccountSWR, myAccountV2SWR } from '../../swr';
import { AppRoutes } from '../../constants';

import { v4 as uuidv4 } from 'uuid';
import { TermsAndConditions } from './TermsAndConditions';
import { kkService } from '../../services';

const ChoosePlan = () => {
  const { clearOrder } = useOrderStore();
  const { isAuthenticated } = useAuth0();
  const {
    guestPrequalData,
    authenticatedPrequalData,
    broadbandProductSelected,
    setBroadbandProductSelected,
    setIsBundle,
    selectedIsSkyStarterCustomer,
    customer,
    isUpdatingPlan,
  } = useStore();
  const [bbAndDTHAcquisitionEnabled] = useSplitIO(SPLITIO_KEY.SKYWEB_BROADBAND_AND_TV_ACQUISTION);
  const [showOneMonthOnUs] = useSplitIO(SPLITIO_KEY.SKYWEB_BB_ONE_MONTH_ON_US_ON);
  const [BBCreditOfferFlag] = useSplitIO(SPLITIO_KEY.SKYWEB_BB_CREDIT_OFFER);
  const [broadbandFilter, setBroadbandFilter] = useState<'BROADBAND_ONLY' | 'BROADBAND_WITH_SKY_TV'>(
    'BROADBAND_WITH_SKY_TV'
  );
  const { addToast } = useToastContainer();

  const {
    meshDevice,
    broadband,
    broadbandDiscounts,
    broadbandProductInCart,
    selectProduct,
    onStarterSelectChange,
    getSkyBroadbandAction,
  } = useChoosePlan();
  const {
    isCustomerNeedsAccountUpdate,
    isStarterActive,
    isBroadcastTier,
    accountIssue,
    hasActiveOrPendingBroadbandPackage,
  } = myAccountSWR.usePackages();
  const userInfo = myAccountV2SWR.useUserInformationGQL();
  const choices = myAccountV2SWR.useGetMoreChoicesForYou();

  const { sessionId, setSessionId, customerId } = useCustomerStore(s => ({
    sessionId: s.sessionId,
    setSessionId: s.setSessionId,
    customerId: s.kk,
  }));

  useEffect(() => {
    if (isAuthenticated) {
      setBroadbandFilter('BROADBAND_ONLY');
    }
  }, [isAuthenticated]);

  let broadbandProducts = (choices?.data?.productsCards ?? [])
    .filter(pkg => pkg.occurrenceType === 'Data')
    .filter(
      pkg =>
        !userInfo?.data?.billing?.products.some(
          product => product.serviceStatus === ServiceStatus.Active && product.sku === pkg.sku
        )
    );

  const availableBBProducts: MoreChoicesForYouProduct[] = broadbandProducts.reduce(
    (prev: MoreChoicesForYouProduct[], curr) => {
      const kkProduct = productStoreApi.getState().products.find(p => p.sku === curr.sku);
      if (!userInfo?.data.billing.broadbandOnlyAccount) {
        prev.push({
          product: curr,
          kkProduct,
        });
      }
      return prev;
    },
    []
  );

  const hasMeshDevice = meshDevice ? meshDevice.quantityInCart > 0 : false;
  const prequal = isAuthenticated ? authenticatedPrequalData : guestPrequalData;
  const isFibreAvailable = prequal
    ? prequal.getBroadbandReadyStatus().broadbandReadyStatus === BroadbandReady.FibreOK
    : false;

  const onSelectChangeHandler = (product: T_Product | undefined) => {
    if (product) {
      setBroadbandProductSelected(product);
    }
  };

  const removeAddOns = async (product: T_Product) => {
    try {
      let { order } = orderStoreApi.getState();

      // 1. get the orders we want to remove from order store
      const removableProducts = order?.orderProducts?.filter(
        p =>
          p.productId !== product?.id && // don't remove the product we are adding to cart
          p.productId !== 108 && // don't remove the "broadband-services" product
          p.productId !== 98 && // don't remove the Wifi Router
          p.productId !== 110 // don't remove the "One-off fee for Self Installation."
      ) as T_OrderProduct[] | undefined;

      let { products } = productStoreApi.getState();

      // 2. remove the products from the product store
      removableProducts?.forEach(p => {
        // 3. put the quantity for these products in cart back to 0
        products = products?.map(prod => {
          if (prod.id === p.productId && prod.id !== product?.id) {
            return { ...prod, quantityInCart: 0 };
          }
          return prod;
        });
      });

      return products;
    } catch (error) {
      addToast({
        message: 'Sorry, there was a problem selecting the plan. Please try again.',
        type: 'error',
        title: 'Alert',
      });
    }
  };

  const clearBBAddOns = async (product: T_Product) => {
    // calls the helper function to clear the broadband related add-ons from the order store and product store
    await removeAddOns(product)
      .then(products => {
        productStoreApi.setState({ products });
      })
      .then(() => {
        clearOrder(kkService, customerId);
      });
  };

  const onSelectPlan = async (product: T_Product, isBundle: boolean) => {
    // if the user is changing the plan, clear the broadband related add-ons from the order store and product store
    if (product?.id !== broadbandProductSelected?.id) {
      if (broadbandProductSelected?.id === 230) {
        //covers the edge case when booster is selected and then user changes the plan
        if (product.id !== 229) {
          //clear the broadband related add-ons from the order store and product store
          await clearBBAddOns(product);
        }
      } else if (broadbandProductSelected?.id === 228) {
        //covers the edge case when booster is selected and then user changes the plan
        if (product.id !== 227) {
          //clear the broadband related add-ons from the order store and product store
          await clearBBAddOns(product);
        }
      } else {
        //clear the broadband related add-ons from the order store and product store
        await clearBBAddOns(product);
      }
    }

    if (product) {
      setBroadbandProductSelected(product);
      setIsBundle(isBundle);
      if (broadbandProductInCart) {
        await selectProduct(product, isBundle);
      } else {
        getSkyBroadbandAction(product, isBundle);
      }

      // Whenever a user selects a bundle, generate a session ID for it if it does not exist yet.  This is to be used later for determining whether this will become an abandoned cart.
      if (!sessionId) {
        setSessionId(uuidv4());
      }
    }
    history.push(AppRoutes.Broadband);
  };

  // Enable card buttons ONLY if Fibre is available AND Account is GOOD
  const hasAccountProblems = isBroadcastTier || accountIssue || isCustomerNeedsAccountUpdate;
  const isUserAllowedToContinue = prequal
    ? isFibreAvailable && !hasAccountProblems && !hasActiveOrPendingBroadbandPackage
    : true;

  return (
    <div id="package-selector-div" className="sky-bg-gray-light w-full p-6">
      {showOneMonthOnUs && (
        <div className="flex flex-col justify-center items-center container m-auto">
          <OneMonthOnUs tAndCLink="https://www.sky.co.nz/offer-terms">
            <div className="ml-40 sm:ml-60">
              Get your <strong>first month on us</strong> when you sign up to any selected Sky Broadband plan, Sky TV
              plan or bundle, on a 12 month contract.{' '}
              <a href="https://www.sky.co.nz/offer-terms" className="underline">
                {' '}
                Offer T&Cs apply.
              </a>
            </div>
          </OneMonthOnUs>
        </div>
      )}

      {BBCreditOfferFlag
        ? null
        : // <div className="container m-auto mb-12">
          //   <a className="hidden md:block" href="https://www.sky.co.nz/offer-terms">
          //     <img src="https://static.sky.co.nz/sky/broadband/credit/bbcredit1.png" alt="" />
          //   </a>

          //   <a className="md:hidden block p-5" href="https://www.sky.co.nz/offer-terms">
          //     <img src="https://static.sky.co.nz/sky/broadband/credit/bbcredit2.png" alt="" />
          //   </a>
          // </div>
          null}
      {!isAuthenticated && (
        <div className="container m-auto">
          <StarterSelector
            isLoadingProduct={isUpdatingPlan}
            isActiveSkyStarter={selectedIsSkyStarterCustomer || isStarterActive}
            onSelectChange={val => {
              onStarterSelectChange(val);
              segment.toggleClicked('offers | Broadband', val ? "Yes" : "No", 'Radio')

              if (val) {
                setBroadbandFilter('BROADBAND_ONLY');
              }
            }}
            broadbandFilter={broadbandFilter}
            setBroadbandFilter={(val: 'BROADBAND_ONLY' | 'BROADBAND_WITH_SKY_TV') => setBroadbandFilter(val)}
          />
        </div>
      )}
      {bbAndDTHAcquisitionEnabled ? (
        <>
          <CardPlanSelector
            isActiveSkyStarter={selectedIsSkyStarterCustomer || isStarterActive}
            onSelectChange={onSelectPlan}
            isUserAllowedToContinue={isUserAllowedToContinue}
            canPurchaseBroadband={isFibreAvailable}
            broadbandProducts={availableBBProducts.filter(p => {
              if (broadbandFilter === 'BROADBAND_ONLY') {
                return p.product.type === 'Product';
              } else {
                return p.product.type === 'Bundle';
              }
            })}
          />
          <TermsAndConditions isActiveSkyStarter={selectedIsSkyStarterCustomer || !!isStarterActive} />
        </>
      ) : (
        <PlanSelector
          hasMeshDevice={hasMeshDevice}
          broadbandSelected={broadbandProductSelected}
          isNotOnA12MonthContract={!!customer}
          broadbandProducts={meshDevice ? [...broadband, meshDevice] : broadband}
          onSelectChange={onSelectChangeHandler}
          isLoadingProduct={isUpdatingPlan}
          selectedIsSkyCustomer={selectedIsSkyStarterCustomer}
        />
      )}
    </div>
  );
};

export { ChoosePlan };
