import {
  couponStoreApi,
  CouponTypeValue,
  customerStoreApi,
  NON_FIXED_TERM_BB_1_MONTH_ON_US_COUPON_ACQ,
  NON_FIXED_TERM_BB_1_MONTH_ON_US_COUPON,
  productSkuIds,
  productStoreApi,
  SPLITIO_KEY,
  T_Product,
  useCartContainer,
  useCustomerStore,
  useOrderStore,
  useSplitIO,
  couponCodes,
} from '@sky-tv-group/shared';
import { useAuth0 } from '../auth0/store';
import { kkService } from '../services';
import { useStore, clearSessionStorageState } from '../store';
import { v4 as uuidv4 } from 'uuid';

function useChoosePlan() {
  const [showOneMonthOnUs] = useSplitIO(SPLITIO_KEY.SKYWEB_BB_ONE_MONTH_ON_US_ON);

  const { isAuthenticated } = useAuth0();

  const cartType = isAuthenticated ? CouponTypeValue.Upgrade : CouponTypeValue.Acquisition;
  const {
    meshDevice,
    routerDevice,
    broadband,
    broadbandProductInCart,
    toggleProduct,
    applyProduct,
    starter,
    broadbandDiscounts,
    broadbandServices,
    broadbandOneOffFees,
    starterAdded,
    buyStarter,
    removeAllStarterDependentProducts,
    hasActiveStarter,
    swapMultipleProducts,
  } = useCartContainer(kkService, cartType, false);

  const {
    broadbandProductSelected,
    setBroadbandProductSelected,
    selectedIsSkyStarterCustomer,
    isUpdatingPlan,
    setIsUpdatingPlan,
    hasActiveOrPendingBroadbandPackage,
  } = useStore();

  const { customerId, sessionId, setSessionId, isStaffAccount } = useCustomerStore(s => ({
    customerId: s.kk,
    sessionId: s.sessionId,
    setSessionId: s.setSessionId,
    isStaffAccount: s.isStaffAccount,
  }));

  let { updateOrder } = useOrderStore();

  const selfInstallService = broadbandServices.find(p => p.sku === productSkuIds.broadbandSelfInstall.primary);
  const selfInstallOTC = broadbandOneOffFees.find(p => p.sku === productSkuIds.broadbandSelfInstallOTC.primary);

  const toggleBroadbandProduct = async (product: T_Product, bundle?: boolean) => {
    await swapMultipleProducts(
      broadbandProductInCart?.product ? [broadbandProductInCart.product] : [],
      [product],
      true
    );

    if (showOneMonthOnUs) {
      /* Get coupons in parallel. */
      const [bbBundleCoupon, tvCoupon, bbOnlyCoupon] = await Promise.all([
        kkService.getCoupon(NON_FIXED_TERM_BB_1_MONTH_ON_US_COUPON),
        kkService.getCoupon(couponCodes.oneMonthOnUs),
        kkService.getCoupon(NON_FIXED_TERM_BB_1_MONTH_ON_US_COUPON_ACQ),
      ]);

      /* Remove and add the appropriate coupons. */
      if (bundle) {
        if (bbOnlyCoupon) couponStoreApi.getState().clearCoupon(bbOnlyCoupon);
        if (bbBundleCoupon) couponStoreApi.getState().applyCouponToStore(bbBundleCoupon);
        if (tvCoupon) couponStoreApi.getState().applyCouponToStore(tvCoupon);
      } else {
        /* Remove tv and bundle coupons if broadband only. */
        if (bbBundleCoupon) couponStoreApi.getState().clearCoupon(bbBundleCoupon);
        if (tvCoupon) couponStoreApi.getState().clearCoupon(tvCoupon);
        if (bbOnlyCoupon) couponStoreApi.getState().applyCouponToStore(bbOnlyCoupon);
      }
    }

    // pass in coupon that is from session storage
    await updateOrder(
      kkService,
      customerStoreApi.getState().kk,
      productStoreApi.getState().getBasketItemsToAddToOrder(isStaffAccount),
      couponStoreApi.getState().coupons
    );
  };

  const selectProduct = async (product: T_Product | undefined, bundle?: boolean) => {
    if (product && !isUpdatingPlan && !hasActiveOrPendingBroadbandPackage) {
      setIsUpdatingPlan(true);

      if (selectedIsSkyStarterCustomer || hasActiveStarter) {
        // Add starter in cart if hasn't been added yet to apply the broadband discount
        if (!starterAdded) {
          applyProduct(starter!.id, 1, false);
        }
      } else {
        if (bundle && !starterAdded) {
          // Buy starter if its a bundle plan and starter not yet in cart
          buyStarter();
        } else if (!bundle && starterAdded) {
          // Remove starter from cart if adding a non-bundle plan and starter is in cart
          removeAllStarterDependentProducts();
          await toggleProduct(starter!, true, false);
        }
      }

      await toggleBroadbandProduct(product, bundle);
      setIsUpdatingPlan(false);
    }
  };

  /**
   * Add broadband to cart including default bb products (sky router and self-install service)
   */
  const getSkyBroadbandAction = (product?: T_Product, isBundle?: boolean) => {
    if (!broadbandProductInCart) {
      const defaultSku = product // if a product is passed, add that
        ? product.sku
        : broadbandProductSelected // else add the already selected product
        ? broadbandProductSelected.sku
        : productSkuIds.broadbandLightningFastWiFi.primary; // if nothing is passed and selected, add lfw
      const defaultProduct = broadband.find(p => p.sku === defaultSku);

      if (routerDevice && selfInstallService && selfInstallOTC) {
        applyProduct(routerDevice.id, 1);
        applyProduct(selfInstallService.id, 1);
        applyProduct(selfInstallOTC.id, 1);
      }

      if (defaultProduct) {
        setBroadbandProductSelected(defaultProduct);
        selectProduct(defaultProduct, isBundle);
      }
      // For the old plan selector, when a user Get Sky Broadband Now, generate a session ID for it if it does not exist yet.  This is to be used later for determining whether this will become an abandoned cart.
      if (!sessionId) {
        setSessionId(uuidv4());
      }
    }
  };

  const onStarterSelectChange = async (isSkyCustomer: boolean) => {
    if (!hasActiveOrPendingBroadbandPackage && broadbandProductInCart) {
      if (isSkyCustomer && starter) {
        applyProduct(starter.id, 0, false);
      } else if (starter) {
        applyProduct(starter.id, 0, false);
      }

      const customerId = customerStoreApi.getState().kk;
      if (customerId) {
        await updateOrder(
          kkService,
          customerId,
          productStoreApi.getState().getBasketItemsToAddToOrder(),
          couponStoreApi.getState().coupons
        );
      }
    }
  };

  const clearProducts = async () => {
    clearSessionStorageState();
    await kkService.updateOrder(customerId, []);
  };

  return {
    meshDevice,
    broadband,
    broadbandDiscounts,
    broadbandProductInCart,
    getSkyBroadbandAction,
    onStarterSelectChange,
    selectProduct,
    clearProducts,
  };
}

export { useChoosePlan };
