import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { BlockLoader } from './loader';

interface Props {
  header: React.ReactNode;
  hide?: boolean;
  loading?: boolean;
  classNames?: string;
}
let Disclosure: React.FC<Props> = ({ header, children, hide = false, loading = false, classNames }) => {
  const [show, setShow] = useState(!hide);
  const toggleShow = () => {
    setShow(!show);
  };
  useEffect(() => {
    if (hide === show) {
      setShow(!hide);
    }
  }, [hide]);
  const childrenClasses = cx(classNames, { 'h-auto overflow-visible': show, 'h-0 overflow-hidden': !show });
  return (
    <div className="mb-6 md:mb-2">
      <h3
        className="relative bg-gray-light sky-h3 md:sky-h3 text-navy text-center h-24 p-4 flex justify-center items-center cursor-pointer"
        onClick={toggleShow}
        data-testid={header}>
        <div className="w-10/12">{header}</div>
        <button className="h-24 w-24 absolute right-0 top-0 sky-h3 md:sky-h4">{show ? '—' : '+'}</button>
      </h3>
      <div className={childrenClasses}>{loading ? <BlockLoader /> : children}</div>
    </div>
  );
};

export { Disclosure };
