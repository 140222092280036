import React from 'react';
import { Button } from './button';

type ButtonsProps = {
  getReview: (index: number) => void;
  selectedReviewIndex: number;
};

let Buttons = ({ getReview, selectedReviewIndex }: ButtonsProps) => {
  const buttonConfig: Array<{ title: string; icon: string; selectedIcon: string }> = [
    {
      title: 'Speed',
      icon: '/images/Lightning_Fast_Fibre_Blue.svg',
      selectedIcon: '/images/Lightning_Fast_Fibre_Orange.svg',
    },
    {
      title: 'Coverage',
      icon: '/images/Sky_WiFi_Router_Blue.svg',
      selectedIcon: '/images/Sky_WiFi_Router_Orange.svg',
    },
    {
      title: 'Setup',
      icon: '/images/DIY_Set-up-Self-install_Blue.svg',
      selectedIcon: '/images/DIY_Set-up-Self-install_Orange.svg',
    },
  ];

  let setSelectedReviewIndex = (index: number) => {
    return () => {
      getReview(index);
    };
  };

  return (
    <div className="relative">
      {/* Mobile View */}
      <div
        className="absolute border-t-2 md:border-t-0 w-full md:w-1/2 border-l-0 md:border-l-2 md:hidden z-0"
        style={{ top: '50px', height: '50px' }}
      />
      {/* Desktop View */}
      <div className="absolute md:border-r-2 md:w-1/2 hidden md:block h-full z-0" style={{}} />
      <div className="flex justify-between flex-row md:flex-col px-12 md:py-12">
        <Button
          title={buttonConfig[0].title}
          icon={selectedReviewIndex === 0 ? buttonConfig[0].selectedIcon : buttonConfig[0].icon}
          getReview={setSelectedReviewIndex(0)}
          isSelected={selectedReviewIndex === 0}
          selectedReviewIndex={selectedReviewIndex}
        />
        <Button
          title={buttonConfig[1].title}
          icon={selectedReviewIndex === 1 ? buttonConfig[1].selectedIcon : buttonConfig[1].icon}
          getReview={setSelectedReviewIndex(1)}
          isSelected={selectedReviewIndex === 1}
          selectedReviewIndex={selectedReviewIndex}
        />
        <Button
          title={buttonConfig[2].title}
          icon={selectedReviewIndex === 2 ? buttonConfig[2].selectedIcon : buttonConfig[2].icon}
          getReview={setSelectedReviewIndex(2)}
          isSelected={selectedReviewIndex === 2}
          selectedReviewIndex={selectedReviewIndex}
        />
      </div>
    </div>
  );
};

export { Buttons };
