import { useJSONConfig } from '@sky-tv-group/shared';
import React from 'react';
import { configService } from '../../services';
import { CarouselContainer, PrevArrow, NextArrow } from '@sky-tv-group/components';
import Slider from 'react-slick';

let Promo = () => {
  let config = useJSONConfig(configService);

  let tvcUrls: { name: string; link: string }[] = config?.broadband?.acquisition?.tvc ?? [];
  if (!tvcUrls) return null;

  let slider = React.createRef<Slider>();
  const slickSettings = {
    centerMode: true,
    infinite: false,
    arrows: true,
    slidesToShow: 1,
    dots: true,
    swipeToSlide: true,
    arrowColorClassName: 'text-navy',
    customClassName: '  bundle-carousel tvc-bundle-carousel ',
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsiveBlock: [
      {
        breakpoint: 767,
        settings: {
          centerMode: true,
          dots: true,
          arrows: false,
          infinite: false,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          centerMode: true,
          dots: true,
          arrows: true,
          infinite: false,
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="promo-container sky-bg-white min-w-0">
      <CarouselContainer {...slickSettings} sliderRef={slider}>
        {tvcUrls.map(url => {
          return (
            <div className="iframe-wrapper m-auto  ">
              <iframe
                key={url.name}
                title="video"
                src={url.link}
                allow="encrypted-media"
                allowFullScreen
                className=" top-0 left-0 right-0 bottom-0 h-full sky-bg-white w-full "></iframe>
            </div>
          );
        })}
      </CarouselContainer>
    </div>
  );
};
export { Promo };
