import React from 'react';
import { useAuth0 } from '../../../auth0/store';
import { useStore } from '../../../store';
import { CallSkyButton } from '../../error-call-sky';
import { useCheckYourAddress } from '../../../hooks/useCheckYourAddress';
import { SPLITIO_KEY, useSplitIO } from '@sky-tv-group/shared';
import { focusOnAddressSelector } from '../../../helper';

interface CTAButtonProps {
  isFibreFuture: () => boolean;
  isFibreOk: () => boolean;
  executeOtherButtonAction: () => Promise<void>;
  getButtonLabel: () => string;
  signedIn: boolean;
}

/** CTAButton will render into 2 states:
 * 1- "Check your address" anchor link
 * 2- "Get Sky Broadband" link to the main cart
 * */
const CTAButton = ({
  isFibreFuture,
  isFibreOk,
  executeOtherButtonAction,
  getButtonLabel,
  signedIn,
}: CTAButtonProps) => {
  const { selectedIsSkyStarterCustomer, selectedTuiAddress } = useStore(s => ({
    selectedIsSkyStarterCustomer: s.selectedIsSkyStarterCustomer,
    selectedTuiAddress: s.selectedTuiAddress,
  }));

  return (
    <div>
      <div className="mx-auto text-center">
        {!signedIn && selectedTuiAddress === '' && (
          <button className="sky-button sky-button--broaband-dark-font" onClick={() => focusOnAddressSelector()}>
            Can you get fibre? Check your address
          </button>
        )}
      </div>
      {(isFibreFuture() || (isFibreOk() && selectedIsSkyStarterCustomer)) && (
        <div className="flex">
          <button
            onClick={executeOtherButtonAction}
            className="self-center sky-button sky-button--broaband-dark-font mx-auto cursor-pointer">
            {getButtonLabel()}
          </button>
        </div>
      )}
    </div>
  );
};

const BroadbandButtonCTA = () => {
  const [allowChangingPlans] = useSplitIO(SPLITIO_KEY.SKYWEB_BROADBAND_CHANGING_PLANS);
  const [allowCheckout] = useSplitIO(SPLITIO_KEY.SKYWEB_BROADBAND_AND_TV_ACQUISTION);
  const { selectedIsSkyCustomer } = useStore(s => ({
    selectedIsSkyCustomer: s.selectedIsSkyStarterCustomer,
  }));
  const { isAuthenticated } = useAuth0();
  const {
    isInHomePage,
    isFibreFuture,
    isFibreOk,
    executeOtherButtonAction,
    getButtonLabel,
    hasActiveOrPendingBroadbandPackage,
  } = useCheckYourAddress();
  const props = { isFibreFuture, isFibreOk, executeOtherButtonAction, getButtonLabel };
  const showCTAButton = hasActiveOrPendingBroadbandPackage ? allowChangingPlans : true;

  if (allowCheckout) {
    return (
      <>
        {!selectedIsSkyCustomer && isFibreOk() && <CallSkyButton title={'Give our Sky Crew a call to get started.'} />}
        {hasActiveOrPendingBroadbandPackage && showCTAButton && <CTAButton signedIn={isAuthenticated} {...props} />}
      </>
    );
  }
  return (
    <>
      {!selectedIsSkyCustomer && isFibreOk() && <CallSkyButton title={'Give our Sky Crew a call to get started.'} />}
      {(isInHomePage() || hasActiveOrPendingBroadbandPackage) && showCTAButton && (
        <CTAButton signedIn={isAuthenticated} {...props} />
      )}
    </>
  );
};

export { BroadbandButtonCTA };
