import React from 'react';
import { useStore } from '../../../store';
import { BroadbandButtonCTA } from './BroadbandButtonCTA';
import { NonStarterPriceSection } from './NonStarterPriceSection';
import { TermsAndConditions } from './TermsAndConditions';
import cx from 'classnames';
import { useAuth0 } from '../../../auth0/store';

interface TabContentProps {
  priceContent: {
    name: string;
    price: string;
    term: string;
    nonStarterPrice: string;
  };
  featuresContent: {
    main: string;
    sec: string;
    extra?: string;
  }[];
  isNotOnA12MonthContract: boolean;
}

const TabContent = ({ priceContent, featuresContent, isNotOnA12MonthContract }: TabContentProps) => {
  const { selectedIsSkyCustomer } = useStore(s => ({
    selectedIsSkyCustomer: s.selectedIsSkyStarterCustomer,
  }));
  const { isAuthenticated } = useAuth0(s => ({
    isAuthenticated: s.isAuthenticated,
  }));
  const priceTermStylesDesktop = cx('sky-h7-reg', {
    'pb-6': isAuthenticated,
    'pb-4': !isAuthenticated,
  });
  const priceTermStylesMobile = cx('sky-h6-reg', {
    'pb-6': isAuthenticated,
    'pb-4': !isAuthenticated,
  });

  return (
    <>
      <div className="sky-bg-midnight p-2 hidden md:block"></div>
      <div className="flex flex-col md:flex-row">
        <div className="flex-1 md:w-1/2">
          <div className="flex flex-col items-center justify-center text-center flex-1 py-4 px-8">
            {/* Mobile View */}
            <div className="md:hidden justify-center">
              <div className="sky-h4-bold pb-2 pt-6">{priceContent.name}</div>
              <div className="pt-2 flex flex-row items-center justify-center sky-h6-reg">
                From{' '}
                <div className="text-bborange sky-h1 px-2" style={{ fontSize: '44px' }}>
                  {selectedIsSkyCustomer ? priceContent.price : priceContent.nonStarterPrice}
                </div>{' '}
                /mth
              </div>
              <div className={priceTermStylesMobile} style={{ fontSize: '12px' }}>
                {!isNotOnA12MonthContract ? priceContent.term : ''}
              </div>
            </div>
            {/* Desktop View */}
            <div className="hidden md:block">
              <div className="sky-h3-bold pb-4 pt-6">{priceContent.name}</div>
              <div className="pt-2 flex flex-row items-center justify-center sky-h5-reg">
                From{' '}
                <div className="text-bborange sky-h1 px-2" style={{ fontSize: '60px' }}>
                  {selectedIsSkyCustomer ? priceContent.price : priceContent.nonStarterPrice}
                </div>{' '}
                /mth
              </div>
              <div className={priceTermStylesDesktop}>{!isNotOnA12MonthContract ? priceContent.term : ''}</div>
            </div>
          </div>
          {/* Desktop View */}
          <div className="pb-2 md:block hidden">
            <BroadbandButtonCTA />
          </div>
        </div>
        {/* Mobile View */}
        <div className="block md:hidden">
          {selectedIsSkyCustomer && <NonStarterPriceSection price={priceContent.nonStarterPrice} isMobileView={true} />}
        </div>
        <div className="md:w-1/2 sky-bg-white sky-text-midnight py-4 px-8">
          {featuresContent.map((feat, index) => (
            <div className="flex flex-col pt-4" key={index}>
              <div className="flex flex-row">
                <img src="/images/check_icon.svg" alt="feat" className="py-2 mr-4 w-6 h-8" />
                <div className="flex flex-col">
                  <div>
                    <span className="font-bold">{feat.main}</span>&nbsp;{feat.sec}
                  </div>
                  {feat.extra && (
                    <div className="sky-text-gray-darker mt-2" style={{ fontSize: '12px' }}>
                      {feat.extra}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
          {/* Mobile View */}
          <div className="pt-10 pb-2 block md:hidden">
            <BroadbandButtonCTA />
          </div>
        </div>
        {/* Mobile View */}
        <div className="block md:hidden">
          <TermsAndConditions isMobileView={true} />
        </div>
      </div>
      {/* Desktop View */}
      <div className="md:flex hidden flex-row p-4">
        <div className="w-full">
          {selectedIsSkyCustomer && (
            <NonStarterPriceSection price={priceContent.nonStarterPrice} isMobileView={false} />
          )}
        </div>
        <TermsAndConditions isMobileView={false} />
      </div>
    </>
  );
};

export { TabContent };
