import React, { useEffect, useState } from 'react';

import {
  useManagePackage,
  useCustomerStore,
  useOrderStore,
  useCouponStore,
  useAnalytics,
  PACKAGE_TYPE,
  T_OrderProduct,
  broadbandCategories,
  voiceCategories,
  NON_FIXED_TERM_BB_1_MONTH_ON_US_COUPON,
  NON_FIXED_TERM_BB_1_MONTH_ON_US_COUPON_ACQ,
} from '@sky-tv-group/shared';

import { useAuth0 } from '../auth0/store';
import { BlockLoader } from '@sky-tv-group/components';
import { myAccountSWR } from '../swr';
import { useStore, clearSessionStorageState, set } from '../store';
import { ACCOUNT_URL } from '../config';
import { Redirect } from 'react-router-dom';
import { ErrorCallSky } from '../components/error-call-sky';
import history from '../history';
import { useChoosePlan } from '../hooks/useChoosePlan';
import { AppRoutes } from '../constants';

const CheckYourSubscription = () => {
  let [error, setError] = useState(false);
  let [loading, setLoading] = useState(true);
  const { isAuthenticated } = useAuth0();
  const { data: customer } = myAccountSWR.useCustomer();
  const { hasActiveOrPendingBroadbandPackage, isCustomerNeedsAccountUpdate } = myAccountSWR.usePackages();
  const { selectedTuiAddress, selectedIsSkyStarterCustomer, isBundle } = useStore();
  const { isBroadcastTier, accountIssue } = useManagePackage(myAccountSWR);
  let isInArrears = myAccountSWR.useIsCustomerInArrears();
  const { clearProducts } = useChoosePlan();
  const order = useOrderStore(s => s.order);
  const coupons = useCouponStore(s => s.coupons);
  const coupon = coupons[0];
  const { checkoutStarted } = useAnalytics();
  const { kk: customerId, sessionId, isActiveGASession, setActiveGASession } = useCustomerStore();

  const getBroadbandAndVoiceSKUS = (orderProducts: T_OrderProduct[]) => {
    return orderProducts
      .filter(p => broadbandCategories.includes(p.product.categoryId) || voiceCategories.includes(p.product.categoryId))
      .map(p => p.product.sku)
      .join('-');
  };

  useEffect(() => {
    if (isCustomerNeedsAccountUpdate) {
      clearProducts();
      return history.push(AppRoutes.Home);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedTuiAddress && customer && customer?.tuiAddressCode !== String(selectedTuiAddress)) {
      clearProducts();
      set({ redirectFromCheckingPage: true });
      return history.push(AppRoutes.Home);
    }
    // eslint-disable-next-line
  }, [customer]);

  useEffect(() => {
    let check = () => {
      if (isBroadcastTier || accountIssue || isInArrears) {
        setError(true);
      }

      // WEBDXP-3223: Workaround to prevent user from swapping/upgrading their BB subscription. TODO: Remove once we implement upgrade/swap packages.
      if (hasActiveOrPendingBroadbandPackage) {
        setError(true);
      }

      setLoading(false);
    };
    if (customer && (isBroadcastTier !== undefined || accountIssue)) {
      check();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, isBroadcastTier, accountIssue]);

  useEffect(() => {
    if (customer && !loading && !error && !isBroadcastTier) {
      // anaytics
      if (!isActiveGASession && order) {
        setActiveGASession(true);
      }

      order &&
        checkoutStarted(
          order,
          selectedIsSkyStarterCustomer,
          coupon,
          undefined,
          false,
          isBundle ? PACKAGE_TYPE.DTH_BROADBAND : PACKAGE_TYPE.BROADBAND
        );

      setTimeout(() => {
        clearSessionStorageState();
        let newUrl = ACCOUNT_URL + `packages/checkout?bc=${window.location.origin}/broadband`;
        // Add the session ID to the URL if it exists to continue the broadband journey.
        if (sessionId) newUrl += `&sid=${sessionId}`;
        if (customerId) newUrl += `&skus=${getBroadbandAndVoiceSKUS(order?.orderProducts ?? [])}`;

        if (coupon) {
          let couponToMyAccount = coupon.couponCode;
          if (couponToMyAccount === NON_FIXED_TERM_BB_1_MONTH_ON_US_COUPON_ACQ) {
            couponToMyAccount = NON_FIXED_TERM_BB_1_MONTH_ON_US_COUPON;
          }

          newUrl += `&couponCode=${couponToMyAccount}`;
        }
        window.location.assign(newUrl);
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, loading]);

  if (!isAuthenticated) return <Redirect to={AppRoutes.Home} />;

  // TODO: Improve Error handling.
  if (error) {
    return (
      <div className="h-screen flex items-center justify-center m-auto">
        <ErrorCallSky />
      </div>
    );
  }

  return (
    <div className="h-screen flex items-center justify-center m-auto">
      <div className="flex flex-col items-center justify-center m-auto">
        <div className="sky-h4-bold text-bborange">Please wait while we do clever things for you...</div>
        <BlockLoader />
      </div>
    </div>
  );
};

export { CheckYourSubscription };
