import { orderStoreApi, productSkuIds } from '@sky-tv-group/shared';

const productIcons = [
  {
    sku: productSkuIds.broadbandLightningFastWiFi.primary,
    svg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/Broadband_Plan_Orange.png',
  },
  {
    sku: productSkuIds.broadbandLightningFastWiFiBoost.primary,
    svg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/Broadband_Plan_Orange.png',
  },
  {
    sku: productSkuIds.broadbandWifi100.primary,
    svg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/Broadband_Plan_Orange.png',
  },
  {
    sku: productSkuIds.broadbandWifi100Boost.primary,
    svg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/Broadband_Plan_Orange.png',
  },
  {
    sku: productSkuIds.skyRouter.primary,
    svg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/Sky_WiFi_Router_Orange.png',
  },
  {
    sku: productSkuIds.meshDevice.primary,
    svg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/Sky_WiFi_Booster_Orange.png',
  },
  {
    sku: productSkuIds.meshOccurrence.primary,
    svg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/Sky_WiFi_Booster_Orange.png',
  },
  {
    sku: productSkuIds.broadbandSelfInstall.primary,
    svg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/DIY_Set-up_Self-install_Orange.png',
  },
  {
    sku: productSkuIds.broadbandBringYourOwnDevice.primary,
    svg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/Use_My_Own_Equipment_Orange.png',
  },
  {
    sku: productSkuIds.broadbandStaticIP.primary,
    svg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/Static_IP_Orange.png',
  },
  {
    sku: productSkuIds.voice.primary,
    svg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/Home_Phone_Orange.png',
  },
  {
    sku: productSkuIds.voiceAustraliaCalls.primary,
    svg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/Australia_Calls_Orange.png',
  },
  {
    sku: productSkuIds.voiceNationalCalls.primary,
    svg: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/Mobile__National_Calls_Orange.png',
  },
  {
    sku: productSkuIds.voiceTop10Calls.primary,
    svg:
      'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/International_Calling_Top_10_Orange.png',
  },
  {
    sku: productSkuIds.voiceTop20Calls.primary,
    svg:
      'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/International_Calling_Top_20_Orange.png',
  },
];

/** Temporary helper to get Orange branded images. */
export function getProductOrangeImage(sku: string): string | undefined {
  return productIcons.find(productIcon => productIcon.sku === sku)?.svg;
}

export const hasProduct = (categoryId: number) =>
  orderStoreApi.getState().order?.orderProducts.find(p => p.categoryId === categoryId) !== undefined;

export const focusOnAddressSelector = () => {
  const addressSelectorInputField = document.getElementById('address');
  if (addressSelectorInputField) {
    addressSelectorInputField.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
    addressSelectorInputField.focus({ preventScroll: true });
  }
};
