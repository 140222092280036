import create from 'zustand';
import createAuth0Client, { Auth0Client, Auth0ClientOptions } from '@auth0/auth0-spa-js';
import { AUTH0_LOGOUT_URL, NAVIGATION_URL, USER_GROUP_SOURCE, ACCOUNT_URL } from '../config';
import { myAccountService, navService } from '../services';
import { NavigationLinks, segment, UserGroupType } from '@sky-tv-group/shared';

const userGroupTypePath = 'https://idm.sky.co.nz/customer_group_type';

interface Store {
  isAuthenticated: boolean;
  isUnlinked: boolean;
  isReseller: boolean;
  loading: boolean;
  user?: any;
  userGroupTypes: UserGroupType[];
  client?: Auth0Client;
  navLinks: NavigationLinks;
  load: (config: Auth0ClientOptions, onRedirectCallback: (appState: any) => void) => void;
  login: () => void;
  logout: () => void;
  loginWithRedirect: (redirectUrl: string) => void;
}

let isUserUnlinked = (userGroupTypes: UserGroupType[]) => {
  return userGroupTypes.some(ugt => ugt === UserGroupType.UNLINKED_ACCOUNT);
};

let isUserReseller = (userGroupTypes: UserGroupType[]) => {
  return userGroupTypes.some(ugt => ugt === UserGroupType.RESELLER_CUSTOMER);
};

export const [useAuth0, API] = create<Store>((set, get) => ({
  isAuthenticated: false,
  isUnlinked: true,
  isReseller: false,
  loading: true,
  userGroupTypes: [],
  navLinks: {
    logoUrl: '/',
    logoSrc: '/',
    TVGuideURL: '/',
    header: [],
    side: [],
    footer: [],
    social: [],
    corporate: [],
  },
  load: async (config, onRedirectCallback) => {
    try {
      const client = await createAuth0Client(config);
      try {
        // Try to login user silently. Doesn't matter if it fails.
        await client.getTokenSilently();
      } catch (e) {}
      set({ client });
      let { search } = window.location;
      let fromAuth0LoginPage = false;

      if (search.includes('error=unauthorized')) {
        get().logout();
        return;
      }
      if (search.includes('code=') && search.includes('state=')) {
        const { appState } = await client.handleRedirectCallback();
        onRedirectCallback(appState);
        fromAuth0LoginPage = true;
      }
      const isAuthenticated = await client.isAuthenticated();
      set({ isAuthenticated });
      if (isAuthenticated) {
        const user = await client.getUser({
          scope: config.scope!,
          audience: config.audience!,
        });

        let userGroupTypes: UserGroupType[] = [];
        const profile = await myAccountService.getProfile();

        if (fromAuth0LoginPage) {
          segment.identify(user.sub, profile.email, profile.accountNumber, profile.profileId);

          if (USER_GROUP_SOURCE === 'EKS') {
            if (USER_GROUP_SOURCE === 'EKS') {
              userGroupTypes = (profile.groups ?? []) as UserGroupType[];
            } else {
              userGroupTypes = (user[userGroupTypePath] ?? []) as UserGroupType[];
            }

            /************ For debugging purposes requested by QA ************/
          } else {
            userGroupTypes = (user[userGroupTypePath] ?? []) as UserGroupType[];
          }

          /************ For debugging purposes requested by QA ************/
          const customer = await myAccountService.getCustomer();
          segment.signedIn(customer.accountNumber);
        }

        set({
          user,
          userGroupTypes: userGroupTypes,
          isUnlinked: isUserUnlinked(userGroupTypes),
          isReseller: isUserReseller(userGroupTypes),
        });
      }

      const navLnk = await navService.getLinks(NAVIGATION_URL);
      set({ navLinks: navLnk });
      set({ loading: false });
    } catch (err) {
      console.error(err);
      //sentryService.report(err);
      window.location.assign('/');
    }
  },
  login: () => {
    get().client?.loginWithRedirect({ redirect_uri: ACCOUNT_URL });
  },
  loginWithRedirect: (redirectUrl: string) => {
    get().client?.loginWithRedirect({ redirect_uri: redirectUrl });
  },
  logout: () => {
    get().client?.logout({ returnTo: AUTH0_LOGOUT_URL });
  },
}));

let getUser = () => API.getState().user;
let getClient = () => API.getState().client;
export { getUser, getClient };
