import React from 'react';
import { T_Product, T_OrderProduct } from '@sky-tv-group/shared';
import { Disclosure } from '../disclosure';
import { PackedCarouselContainer } from '../carousel/PackedCarouselContainer';
import { ISliderProps } from '../carousel/CarouselContainer';
import { CarouselProductItem } from './CarouselProductItem';

export interface ISpecialistChannelProps {
  products: T_Product[];
  toggleProduct: (
    product: T_Product,
    update?: boolean | undefined,
    removeAccessories?: boolean | undefined,
    broadbandJourney?: string | undefined
  ) => Promise<void>;
  className?: string;
  freeProduct?: T_OrderProduct;
  header?: string;
}

/**
 * Parent container for the SpecialistChannels
 */
const SpecialistChannelsContainer = ({
  products,
  toggleProduct,
  className = '',
  freeProduct,
  header = 'Premium Channels',
}: ISpecialistChannelProps) => {
  const sliderProps: ISliderProps = {
    arrowColorClassName: 'text-navy',
  };

  // Sort by rating.
  let sortedspecialistChannels = products.sort(
    ({ rating: previousRating }, { rating: currentRating }) => currentRating - previousRating
  );

  return (
    <div className={className}>
      <Disclosure header={header} hide={false}>
        {/* // this check is required as on mobile display issues occur on carousel dots  */}
        {sortedspecialistChannels.length !== 0 && (
          <PackedCarouselContainer {...sliderProps}>
            {(sortedspecialistChannels ?? []).map((product, index) => (
              <CarouselProductItem
                imageBackgroundClassName="bg-white"
                imageClassName="opacity-1 object-scale-down"
                key={product.id}
                product={product}
                toggleProduct={toggleProduct}
                removable={product.id !== freeProduct?.productId}
              />
            ))}
          </PackedCarouselContainer>
        )}
      </Disclosure>
    </div>
  );
};

export { SpecialistChannelsContainer };
