import { useJSONConfig } from '@sky-tv-group/shared';
import React, { useState } from 'react';
import { configService } from '../../../services';
import { Review } from './review';
import { Buttons } from './buttons';
import { SelectedSpeed, SelectedCoverage, SelectedSetup } from './buttonImages';
import { Button } from '@sky-tv-group/components';

let Reviews = () => {
  const [selectedReviewIndex, setSelectedReviewIndex] = useState(0);
  let config = useJSONConfig(configService);

  let data = config?.broadband?.acquisition?.testimonial?.reviews;

  const selectedImage = () => {
    switch (selectedReviewIndex) {
      case 0:
        return { image: SelectedSpeed() };
      case 1:
        return { image: SelectedCoverage() };
      case 2:
        return { image: SelectedSetup() };
    }
  };

  const visualData = selectedImage();

  const redirectURL = config?.broadband?.acquisition?.testimonial?.url;

  const handleReviews = () => {
    window.location.href = redirectURL;
  };

  if (!data) return null;

  return (
    <div className="sky-bg-gray-light text-navy">
      <div className="md:container m-auto" style={{ minHeight: '400px' }}>
        <div className="py-12 md:py-0 flex flex-wrap sky-bg-gray-light">
          {/* Review */}
          <div className="w-full md:w-2/3 px-12 md:py-12 relative">
            <div className="object-contain text-center absolute right-0 hidden md:block" style={{ maxWidth: '300px' }}>
              {visualData?.image}
            </div>
            <p className="pb-4 relative">
              <span className="text-bborange  sky-h3 md:sky-h3">Customer Review{data.length > 1 ? 's' : ''}</span>
            </p>

            <Review
              review={data[selectedReviewIndex].review}
              name={data[selectedReviewIndex].name}
              title={data[selectedReviewIndex].title}
            />

            {/* Desktop */}
            <div className="hidden md:block">
              <Button colorMode="light" className="w-full" onClick={handleReviews} variant="secondary">
                See our customer reviews
              </Button>
            </div>
          </div>
          {/* Buttons */}
          <div className="w-full md:w-1/3">
            <Buttons getReview={setSelectedReviewIndex} selectedReviewIndex={selectedReviewIndex} />
          </div>
        </div>

        {/* Mobile */}
        <div className="p-12 md:hidden">
          <Button colorMode="light" className="w-full" onClick={handleReviews} variant="secondary">
            See our customer reviews
          </Button>
        </div>
      </div>
    </div>
  );
};
export { Reviews };
