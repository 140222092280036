import React from 'react';

interface TwoOptionsSelectorProps<L extends string, R extends string> {
  onSelectChange: (value: L | R) => void;
  valueSelected?: L | R;
  valueLeft: L;
  dataTestId?: string;
  valueRight: R;
  isDisabled?: boolean;
}
function TwoOptionsSelector<L extends string, R extends string>({
  valueLeft,
  valueRight,
  dataTestId,
  valueSelected = valueLeft,
  onSelectChange,
  isDisabled = false,
}: TwoOptionsSelectorProps<L, R>) {
  let selectedClass = 'sky-bg-midnight sky-text-white';
  let unselectedClass = 'sky-bg-gray-light sky-text-midnight';
  let buttonClass = 'py-4 w-2/5 border border-navy sky-h7-reg';
  let disabledClass = 'cursor-not-allowed';

  return (
    <div className="lg:px-4 w-full flex justify-center">
      <button
        onClick={() => onSelectChange(valueLeft)}
        className={`${buttonClass} rounded-l-full ${valueSelected == valueLeft ? selectedClass : unselectedClass} ${
          isDisabled ? disabledClass : ''
        }`}
        data-testid={dataTestId + '-YES'}
        disabled={isDisabled}>
        {valueLeft}
      </button>
      <button
        onClick={() => onSelectChange(valueRight)}
        className={`${buttonClass} rounded-r-full ${valueSelected == valueRight ? selectedClass : unselectedClass} ${
          isDisabled ? disabledClass : ''
        }`}
        data-testid={dataTestId + '-NO'}
        disabled={isDisabled}>
        {valueRight}
      </button>
    </div>
  );
}

export { TwoOptionsSelector };
