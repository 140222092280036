import React from 'react';
import cx from 'classnames';

interface Props {
  title: string;
  index: number;
  visited: boolean;
  valid: boolean;
  match: boolean;
  onClick?: () => void;
}

export function StepperDot({ title, index, visited, valid, match, onClick }: Props) {
  const contClass = cx('stepper-dot flex flex-col items-center relative', {
    valid,
    'cursor-pointer': onClick,
  });

  const dotClass = cx('rounded-full h-10 w-10 sm:h-8 sm:w-8 flex items-center justify-center', {
    'sky-blue-gradient': visited,
    'bg-gray-dark': !visited,
  });

  const titleClass = cx('absolute mt-12 w-26 md:w-36 top-0 text-center sky-h6-bold md:sky-h6-bold', {
    'text-blue-light': match,
  });

  const contentClass = cx('sky-h5-bold text-white', {
    'stepper-active': valid && !match,
  });

  return (
    <div className={contClass} onClick={onClick}>
      <div className={dotClass}>
        <span className={contentClass}>{valid && !match ? '' : index}</span>
      </div>
      <div className={titleClass}>{title}</div>
    </div>
  );
}
