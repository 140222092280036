import React from 'react';

const infoConfig: Array<{ title: string; body: string; icon: string }> = [
  {
    title: 'Hassle-free set up',
    body:
      'We’ll get you up and running quickly. Fancy a bit of DIY? You can self-install using our set-up guides. They’re jargon-free and ' +
      'in plain English, making it straightforward for you to tackle on your own.',
    icon: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/DIY_Set-up_Self-install_Orange.png',
  },
  {
    title: 'Super Set Up Technicians',
    body:
      'Our Super Set Up Technicians visit your home and get your WiFi going from the lounge room to the throne room. They’ll use Sky WiFi Boosters if necessary and connect your devices too! ' +
      'They’ll even personalise your Sky Broadband network name and password to one you’ll remember.',
    icon: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/Perfect_Set-up_Technician_Orange.png',
  },
  {
    title: 'Trusted service that rocks',
    body:
      'You know us and our Sky Crew, so you can trust us to deliver fantastic service. Sky Broadband’s no exception. You can call our friendly Sky crew, we’ve got a dedicated Sky Broadband team on hand to help.',
    icon: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/Headset_Orange.png',
  },
  {
    title: 'Use of latest WiFi6 tech',
    body:
      'We’ve got the latest tech (that’s WiFi 6 if you like the letters and numbers). So that means our Sky WiFi Routers are grunty enough to reach from the lounge room to the throne room. ' +
      'And if your palace is a bit on the larger side, we’ll recommend Sky WiFi Boosters.',
    icon: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/Sky_WiFi_Router_Orange.png',
  },
  {
    title: 'Home phone plans available',
    body:
      'There’s even a very competitive Sky Home Phone calling plan! You can even bring your existing home phone number over to Sky if you simply can’t part with ' +
      'it or mix it up and choose a new home phone number. The choice is yours.',
    icon: 'https://static.sky.co.nz/sky/SkyBroadbandIcons_CompleteSet/Orange/PNG/Sky_Home_Phone_Plan_Orange.png',
  },
];

const MarketingInfo = () => {
  return (
    <div className="sky-bg-white text-midnight">
      <h3 className={'pt-16 text-center '} style={{ fontSize: '32px', lineHeight: '36px' }}>
        <span>Why</span>{' '}
        <span className=" font-black " style={{ fontFamily: 'MarkPro-Black SKY' }}>
          Sky Broadband?
        </span>
      </h3>
      <div className={'pt-9 md:pt-11 pb-16 md:pb-24 flex flex-row justify-center md:container m-auto'}>
        <div className={'flex flex-col md:flex-row md:justify-center md:flex-wrap w-full'}>
          {infoConfig.map(({ icon, title, body }, index) => (
            <div key={index} className={'flex flex-col mt-7 md:mt-14 items-center justify-start md:w-1/3 px-8'}>
              <img src={icon} className={'object-contain'} style={{ width: '89px', height: '89px' }} alt={title} />
              <h5 className={'mt-5 sky-h4-black md:sky-h6-black text-center'}>{title}</h5>
              <div className={'mt-5 text-center'}>{body}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export { MarketingInfo };
